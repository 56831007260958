import React from "react";

const AllergiesIcon = (props) => (
  <svg {...props} className={"icon"} width="114" height="115" viewBox="0 0 114 115" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30.8131 47.5195C31.7786 49.6656 33.1812 51.5854 34.9307 53.1558C36.6803 54.7262 38.7381 55.9124 40.9721 56.6383C48.8258 59.3018 52.7332 63.1718 55.4996 69.439C58.2659 75.7062 69.1752 81.0411 69.1752 81.0411L52.3502 97.9077L14.3711 98.7694V63.0073L30.8131 47.5195Z" fill="#FF592C"/>
    <path d="M3.57812 110.627L14.4038 99.8035M14.4038 99.8035V64.2914C14.4038 63.4667 14.7433 62.6784 15.3425 62.1117L52.3075 27.1526C53.4951 26.0294 55.3633 26.0637 56.5089 27.2298L87.3434 58.6171C88.4961 59.7905 88.489 61.6733 87.3275 62.838L53.1999 97.0572C52.6742 97.5844 51.9712 97.8972 51.2277 97.9349L14.4038 99.8035Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M45.1289 15.7292L97.9403 68.6712" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M80.7305 4.09216L109.441 32.803" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M72.2695 42.2002L95.5415 18.8628" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M41.582 37.304L54.5778 50.3319" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M26.0391 51.9927L37.8157 63.7985" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
  </svg>
);

export default AllergiesIcon;
