import React from "react";

const DataCaptureIcon = (props) => (
  <svg {...props} className={"icon"} width="125" height="97" viewBox="0 0 125 97" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M66.6412 18.0754V73.7297" stroke="var(--svgColor)" strokeWidth="3" />
    <path d="M121.145 18.0754V73.7297" stroke="var(--svgColor)" strokeWidth="3" />
    <path d="M121.145 54.8656C121.145 56.4626 120.823 58.0433 119.368 59.5136C117.913 60.9838 116.21 62.3137 113.531 63.4239C110.852 64.5342 107.679 65.4021 104.201 65.9761C100.723 66.5501 97.0108 66.8184 93.2858 66.7651C89.5608 66.7117 85.899 66.3379 82.5182 65.6657C79.1374 64.9935 76.1067 64.0367 73.6066 62.8523C71.1064 61.6679 69.6154 60.2801 68.3927 58.7715C67.17 57.2628 66.793 55.7321 66.6412 54.0708" stroke="var(--svgColor)" strokeWidth="3" />
    <path d="M121.145 73.14C121.145 74.737 120.823 76.3177 119.368 77.788C117.913 79.2583 116.21 80.5881 113.531 81.6983C110.852 82.8086 107.679 83.6765 104.201 84.2505C100.723 84.8245 97.0108 85.0928 93.2858 85.0395C89.5608 84.9862 85.899 84.6123 82.5182 83.9401C79.1374 83.2679 76.1067 82.3111 73.6066 81.1267C71.1064 79.9423 69.6154 78.5545 68.3927 77.0459C67.17 75.5373 66.793 74.0065 66.6412 72.3452" stroke="var(--svgColor)" strokeWidth="3" />
    <path d="M121.145 36.5911C121.145 38.1881 120.823 39.7687 119.368 41.239C117.913 42.7093 116.21 44.0391 113.531 45.1494C110.852 46.2596 107.679 47.1276 104.201 47.7016C100.723 48.2756 97.0108 48.5439 93.2858 48.4905C89.5608 48.4372 85.899 48.0633 82.5182 47.3911C79.1374 46.7189 76.1067 45.7621 73.6066 44.5778C71.1064 43.3934 69.6154 42.0056 68.3927 40.4969C67.17 38.9883 66.793 37.4576 66.6412 35.7963" stroke="var(--svgColor)" strokeWidth="3" />
    <path d="M121.5 18.5C121.5 19.5375 120.976 20.6813 119.695 21.882C118.412 23.0853 116.473 24.238 113.953 25.2374C108.921 27.2327 101.868 28.5 94 28.5C86.1325 28.5 79.0786 27.2327 74.0468 25.2374C71.5266 24.238 69.5883 23.0853 68.3049 21.882C67.0241 20.6813 66.5 19.5375 66.5 18.5C66.5 17.4625 67.0241 16.3187 68.3049 15.118C69.5883 13.9147 71.5266 12.762 74.0468 11.7626C79.0786 9.76729 86.1325 8.5 94 8.5C101.868 8.5 108.921 9.76729 113.953 11.7626C116.473 12.762 118.412 13.9147 119.695 15.118C120.976 16.3187 121.5 17.4625 121.5 18.5Z" fill="#484848" stroke="var(--svgColor)" strokeWidth="3" />
    <rect x="3" y="7" width="59" height="48" rx="17" fill="var(--svgColor)" />
    <path d="M21.6484 24.8555L17.0078 39H13.2695L19.6094 21.9375H21.9883L21.6484 24.8555ZM25.5039 39L20.8516 24.8555L20.4766 21.9375H22.8789L29.2539 39H25.5039ZM25.293 32.6484V35.4023H16.2812V32.6484H25.293ZM37.4805 32.918H33.1328V30.1758H37.4805C38.1523 30.1758 38.6992 30.0664 39.1211 29.8477C39.543 29.6211 39.8516 29.3086 40.0469 28.9102C40.2422 28.5117 40.3398 28.0625 40.3398 27.5625C40.3398 27.0547 40.2422 26.582 40.0469 26.1445C39.8516 25.707 39.543 25.3555 39.1211 25.0898C38.6992 24.8242 38.1523 24.6914 37.4805 24.6914H34.3516V39H30.8359V21.9375H37.4805C38.8164 21.9375 39.9609 22.1797 40.9141 22.6641C41.875 23.1406 42.6094 23.8008 43.1172 24.6445C43.625 25.4883 43.8789 26.4531 43.8789 27.5391C43.8789 28.6406 43.625 29.5938 43.1172 30.3984C42.6094 31.2031 41.875 31.8242 40.9141 32.2617C39.9609 32.6992 38.8164 32.918 37.4805 32.918ZM50.043 21.9375V39H46.5391V21.9375H50.043Z" fill="#FF592C" />
    <path d="M51.7447 90.726C52.1179 85.1218 54.8869 68.3405 74.8241 67.7985L74.824 74.3492L87 62.1746L74.8241 50L74.8241 55.4982C74.8241 55.4982 43.6811 63.9536 51.1595 90.7809C51.1782 90.8485 51.22 90.9074 51.2778 90.9472C51.3355 90.987 51.4053 91.0052 51.4752 90.9987C51.545 90.9921 51.6102 90.9612 51.6595 90.9113C51.7088 90.8615 51.739 90.7959 51.7447 90.726Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
    <path d="M111 33.7901C111 32.771 111.766 31.9149 112.779 31.8023L113.49 31.7233C114.597 31.6004 115.59 32.4044 115.701 33.5121L115.801 34.5099C115.911 35.609 115.109 36.5891 114.01 36.699L113.199 36.7801C112.022 36.8978 111 35.9733 111 34.79V33.7901Z" fill="var(--svgColor)" />
    <path d="M111 53.2901C111 52.271 111.766 51.4149 112.779 51.3023L113.49 51.2233C114.597 51.1004 115.59 51.9044 115.701 53.0121L115.801 54.0099C115.911 55.109 115.109 56.0891 114.01 56.199L113.199 56.2801C112.022 56.3978 111 55.4733 111 54.29V53.2901Z" fill="var(--svgColor)" />
    <path d="M111 72.2901C111 71.271 111.766 70.4149 112.779 70.3023L113.49 70.2233C114.597 70.1004 115.59 70.9044 115.701 72.0121L115.801 73.0099C115.911 74.109 115.109 75.0891 114.01 75.199L113.199 75.2801C112.022 75.3978 111 74.4733 111 73.29V72.2901Z" fill="var(--svgColor)" />
  </svg>
);

export default DataCaptureIcon;
