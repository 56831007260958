import React from "react";

const ConceptGroupsIcon = (props) => (
  <svg {...props} className={"icon"} width="179" height="101" viewBox="0 0 179 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M88.8879 2.29785H4.63672C4.08443 2.29785 3.63672 2.74557 3.63672 3.29785V46.4434C3.63672 46.9957 4.08444 47.4434 4.63672 47.4434H88.8879C89.4402 47.4434 89.8879 46.9957 89.8879 46.4434V3.29785C89.8879 2.74557 89.4402 2.29785 88.8879 2.29785Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M175.358 7.53107H101.102C100.549 7.53107 100.102 7.97878 100.102 8.53107V46.4476C100.102 46.9998 100.549 47.4476 101.102 47.4476H175.358C175.911 47.4476 176.358 46.9998 176.358 46.4476V8.53107C176.358 7.97878 175.911 7.53107 175.358 7.53107Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M175.358 57.1183H101.102C100.549 57.1183 100.102 57.566 100.102 58.1183V96.0348C100.102 96.5871 100.549 97.0348 101.102 97.0348H175.358C175.911 97.0348 176.358 96.5871 176.358 96.0348V58.1183C176.358 57.566 175.911 57.1183 175.358 57.1183Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M88.8897 57.1183H14.6328C14.0805 57.1183 13.6328 57.566 13.6328 58.1183V96.0348C13.6328 96.5871 14.0805 97.0348 14.6328 97.0348H88.8897C89.442 97.0348 89.8897 96.5871 89.8897 96.0348V58.1183C89.8897 57.566 89.442 57.1183 88.8897 57.1183Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M24.207 13.3327H80.9859" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M24.207 24.8727H80.9859" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M24.207 36.5517H80.9859" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M18.2712 10.6087H12.7656V16.0566H18.2712V10.6087Z" fill="var(--svgColor)"/>
    <path d="M18.2712 22.1487H12.7656V27.5967H18.2712V22.1487Z" fill="var(--svgColor)"/>
    <path d="M18.2712 33.8277H12.7656V39.2756H18.2712V33.8277Z" fill="var(--svgColor)"/>
    <path d="M31.9336 67.5174H82.8623" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M31.9336 77.866H82.8623" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M31.9336 88.345H82.8623" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M26.6073 65.0755H21.6719V69.9593H26.6073V65.0755Z" fill="var(--svgColor)"/>
    <path d="M26.6073 75.4241H21.6719V80.3079H26.6073V75.4241Z" fill="var(--svgColor)"/>
    <path d="M26.6073 85.9031H21.6719V90.7869H26.6073V85.9031Z" fill="var(--svgColor)"/>
    <path d="M117.898 17.1808H168.831" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M117.898 27.5293H168.831" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M117.898 38.0084H168.831" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M112.572 14.7389H107.637V19.6226H112.572V14.7389Z" fill="var(--svgColor)"/>
    <path d="M112.572 25.0874H107.637V29.9712H112.572V25.0874Z" fill="var(--svgColor)"/>
    <path d="M112.572 35.5665H107.637V40.4503H112.572V35.5665Z" fill="var(--svgColor)"/>
    <path d="M117.898 67.4542H168.831" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M117.898 77.8028H168.831" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M117.898 88.2819H168.831" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M112.572 65.0081H107.637V69.8919H112.572V65.0081Z" fill="var(--svgColor)"/>
    <path d="M112.572 75.3609H107.637V80.2447H112.572V75.3609Z" fill="var(--svgColor)"/>
    <path d="M112.572 85.8358H107.637V90.7196H112.572V85.8358Z" fill="var(--svgColor)"/>
  </svg>
);

export default ConceptGroupsIcon;
