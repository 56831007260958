import React from "react";

const ConsentTypesIcon = (props) => (
    <svg {...props} className={"icon"} width="72" height="91" viewBox="0 0 82 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.7542 31.4543H62.8018" stroke="var(--svgColor)" strokeWidth="2.17265" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M20.7542 37.7708H62.8018" stroke="var(--svgColor)" strokeWidth="2.17265" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M20.7542 44.0872H62.8018" stroke="var(--svgColor)" strokeWidth="2.17265" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M20.7542 50.4033H42.5148" stroke="var(--svgColor)" strokeWidth="2.17265" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M59.8388 3.28735H23.7208C22.9208 3.28735 22.2723 3.93584 22.2723 4.73579V17.424C22.2723 18.224 22.9208 18.8725 23.7208 18.8725H59.8388C60.6387 18.8725 61.2872 18.224 61.2872 17.424V4.73579C61.2872 3.93584 60.6388 3.28735 59.8388 3.28735Z" stroke="var(--svgColor)" strokeWidth="2.17265" strokeMiterlimit="10" />
        <path d="M62.7343 6.30762C61.9344 6.30762 61.2859 6.9561 61.2859 7.75605V14.2372C61.2859 15.0371 61.9344 15.6856 62.7343 15.6856H69.4898C70.2898 15.6856 70.9382 16.3341 70.9382 17.1341V86.6929C70.9382 87.4928 70.2898 88.1413 69.4898 88.1413H12.5077C11.7078 88.1413 11.0593 87.4928 11.0593 86.6929V17.1341C11.0593 16.3341 11.7078 15.6856 12.5077 15.6856H20.8226C21.6225 15.6856 22.271 15.0371 22.271 14.2372V7.75605C22.271 6.9561 21.6225 6.30762 20.8226 6.30762H4.75776C3.95781 6.30762 3.30933 6.9561 3.30933 7.75605V96.0709C3.30933 96.8708 3.95781 97.5193 4.75776 97.5193H77.2398C78.0397 97.5193 78.6882 96.8708 78.6882 96.0709V7.75605C78.6882 6.9561 78.0397 6.30762 77.2398 6.30762H62.7343Z" stroke="var(--svgColor)" strokeWidth="2.17265" strokeMiterlimit="10" />
        <path d="M32.7571 10.7627H50.7987" stroke="var(--svgColor)" strokeWidth="2.17265" strokeMiterlimit="10" strokeLinecap="round" />
        <ellipse cx="52.6583" cy="70.1852" rx="10.1435" ry="10.1435" fill="#F5A506" />
        <path d="M47.8583 70.3201L50.1858 73.1124L57.4582 67.2576" stroke="var(--svgColor)" strokeWidth="2.89687" strokeMiterlimit="10" strokeLinecap="round" />
    </svg>
);

export default ConsentTypesIcon;
