import React from "react";

const ActionTypesIcon = (props) => (
  <svg {...props} className={"icon"} width="107" height="92" viewBox="0 0 107 92" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M88.1885 87.0174C88.1887 88.1222 87.2932 89.0179 86.1885 89.0179H24.1692C23.0646 89.0179 22.1692 88.1224 22.1692 87.0179V4.98145C22.1692 3.87688 23.0646 2.98145 24.1692 2.98145H65.9852H86.1877C87.2927 2.98145 88.1883 3.87761 88.1877 4.98266L88.1758 24.5749L88.1885 87.0174Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
    <path d="M32.4479 43.7286L34.3412 46L40.2572 41.2373" stroke="var(--svgColor)" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M32.3416 25.7491L34.2349 28.0205L40.1509 23.2578" stroke="var(--svgColor)" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M32.3416 61.7081L34.2349 63.9795L40.1509 59.2168" stroke="var(--svgColor)" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M18.3065 11.8867L22.1692 11.8867" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M18.3065 34.6289L22.1692 34.6289" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M18.3065 46L22.1692 46" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M18.3065 23.2578L22.1692 23.2578" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M18.3065 68.7417L22.1692 68.7417" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M18.3065 57.3711L22.1692 57.3711" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M18.3065 80.1128L22.1692 80.1128" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M47.379 28.0205L74.0164 28.0205" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M47.379 44.9946L74.0164 44.9946" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M47.379 61.6758L74.0164 61.6758" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default ActionTypesIcon;
