import React from "react";

const AssistanceEmployerIcon = (props) => (
  <svg {...props} className={"icon"} width="112" height="96" viewBox="0 0 112 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7387_257)">
      <path fillRule="evenodd" clipRule="evenodd" d="M80.407 62.9176L73.9497 62.9176L73.9497 78.1671C67.4695 86.9442 57.0517 92.6368 45.3044 92.6368C25.6521 92.6368 9.72082 76.7055 9.72082 57.0532L9.72082 38.4034C9.72082 18.7511 25.6521 2.81982 45.3044 2.81982C64.9566 2.81982 80.8879 18.7511 80.8879 38.4034L80.8879 57.0532C80.8879 59.0507 80.7233 61.0098 80.407 62.9176Z" fill="#FF592C" />
      <path fillRule="evenodd" clipRule="evenodd" d="M23.8247 25.4567C23.3842 25.8876 22.6789 25.878 22.2518 25.4374C21.8209 24.9969 21.8306 24.2916 22.2711 23.8645C22.3368 23.7988 44.635 1.50057 68.0928 23.7485C68.5373 24.1697 68.5566 24.8751 68.1295 25.3157C67.7083 25.7602 67.003 25.7795 66.5623 25.3524C44.6815 4.60211 23.8866 25.3968 23.8247 25.4567Z" fill="var(--svgColor)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M39.7246 23.9419C39.1313 24.0848 38.5285 23.7196 38.3836 23.1263C38.2386 22.533 38.6058 21.9302 39.1991 21.7852C39.2784 21.7659 66.6549 14.7396 71.6309 46.8255C71.7236 47.4285 71.3101 47.9986 70.7034 48.0913C70.1004 48.184 69.5303 47.7705 69.4376 47.1637C64.8422 17.4895 39.7941 23.9225 39.7246 23.9419Z" fill="var(--svgColor)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M33.6104 23.7878C34.1902 23.5926 34.8202 23.9077 35.0154 24.4874C35.2106 25.0672 34.8955 25.6972 34.3158 25.8924C34.2829 25.902 22.6516 29.6626 21.1869 46.7296C21.1366 47.3423 20.5975 47.7964 19.9849 47.7461C19.3722 47.6959 18.9181 47.1567 18.9684 46.5441C20.5648 28.0141 33.5738 23.8032 33.6104 23.7878Z" fill="var(--svgColor)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.5646 62.1347C22.0969 62.5288 21.3955 62.4632 21.0013 61.9954C20.6071 61.5277 20.6728 60.8263 21.1405 60.4322C22.3599 59.4119 23.2932 57.205 24.0025 54.6388C24.7445 51.9585 25.2276 48.8975 25.5427 46.3333C26.1185 41.7284 27.9736 37.4615 30.7506 34.1261C32.7487 31.7279 35.2222 29.8091 38.0319 28.5974C40.8609 27.378 44.0244 26.8679 47.3906 27.2891C51.4989 27.7992 55.9048 29.7007 60.3398 33.4013C60.8075 33.7917 60.8731 34.4912 60.4827 34.9589C60.0923 35.4266 59.3929 35.4922 58.9252 35.1018C54.8438 31.7027 50.836 29.9577 47.122 29.4939C44.1634 29.1267 41.3904 29.5731 38.9131 30.6397C36.4222 31.7162 34.2289 33.4128 32.4568 35.5424C29.9524 38.553 28.2731 42.4161 27.7494 46.5998C27.4209 49.2239 26.9242 52.3874 26.1398 55.2261C25.3183 58.1771 24.1723 60.7839 22.5646 62.1347Z" fill="var(--svgColor)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M26.6633 69.8795C26.1956 70.2737 25.4941 70.2081 25.1 69.7404C24.7058 69.2726 24.7715 68.5712 25.2392 68.177C26.3986 67.2069 27.7204 64.1519 28.876 60.4416C30.2114 56.1516 31.2742 51.0597 31.6027 47.2278C31.6066 47.1678 31.6163 47.1118 31.6297 47.0558C32.3293 42.8914 33.8463 39.7879 35.8077 37.5908C37.458 35.7454 39.4213 34.5396 41.489 33.8806C43.5528 33.2275 45.7094 33.1153 47.7598 33.4535C50.3472 33.8806 52.7783 35.0207 54.6372 36.6864C57.934 39.6353 58.3378 43.2527 58.7746 47.1254C58.8075 47.4365 58.8442 47.7515 58.9041 48.2578C59.053 49.5196 59.2056 50.9845 59.364 52.4551C60.0557 59.0737 60.7592 65.8255 63.5806 72.0499C63.8318 72.6103 63.5845 73.2654 63.0241 73.5205C62.4637 73.7717 61.8086 73.5244 61.5535 72.964C58.5892 66.4208 57.8665 59.4814 57.1515 52.6832C57.0123 51.3518 56.8732 50.0301 56.6916 48.5189C56.6587 48.2599 56.6124 47.8135 56.5621 47.3691C56.1776 43.9274 55.8201 40.7138 53.1571 38.335C51.6131 36.9533 49.5764 36.0025 47.4024 35.6412C45.6768 35.359 43.868 35.446 42.1578 35.989C40.4516 36.5321 38.8283 37.5292 37.4543 39.0636C35.7577 40.965 34.4358 43.7014 33.8137 47.4252C33.4698 51.3944 32.3721 56.6583 30.9884 61.0971C29.7301 65.1721 28.1764 68.6118 26.6633 69.8795Z" fill="var(--svgColor)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M29.9427 74.5444C29.5523 75.0178 28.8529 75.0912 28.3794 74.7029C27.906 74.3145 27.8326 73.6131 28.2209 73.1396C29.0461 72.129 30.8084 69.4623 32.5669 66.1596C33.7997 63.8407 35.0154 61.2397 35.8868 58.7159C36.6848 56.4106 36.9109 54.7041 37.1661 52.8123C37.3053 51.7824 37.4482 50.6983 37.6859 49.4692C38.0008 47.8324 38.3815 46.4353 38.8183 45.2623C39.2917 43.9829 39.8386 42.9434 40.4415 42.1182C41.4811 40.6998 42.919 39.8456 44.4494 39.5634C45.6514 39.3412 46.9075 39.4707 48.0669 39.948C49.2224 40.4214 50.2795 41.2369 51.0912 42.3925C52.2545 44.0427 52.9134 46.3906 52.6024 49.4091C52.1347 53.9774 50.2874 60.1128 48.0766 65.8966C45.3268 73.0853 41.9817 79.8062 39.9933 82.3512C39.6185 82.8344 38.9228 82.9174 38.4397 82.5406C37.9566 82.1657 37.8735 81.47 38.2504 80.9869C40.1151 78.5984 43.3249 72.1151 46.0051 65.1063C48.1617 59.4676 49.9569 53.535 50.3975 49.1851C50.6487 46.7542 50.1559 44.9221 49.2747 43.6757C48.7143 42.8834 47.9993 42.327 47.2244 42.0061C46.4591 41.6912 45.634 41.608 44.8494 41.751C43.8523 41.9365 42.915 42.493 42.233 43.4302C41.7556 44.0833 41.3093 44.9415 40.9073 46.0371C40.5131 47.1076 40.1613 48.383 39.8734 49.8962C39.6415 51.1116 39.5024 52.1417 39.3671 53.1194C39.0985 55.1175 38.857 56.9223 37.9797 59.45C37.0521 62.1304 35.796 64.8338 34.5283 67.2145C32.7081 70.635 30.8374 73.4545 29.9427 74.5444Z" fill="var(--svgColor)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M61.7676 40.8641C61.5164 40.3037 61.7676 39.6486 62.328 39.3994C62.8884 39.1501 63.5435 39.3994 63.7928 39.9598C65.4257 43.6197 65.7502 48.5358 66.0691 53.3071C66.3744 57.9352 66.6721 62.4242 68.1329 65.1065C68.4247 65.6437 68.2256 66.3162 67.6884 66.6099C67.1512 66.9037 66.4787 66.7026 66.185 66.1654C64.4884 63.0485 64.1773 58.3237 63.8525 53.4481C63.5494 48.8701 63.2382 44.153 61.7676 40.8641Z" fill="var(--svgColor)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M44.148 46.899C44.1809 46.2864 44.7007 45.8188 45.3114 45.8458C45.924 45.8788 46.3916 46.3985 46.3645 47.0092C46.0727 52.648 44.8939 58.9749 42.5886 65.0504C40.604 70.2872 37.7788 75.3366 33.972 79.6169C33.5642 80.0768 32.8628 80.1174 32.4047 79.7096C31.9467 79.3018 31.9043 78.6004 32.312 78.1424C35.9334 74.0746 38.6195 69.2649 40.5113 64.2715C42.7334 58.4182 43.8698 52.3252 44.148 46.899Z" fill="var(--svgColor)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M46.3337 84.622C45.9898 85.1322 45.2998 85.2674 44.7897 84.9235C44.2796 84.5796 44.1443 83.8897 44.4882 83.3795C46.5656 80.305 48.1752 77.2074 49.5338 74.1078C50.9058 70.9812 52.0286 67.8468 53.1242 64.7395V64.7356C53.3291 64.1558 53.9628 63.8506 54.5426 64.0535C54.9464 64.1927 55.215 64.5443 55.2749 64.9385L55.4411 65.7308C56.332 69.9377 57.1436 73.7774 59.5785 78.1234C59.88 78.6567 59.6905 79.3331 59.1514 79.6346C58.618 79.9361 57.9417 79.7466 57.6402 79.2075C55.6324 75.6228 54.6719 72.417 53.9067 69.1241C53.1878 71.072 52.4264 73.0296 51.5645 74.991C50.154 78.2218 48.4844 81.4412 46.3337 84.622Z" fill="var(--svgColor)" />
      <path d="M99.3345 61.2875H96.5236V57.031C96.5236 54.8995 94.7462 53.1177 92.6199 53.1177H82.4229C80.2966 53.1177 78.5192 54.8995 78.5192 57.031V61.2875H75.7807C73.3845 61.2875 71.4689 63.2079 71.4689 65.61V81.0587C71.4689 83.4608 73.3845 85.3812 75.7807 85.3812H99.3411C101.737 85.3812 103.653 83.4608 103.653 81.0587V65.6166C103.653 63.2145 101.737 61.2875 99.3345 61.2875ZM81.1853 57.031C81.1853 56.3447 81.7317 55.797 82.4163 55.797H92.6923C93.3769 55.797 93.9233 56.3447 93.9233 57.031V61.2875H81.1853V57.031ZM100.987 81.0587H100.98C100.98 81.9496 100.23 82.7085 99.3345 82.7085H75.7807C74.892 82.7085 74.135 81.9562 74.135 81.0587V65.6166C74.135 64.7257 74.8854 63.9668 75.7807 63.9668H99.3411C100.23 63.9668 100.987 64.7191 100.987 65.6166V81.0587Z" fill="var(--svgColor)" />
    </g>
    <defs>
      <clipPath id="clip0_7387_257">
        <rect x="111.5" y="0.228516" width="95" height="111" rx="2" transform="rotate(90 111.5 0.228516)" fill="var(--svgColor)" />
      </clipPath>
    </defs>
  </svg>
);

export default AssistanceEmployerIcon;