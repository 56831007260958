import React from "react";

const ContractTypesIcon = (props) => (
    <svg {...props} className={"icon"} width="73" height="92" viewBox="0 0 73 92" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M69.5076 86.5509C69.5078 87.6557 68.6123 88.5513 67.5076 88.5513H5.48828C4.38371 88.5513 3.48828 87.6559 3.48828 86.5513V4.51489C3.48828 3.41033 4.38371 2.51489 5.48828 2.51489H46.4918C47.0128 2.51489 47.5132 2.71819 47.8866 3.08152L68.8899 23.5196C69.2767 23.896 69.495 24.4128 69.4951 24.9525L69.5076 86.5509Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
        <path d="M12 41H57" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 48H57" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 55H57" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 62H57" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 76H28" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M47.3047 2.51489V22.4402C47.3047 22.9925 47.7524 23.4402 48.3047 23.4402H69.0446" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
        <rect x="36" y="73" width="20" height="5" rx="1" stroke="var(--svgColor)" strokeWidth="2" />
    </svg >
);

export default ContractTypesIcon;
