import React from "react";

const ExitIcon = (props) => (
  <svg {...props} className={"icon"} width="89" height="97" viewBox="0 0 89 97" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.5472 2.70571V78.4895C4.5472 79.2656 4.99619 79.9716 5.69904 80.3008L34.7972 93.9267C36.1234 94.5478 37.6454 93.5799 37.6454 92.1155V18.6084C37.6454 17.8195 37.1816 17.1043 36.4613 16.7824L4.70841 2.59407C4.69043 2.58523 4.67041 2.58134 4.65041 2.58283C4.63041 2.58432 4.61118 2.5911 4.59472 2.60251C4.57826 2.61391 4.56518 2.6295 4.55682 2.64766C4.54847 2.66581 4.54513 2.68585 4.5472 2.70571Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M4.61644 2.59402L62.4263 2.52657C63.5318 2.52528 64.4286 3.42109 64.4286 4.52657V33.32" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M65.5017 54.1087V81.2087C65.5017 82.3133 64.6063 83.2087 63.5017 83.2087H37.6524" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M84.9793 43.2991L51.579 43.2991" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M73.6429 31.1846L84.7014 41.8823C85.5058 42.6605 85.5151 43.9472 84.722 44.737L73.8812 55.5323" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M31.2289 46.1463V56.1254" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
  </svg>

);

export default ExitIcon;
