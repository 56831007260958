import React from "react";
import Icon from "./icon";

function DocumentIcon(props) {
  return (
    <Icon viewBox="0 0 512 512" {...props}>
      <path d="m402 412v-347c0-35.839844-29.160156-65-65-65h-272c-35.839844 0-65 29.160156-65 65v45h110v337c0 35.839844 29.160156 65 65 65h272c35.839844 0 65-29.160156 65-65v-35zm-372-332v-15c0-19.300781 15.699219-35 35-35h10c19.300781 0 35 15.699219 35 35v15zm184.796875 362c0 21.964844-17.863281 39.902344-39.828125 40-19.285156-.015625-34.96875-15.710938-34.96875-35v-382c0-12.875-3.765625-24.890625-10.25-35h207.25c19.300781 0 35 15.699219 35 35v347h-157.203125zm267.203125 5c0 19.300781-15.699219 35-35 35h-214.8125c7.941406-11.351562 12.605469-25.148438 12.605469-40h237.207031zm0 0" />
      <path d="m305 222v-30h-61.78125l11.859375-39.132812 29.050781-6.195313-6.257812-29.34375-12.855469 2.742187 12.339844-40.71875-28.710938-8.703124-17.140625 56.574218-28.632812 6.105469 6.257812 29.34375 12.433594-2.65625-18.78125 61.984375zm0 0" />
      <path d="m190 262h132v30h-132zm0 0" />
      <path d="m190 322h102v30h-102zm0 0" />
    </Icon>
  );
}
export default DocumentIcon;
