import React from "react";

const FamilyAsignationsIcon = (props) => (
  <svg {...props} className={"icon"} width="130" height="93" viewBox="0 0 130 93" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M33 41.5459C38.9551 41.5459 44.694 44.1138 48.9457 48.7311C52.8769 53.0003 55.233 58.6956 55.5694 64.7298H10.4307C10.767 58.6956 13.1231 53.0003 17.0543 48.7311C21.3061 44.1138 27.045 41.5459 33 41.5459Z" stroke="var(--svgColor)" strokeWidth="3" />
    <path d="M47.9893 21.6765C47.9893 29.9116 41.2842 36.598 33 36.598C24.7158 36.598 18.0106 29.9116 18.0106 21.6765C18.0106 13.4413 24.7158 6.75488 33 6.75488C41.2842 6.75488 47.9893 13.4413 47.9893 21.6765Z" stroke="var(--svgColor)" strokeWidth="3" />
    <path d="M94.5001 41.5459C100.343 41.5459 105.981 44.1067 110.163 48.7221C114.03 52.9906 116.35 58.689 116.681 64.7298H72.3189C72.6498 58.689 74.9703 52.9906 78.8375 48.7221C83.0191 44.1067 88.6568 41.5459 94.5001 41.5459Z" stroke="var(--svgColor)" strokeWidth="3" />
    <path d="M109.223 21.6765C109.223 29.9346 102.614 36.598 94.5 36.598C86.3856 36.598 79.7766 29.9346 79.7766 21.6765C79.7766 13.4183 86.3856 6.75488 94.5 6.75488C102.614 6.75488 109.223 13.4183 109.223 21.6765Z" stroke="var(--svgColor)" strokeWidth="3" />
    <path d="M65 57.5C69.6413 57.5 74.0925 59.3437 77.3744 62.6256C80.3008 65.5521 82.0838 69.4083 82.4356 73.5H47.5644C47.9162 69.4083 49.6992 65.5521 52.6256 62.6256C55.9075 59.3437 60.3587 57.5 65 57.5Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" />
    <path d="M76.5 41.5C76.5 47.5202 71.4073 52.5 65 52.5C58.5927 52.5 53.5 47.5202 53.5 41.5C53.5 35.4798 58.5927 30.5 65 30.5C71.4073 30.5 76.5 35.4798 76.5 41.5Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" />
    <rect x="73" y="54" width="36" height="36" rx="18" fill="var(--svgColor)" />
    <path d="M89.5714 83.3949V80.7323C87.9803 80.6892 86.4185 80.2762 85 79.5236L85.8067 76.2479C87.1815 77.0231 88.7145 77.4436 90.2773 77.4741C91.8235 77.4741 92.8656 76.8435 92.8656 75.7224C92.8656 74.6013 92.0084 73.9707 90.0084 73.2875C87.1345 72.2715 85.1681 70.8702 85.1681 68.155C85.2232 66.8907 85.7143 65.6896 86.551 64.7724C87.3878 63.8553 88.514 63.2836 89.7227 63.1626V60.5H92.3614V62.9699C93.7095 62.9879 95.0386 63.3044 96.2605 63.8983L95.4538 67.104C94.2592 66.4592 92.9335 66.1228 91.5882 66.123C89.9076 66.123 89.2857 66.9288 89.2857 67.7171C89.2857 68.5053 90.2269 69.2411 92.5126 70.1169C95.7227 71.2906 97 72.8321 97 75.3721C97.0018 76.7186 96.5077 78.0145 95.6195 78.9924C94.7314 79.9702 93.517 80.5554 92.2269 80.6272V83.5L89.5714 83.3949Z" fill="#F5A506" />
  </svg>
);

export default FamilyAsignationsIcon;
