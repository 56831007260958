import React from "react";

const AssetsAndDiscountsEntryPerConceptSetIcon = (props) => (
  <svg {...props} className={"icon"} width="86" height="99" viewBox="0 0 86 99" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M80.8903 87.8349C80.8905 88.9396 79.995 89.8353 78.8903 89.8353H16.871C15.7664 89.8353 14.871 88.9398 14.871 87.8353V5.79883C14.871 4.69426 15.7664 3.79883 16.871 3.79883H57.8745C58.3955 3.79883 58.8959 4.00212 59.2693 4.36546L80.2726 24.8035C80.6594 25.1799 80.8777 25.6967 80.8778 26.2365L80.8903 87.8349Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
    <path d="M58.1864 4.66895V24.5943C58.1864 25.1466 58.6341 25.5943 59.1864 25.5943H79.9263" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
    <path d="M70.0837 94.0509C70.084 95.1557 69.1885 96.0513 68.0837 96.0513H6.06445C4.95988 96.0513 4.06445 95.1559 4.06445 94.0513V12.0149C4.06445 10.9103 4.95988 10.0149 6.06445 10.0149H47.0679C47.5889 10.0149 48.0894 10.2182 48.4627 10.5815L69.466 31.0196C69.8529 31.396 70.0711 31.9128 70.0713 32.4525L70.0837 94.0509Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
    <path d="M47.8809 10.0149V29.9402C47.8809 30.4925 48.3286 30.9402 48.8809 30.9402H69.6208" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
    <path d="M22.4199 53.6289H54.3449" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22.4199 64.0161H54.3449" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22.4199 74.4033H54.3449" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22.4226 68.1677C28.3184 68.1677 33.0979 63.3882 33.0979 57.4924C33.0979 51.5966 28.3184 46.8171 22.4226 46.8171C16.5267 46.8171 11.7473 51.5966 11.7473 57.4924C11.7473 63.3882 16.5267 68.1677 22.4226 68.1677Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="2.4894" strokeMiterlimit="10" />
    <path d="M26 58H16" stroke="var(--svgColor)" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M22.5 53L26.6185 57.437C26.9719 57.8178 26.965 58.4087 26.6028 58.7811L22.5 63" stroke="var(--svgColor)" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
  </svg>
);

export default AssetsAndDiscountsEntryPerConceptSetIcon;
