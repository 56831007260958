import React from "react";

const BenefitsForPlansIcon = (props) => (
    <svg {...props} className={"icon"} width="100" height="99" viewBox="0 0 100 99" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.8211 66.3949H19.0314C26.3868 59.5066 43.9516 49.598 55.3678 65.0702H65.542V65.0702C68.2421 65.0702 70.4309 67.259 70.4309 69.9591V70.3026C70.4309 73.1925 68.0882 75.5351 65.1984 75.5351H39.9083" stroke="var(--svgColor)" strokeWidth="2.41359" strokeLinecap="round" />
        <path d="M13.0854 93.286H67.9203C70.8272 93.286 77.6188 92.0938 81.5299 87.325C84.7438 83.4064 91.8655 73.4948 96.7219 66.6309C98.3352 64.3507 98.0627 61.2453 96.0901 59.2677V59.2677C93.5829 56.7541 89.4452 57.1029 87.2413 59.8862C81.6628 66.9313 73.4724 75.4975 65.3557 75.4975" stroke="var(--svgColor)" strokeWidth="2.41359" strokeLinecap="round" />
        <mask id="path-3-inside-1_5706_19081" fill="white">
            <rect x="0.499985" y="61.3093" width="12.9235" height="36.9261" rx="1.60906" />
        </mask>
        <rect x="0.499985" y="61.3093" width="12.9235" height="36.9261" rx="1.60906" stroke="var(--svgColor)" strokeWidth="4.82718" mask="url(#path-3-inside-1_5706_19081)" />
        <path d="M48.0545 44.4567L54.7762 30.5553C55.0276 30.0354 55.6961 29.8823 56.1487 30.241L60.0712 33.3497C60.1557 33.4167 60.2516 33.468 60.3543 33.5012L65.4956 35.161C65.9843 35.3188 66.2429 35.8523 66.0641 36.3337L60.9442 50.114C60.6941 50.7874 59.7955 50.9125 59.3709 50.3331L55.3036 44.7831C55.088 44.489 54.7167 44.3536 54.3625 44.4398L49.08 45.7259C48.3392 45.9063 47.7225 45.1432 48.0545 44.4567Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2.70642" />
        <path d="M68.9607 49.6395L63.3242 35.2839C63.1109 34.7408 63.4616 34.1411 64.0395 34.0607L69.9924 33.2316C70.1304 33.2124 70.2621 33.1614 70.3771 33.0828L73.9485 30.6416C74.3984 30.334 75.0162 30.4926 75.2624 30.9788L81.8654 44.0163C82.19 44.6571 81.6718 45.4018 80.9581 45.3202L74.1218 44.5386C73.7596 44.4972 73.408 44.6779 73.2309 44.9965L70.5889 49.7482C70.2183 50.4146 69.2394 50.3493 68.9607 49.6395Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2.70642" />
        <circle cx="64.5732" cy="18.4916" r="16.6384" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="2.70642" />
    </svg>
);

export default BenefitsForPlansIcon;
