import React from "react";

const ContractorIcon = (props) => (
    <svg {...props} className={"icon"} width="88" height="95" viewBox="0 0 88 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.037 25.5229H70.963C72.3437 25.5229 73.463 26.6422 73.463 28.0229V29.8505C73.463 31.2312 72.3437 32.3505 70.963 32.3505H17.037C15.6563 32.3505 14.537 31.2312 14.537 29.8505V28.0229C14.537 26.6422 15.6563 25.5229 17.037 25.5229Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" />
        <path d="M64.4921 32.5C64.4226 35.0072 63.8967 37.4822 62.9389 39.8022C61.9083 42.2984 60.3978 44.5663 58.494 46.4763C56.5901 48.3864 54.3302 49.9013 51.8434 50.9347C49.3566 51.9682 46.6914 52.5 44 52.5C41.3086 52.5 38.6434 51.9682 36.1566 50.9347C33.6698 49.9013 31.4099 48.3864 29.506 46.4763C27.6022 44.5663 26.0917 42.2984 25.0611 39.8022C24.1033 37.4822 23.5774 35.0072 23.5079 32.5H64.4921Z" stroke="var(--svgColor)" strokeWidth="3" />
        <path d="M21.5 23.9759L21.5 23.9753C21.4988 21.0237 22.0799 18.1008 23.2101 15.3735C24.3403 12.6463 25.9974 10.1681 28.0868 8.08082C30.1762 5.99349 32.657 4.33789 35.3874 3.20868C38.1178 2.07948 41.0442 1.49884 43.9994 1.5L44 0V1.5C49.9679 1.5 55.691 3.86836 59.9104 8.08358C64.1298 12.2987 66.5 18.0154 66.5 23.9759V25.5H21.5L21.5 23.9759Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" />
        <path d="M44 58.2816C52.6372 58.2816 60.948 62.0028 67.0958 68.6723C72.9224 74.9934 76.3475 83.4693 76.6932 92.4081H11.3068C11.6524 83.4693 15.0776 74.9934 20.9041 68.6723C27.052 62.0028 35.3628 58.2816 44 58.2816Z" stroke="var(--svgColor)" strokeWidth="3" />
        <rect x="36.3518" y="10.3276" width="2.25926" height="9.91954" rx="1.12963" fill="#747474" stroke="var(--svgColor)" />
        <rect x="42.8704" y="10.3276" width="2.25925" height="9.91954" rx="1.12963" fill="#747474" stroke="var(--svgColor)" />
        <rect x="49.3889" y="10.3276" width="2.25926" height="9.91954" rx="1.12963" fill="#747474" stroke="var(--svgColor)" />
    </svg>

);

export default ContractorIcon;
