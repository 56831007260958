import React from "react";

const DivisionsIcon = (props) => (
    <svg {...props} className={"icon"} width="112" height="96" viewBox="0 0 112 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_7279_158)">
            <path d="M92 62H20C18.8954 62 18 62.8954 18 64V88C18 89.1046 18.8954 90 20 90H92C93.1046 90 94 89.1046 94 88V64C94 62.8954 93.1046 62 92 62Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
            <path d="M94 34H18V62H94V34Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
            <path d="M92 6H20C18.8954 6 18 6.89543 18 8V32C18 33.1046 18.8954 34 20 34H92C93.1046 34 94 33.1046 94 32V8C94 6.89543 93.1046 6 92 6Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
            <path d="M61 72H50C48.8954 72 48 72.8954 48 74V88C48 89.1046 48.8954 90 50 90H61C62.1046 90 63 89.1046 63 88V74C63 72.8954 62.1046 72 61 72Z" stroke="var(--svgColor)" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M80.5388 42H69.5388C68.4342 42 67.5388 42.8954 67.5388 44V52C67.5388 53.1046 68.4342 54 69.5388 54H80.5388C81.6434 54 82.5388 53.1046 82.5388 52V44C82.5388 42.8954 81.6434 42 80.5388 42Z" stroke="var(--svgColor)" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M42.2434 42H31.2434C30.1388 42 29.2434 42.8954 29.2434 44V52C29.2434 53.1046 30.1388 54 31.2434 54H42.2434C43.348 54 44.2434 53.1046 44.2434 52V44C44.2434 42.8954 43.348 42 42.2434 42Z" stroke="var(--svgColor)" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M80.5388 14H69.5388C68.4342 14 67.5388 14.8954 67.5388 16V24C67.5388 25.1046 68.4342 26 69.5388 26H80.5388C81.6434 26 82.5388 25.1046 82.5388 24V16C82.5388 14.8954 81.6434 14 80.5388 14Z" stroke="var(--svgColor)" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M42.2434 14H31.2434C30.1388 14 29.2434 14.8954 29.2434 16V24C29.2434 25.1046 30.1388 26 31.2434 26H42.2434C43.348 26 44.2434 25.1046 44.2434 24V16C44.2434 14.8954 43.348 14 42.2434 14Z" stroke="var(--svgColor)" strokeWidth="2" strokeMiterlimit="10" />
        </g>
        <defs>
            <clipPath id="clip0_7279_158">
                <rect x="111.391" y="0.772461" width="95" height="111" rx="2" transform="rotate(90 111.391 0.772461)" fill="var(--svgColor)" />
            </clipPath>
        </defs>
    </svg>

);

export default DivisionsIcon;
