import React from "react";
import Icon from "./icon";

function DirectoyButton(props) {
  return (
    <Icon viewBox="0 0 487 487" {...props}>
      <path
        d="M171.1,260.8c2.9-11.2,8.8-21.5,17.3-30.6c-14.2-9.2-26.1-21.8-34.3-36.6h27.1c3.6,11.6,8.2,21.8,13.2,30.7
				c3.5-3.2,7.3-6.1,11.3-8.6c1.9-1.3,3.8-2.5,5.7-3.6c-3.1-5.6-5.9-11.8-8.4-18.5h30.6v10.9c1.8-0.3,3.6-0.5,5.4-0.5
				c2.1-0.2,4.2-0.3,6.2-0.3c3.1,0,6.1,0.2,9,0.6v-10.6h30.7c-2.3,6.5-5.1,12.5-8,17.9c0.6,0.3,1.1,0.6,1.7,0.9
				c6.6,3.7,11.5,8,14.9,11.7c5-8.9,9.6-19.1,13.1-30.6h27.1c-8,14.4-19.3,26.6-33,35.7c2.7,2,5.3,4.4,7.7,7.4
				c4.9,6.2,8.1,14,9.7,23.3c38.3-24.5,63.7-67.4,63.7-116.1C381.8,67.9,320,6,243.9,6C167.9,6,106,67.8,106,143.9
				C106,193.1,132.1,236.5,171.1,260.8z M342.4,114.7c2.7,9.2,4.2,19,4.2,29.1s-1.5,19.9-4.2,29.1h-30.9c1.5-9.1,2.4-18.7,2.4-29.1
				s-0.9-20.1-2.4-29.1C311.5,114.7,342.4,114.7,342.4,114.7z M333.8,94h-27.1c-5.6-18.1-13.7-32.9-22.1-44.6
				C305.5,58.5,322.8,74.3,333.8,94z M254.2,45.6c10,10.1,22.4,26,30.6,48.5h-30.6V45.6z M254.2,114.7h36.2
				c1.7,8.9,2.8,18.6,2.8,29.1s-1,20.2-2.7,29.1h-36.3V114.7z M233.6,173h-36.2c-1.7-8.9-2.8-18.6-2.8-29.1s1-20.2,2.7-29.1h36.3V173
				z M233.6,94h-30.7c8.1-22.6,20.7-38.6,30.7-48.6V94z M203.2,49.5c-8.4,11.7-16.5,26.5-22.1,44.6H154
				C165,74.3,182.3,58.5,203.2,49.5z M141.1,143.8c0-10.1,1.5-19.9,4.2-29.1h30.9c-1.5,9.1-2.4,18.7-2.4,29.1s0.9,20.1,2.4,29.1
				h-30.9C142.6,163.7,141.1,153.9,141.1,143.8z"
      />
      <path
        d="M463.2,412c-23-8.8-36.7-17.7-36.7-17.7l-17.7,56l-2.4,7.6l-7.9-22.5c18.2-25.4-1.4-26.6-4.8-26.6l0,0c0,0,0,0-0.1,0
				c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0l0,0c-3.4,0-23,1.2-4.8,26.6l-7.9,22.5l-2.4-7.6l-17.7-56c0,0-7.7,5-21.3,11.3
				c-3.4-2.6-7.5-4.8-12.5-6.7c-27.3-10.4-43.7-21.1-43.7-21.1l-21.1,66.6l-2.9,9l-9.4-26.7c21.7-30.2-1.6-31.7-5.7-31.7l0,0
				c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0l0,0c-4,0-27.4,1.5-5.7,31.7l-9.4,26.7l-2.9-9l-21.1-66.6
				c0,0-16.4,10.7-43.7,21.1c-5,1.8-9.1,4-12.5,6.7c-13.6-6.2-21.3-11.3-21.3-11.3l-17.7,56l-2.4,7.6l-7.9-22.5
				c18.2-25.4-1.4-26.6-4.8-26.6l0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0s0,0-0.1,0l0,0c-3.4,0-23,1.2-4.8,26.6L81,457.9
				l-2.4-7.6l-17.7-56c0,0-13.8,9-36.7,17.7C-1.1,421.2,0.5,442,0,481h89.1h4.8h37.8h56h50.1h5.8h55.6h56h33.2h4.8H487
				C486.9,442,488.5,421.2,463.2,412z"
      />
      <path
        d="M381,266.7c-3.8,1.3-7.3,3.2-10.5,5.4c-3.9,2.5-7.5,5.5-10.8,8.9c-5.1,5.2-9.7,12-11.6,20.4c-1.7,6.3-1.3,12.9,0.1,19.9
				l0,0c0.4,2.1,0.9,4.1,1.7,6.2c-3.9-0.4-8.4,1.9-4.1,17c3.1,11.1,6,14.2,8.2,14.3c2,13.2,12.2,29.9,28.8,35.7
				c6.8,2.4,14.3,2.4,21.2,0c16.4-5.9,26.9-22.6,28.9-35.7c2.2-0.2,5.1-3.3,8.2-14.3c4.3-15.1-0.3-17.4-4.1-17
				c0.7-2.1,1.3-4.2,1.7-6.2c6.5-39.2-12.8-40.5-12.8-40.5s-3.2-6.1-11.6-10.8c-5.6-3.3-13.5-5.9-23.9-5
				C387.2,265,384,265.6,381,266.7L381,266.7z"
      />
      <path
        d="M54.8,358.8c2,13.2,12.2,29.9,28.8,35.7c6.8,2.4,14.3,2.4,21.2,0c16.4-5.9,26.9-22.6,28.9-35.7c2.2-0.2,5.1-3.3,8.2-14.3
				c4.3-15.1-0.3-17.4-4.1-17c0.7-2.1,1.3-4.2,1.7-6.2c6.5-39.2-12.8-40.5-12.8-40.5s-3.2-6.1-11.6-10.8c-5.6-3.3-13.5-5.9-23.9-5
				c-3.4,0.2-6.5,0.8-9.5,1.8l0,0c-3.8,1.3-7.3,3.2-10.4,5.4c-3.9,2.5-7.5,5.5-10.8,8.9c-5.1,5.2-9.7,12-11.6,20.4
				c-1.7,6.3-1.3,12.9,0.1,19.9l0,0c0.4,2.1,0.9,4.1,1.7,6.2c-3.8-0.4-8.4,1.9-4.1,17C49.7,355.5,52.6,358.6,54.8,358.8z"
      />
      <path
        d="M187.1,318.4c3.7,13.2,7.2,16.8,9.8,17.1c2.4,15.7,14.5,35.6,34.3,42.5c8.1,2.9,17.1,2.9,25.2-0.1
				c19.5-7,32-26.9,34.4-42.5c2.6-0.2,6.1-3.9,9.8-17.1c5.1-18-0.3-20.7-4.9-20.2c0.9-2.5,1.5-5,2-7.4c7.8-46.6-15.2-48.2-15.2-48.2
				s-3.8-7.3-13.8-12.9c-6.7-4-16.1-7.1-28.4-6c-4,0.2-7.8,1-11.3,2.2l0,0c-4.5,1.5-8.7,3.8-12.4,6.4c-4.6,2.9-9,6.5-12.8,10.6
				c-6.1,6.2-11.5,14.2-13.8,24.3c-2,7.5-1.5,15.3,0.1,23.7l0,0c0.4,2.5,1.1,4.9,2,7.4C187.4,297.8,182,300.4,187.1,318.4z"
      />
    </Icon>
  );
}
export default DirectoyButton;
