import React from "react";

const FilterIcon = (props) => (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18.925 -5.95292e-05H1.09448C0.7798 -0.000654392 0.491491 0.175028 0.347733 0.45481C0.201992 0.73836 0.227571 1.07981
            0.413762 1.33877L6.94612 10.5411C6.9483 10.5442 6.95068 10.5472 6.95286 10.5504C7.19021 10.8708 7.3187 11.2591 7.3193
            11.6578V19.1604C7.31791 19.3825 7.40516 19.596 7.5616 19.7536C7.71825 19.9111 7.93121 19.9999 8.15329 19.9999C8.26612
            19.9997 8.37795 19.9773 8.48225 19.9339L12.1529 18.5344C12.4817 18.4339 12.7 18.1235 12.7 17.75V11.6578C12.7006 11.2591
            12.8291 10.8708 13.0662 10.5504C13.0684 10.5472 13.0708 10.5442 13.073 10.5411L19.6055 1.33857C19.7917 1.07981 19.8173
            0.738558 19.6716 0.455008C19.528 0.175028 19.2395 -0.000654392 18.925 -5.95292e-05Z"
        />
    </svg>
);

export default FilterIcon;
