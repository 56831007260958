import React from "react";

const CategoryIcon = (props) => (
<svg {...props} className={"icon"} width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M44.3843 6.52696H10.7087C8.22464 6.52696 6.21094 8.55764 6.21094 11.0626V45.0222C6.21094 47.5272 8.22464 49.5579 10.7087 49.5579H44.3843C46.8683 49.5579 48.882 47.5272 48.882 45.0222V11.0626C48.882 8.55764 46.8683 6.52696 44.3843 6.52696Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
<path d="M85.3689 49.414C97.3669 49.414 107.093 39.6056 107.093 27.5064C107.093 15.4072 97.3669 5.59888 85.3689 5.59888C73.3709 5.59888 63.6445 15.4072 63.6445 27.5064C63.6445 39.6056 73.3709 49.414 85.3689 49.414Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
<path d="M5.07422 62.3875L48.9554 106.639" stroke="var(--svgColor)" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.07422 106.639L48.9554 62.3875" stroke="var(--svgColor)" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M66.0988 103.501L104.669 64.911C104.924 64.6564 105.249 64.4837 105.601 64.4145C105.954 64.3453 106.319 64.3827 106.65 64.5221C106.982 64.6614 107.265 64.8965 107.465 65.1977C107.664 65.4989 107.771 65.8529 107.771 66.2151V104.805C107.771 105.046 107.724 105.284 107.633 105.507C107.542 105.729 107.408 105.931 107.239 106.102C107.07 106.272 106.87 106.407 106.649 106.499C106.429 106.591 106.192 106.639 105.953 106.639H67.3794C67.0187 106.639 66.6659 106.531 66.366 106.329C66.0662 106.127 65.8327 105.839 65.6954 105.503C65.5581 105.167 65.5232 104.797 65.595 104.44C65.6668 104.084 65.8422 103.757 66.0988 103.501Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
);

export default CategoryIcon;
