import React from "react";

const ConceptsSetIcon = (props) => (
    <svg {...props} className={"icon"} width="104" height="100" viewBox="0 0 104 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M52.5 39.8796C60.144 44.3015 65.5823 52.1121 66.7607 61.2573C62.5497 63.64 57.6837 65 52.5 65C47.3163 65 42.4503 63.64 38.2393 61.2573C39.4177 52.1121 44.856 44.3015 52.5 39.8796Z" fill="#FF592C" />
        <circle cx="52.5" cy="36" r="27.5" stroke="var(--svgColor)" strokeWidth="3" />
        <circle cx="38" cy="65" r="27.5" stroke="var(--svgColor)" strokeWidth="3" />
        <circle cx="67" cy="65" r="27.5" stroke="var(--svgColor)" strokeWidth="3" />
    </svg>
);

export default ConceptsSetIcon;
