import React from "react";

const CalculationFunctionIcon = (props) => (
  <svg {...props} className={"icon"} width="74" height="93" viewBox="0 0 74 93" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M70.0076 87.051C70.0078 88.1557 69.1123 89.0514 68.0076 89.0514H5.98828C4.88371 89.0514 3.98828 88.156 3.98828 87.0514V5.01496C3.98828 3.91039 4.88371 3.01495 5.98828 3.01495H46.9918C47.5128 3.01495 48.0132 3.21825 48.3866 3.58158L69.3899 24.0196C69.7767 24.396 69.995 24.9128 69.9951 25.4526L70.0076 87.051Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round"/>
    <path d="M39.6714 39.076L34.398 70.962M27.2434 64.6852L18.707 55.1445L27.2434 46.6081M45.7506 64.6852L55.2913 55.1445L46.7549 46.6081" stroke="var(--svgColor)" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M47.8047 3.01495V22.9403C47.8047 23.4926 48.2524 23.9403 48.8047 23.9403H69.5446" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round"/>
  </svg>
);

export default CalculationFunctionIcon;
