import React from "react";

const AssistanceCollaboratorIcon = (props) => (
  <svg {...props} className={"icon"} width="112" height="96" viewBox="0 0 112 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7387_106)">
      <path fillRule="evenodd" clipRule="evenodd" d="M81.1671 51.246L81.1671 38.4034C81.1671 18.7511 65.2358 2.81982 45.5836 2.81982C25.9313 2.81982 10 18.7511 10 38.4034L10 57.0532C10 76.7055 25.9313 92.6368 45.5836 92.6368C61.4033 92.6368 74.8119 82.3133 79.44 68.0356C77.2874 66.1062 75.9392 63.3427 75.9392 60.2733C75.9392 56.4485 78.0326 53.0987 81.1671 51.246Z" fill="#FF4040" />
      <path fillRule="evenodd" clipRule="evenodd" d="M24.104 25.4567C23.6635 25.8876 22.9582 25.878 22.5311 25.4374C22.1002 24.9969 22.1099 24.2916 22.5504 23.8645C22.6161 23.7988 44.9143 1.50057 68.3721 23.7485C68.8166 24.1697 68.8359 24.8751 68.4088 25.3157C67.9876 25.7602 67.2823 25.7795 66.8416 25.3524C44.9608 4.60211 24.1659 25.3968 24.104 25.4567Z" fill="var(--svgColor)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M40.0039 23.9419C39.4106 24.0848 38.8078 23.7196 38.6629 23.1263C38.5179 22.533 38.8851 21.9302 39.4784 21.7852C39.5577 21.7659 66.9342 14.7396 71.9102 46.8255C72.0029 47.4285 71.5894 47.9986 70.9827 48.0913C70.3797 48.184 69.8096 47.7705 69.7169 47.1637C65.1215 17.4895 40.0734 23.9225 40.0039 23.9419Z" fill="var(--svgColor)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M33.8897 23.7878C34.4695 23.5926 35.0995 23.9077 35.2947 24.4874C35.4899 25.0672 35.1748 25.6972 34.5951 25.8924C34.5622 25.902 22.9309 29.6626 21.4662 46.7296C21.4159 47.3423 20.8768 47.7964 20.2642 47.7461C19.6515 47.6959 19.1974 47.1567 19.2477 46.5441C20.8441 28.0141 33.8531 23.8032 33.8897 23.7878Z" fill="var(--svgColor)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.8439 62.1347C22.3762 62.5288 21.6748 62.4632 21.2806 61.9954C20.8864 61.5277 20.9521 60.8263 21.4198 60.4322C22.6392 59.4119 23.5725 57.205 24.2818 54.6388C25.0238 51.9585 25.5069 48.8975 25.822 46.3333C26.3978 41.7284 28.2529 37.4615 31.0299 34.1261C33.028 31.7279 35.5015 29.8091 38.3112 28.5974C41.1402 27.378 44.3037 26.8679 47.6698 27.2891C51.7782 27.7992 56.1841 29.7007 60.6191 33.4013C61.0868 33.7917 61.1524 34.4912 60.762 34.9589C60.3716 35.4266 59.6722 35.4922 59.2045 35.1018C55.1231 31.7027 51.1153 29.9577 47.4013 29.4939C44.4427 29.1267 41.6697 29.5731 39.1924 30.6397C36.7015 31.7162 34.5082 33.4128 32.7361 35.5424C30.2317 38.553 28.5524 42.4161 28.0287 46.5998C27.7002 49.2239 27.2035 52.3874 26.4191 55.2261C25.5976 58.1771 24.4516 60.7839 22.8439 62.1347Z" fill="var(--svgColor)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M26.9426 69.8795C26.4749 70.2737 25.7734 70.2081 25.3793 69.7404C24.9851 69.2726 25.0507 68.5712 25.5185 68.177C26.6779 67.2069 27.9997 64.1519 29.1553 60.4416C30.4907 56.1516 31.5535 51.0597 31.882 47.2278C31.8859 47.1678 31.8956 47.1118 31.909 47.0558C32.6086 42.8914 34.1256 39.7879 36.087 37.5908C37.7373 35.7454 39.7006 34.5396 41.7683 33.8806C43.8321 33.2275 45.9887 33.1153 48.0391 33.4535C50.6265 33.8806 53.0576 35.0207 54.9165 36.6864C58.2133 39.6353 58.6171 43.2527 59.0539 47.1254C59.0868 47.4365 59.1235 47.7515 59.1834 48.2578C59.3323 49.5196 59.4849 50.9845 59.6433 52.4551C60.335 59.0737 61.0385 65.8255 63.8599 72.0499C64.1111 72.6103 63.8638 73.2654 63.3034 73.5205C62.743 73.7717 62.0879 73.5244 61.8328 72.964C58.8685 66.4208 58.1458 59.4814 57.4308 52.6832C57.2916 51.3518 57.1525 50.0301 56.9709 48.5189C56.938 48.2599 56.8917 47.8135 56.8414 47.3691C56.4569 43.9274 56.0994 40.7138 53.4364 38.335C51.8924 36.9533 49.8557 36.0025 47.6817 35.6412C45.9561 35.359 44.1473 35.446 42.4371 35.989C40.7309 36.5321 39.1076 37.5292 37.7336 39.0636C36.037 40.965 34.7151 43.7014 34.093 47.4252C33.7491 51.3944 32.6514 56.6583 31.2677 61.0971C30.0094 65.1721 28.4557 68.6118 26.9426 69.8795Z" fill="var(--svgColor)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M30.222 74.5444C29.8316 75.0178 29.1322 75.0912 28.6587 74.7029C28.1853 74.3145 28.1119 73.6131 28.5002 73.1396C29.3254 72.129 31.0877 69.4623 32.8462 66.1596C34.079 63.8407 35.2946 61.2397 36.1661 58.7159C36.9641 56.4106 37.1902 54.7041 37.4454 52.8123C37.5846 51.7824 37.7275 50.6983 37.9652 49.4692C38.2801 47.8324 38.6608 46.4353 39.0976 45.2623C39.571 43.9829 40.1179 42.9434 40.7208 42.1182C41.7604 40.6998 43.1983 39.8456 44.7287 39.5634C45.9307 39.3412 47.1868 39.4707 48.3462 39.948C49.5017 40.4214 50.5588 41.2369 51.3705 42.3925C52.5338 44.0427 53.1927 46.3906 52.8817 49.4091C52.414 53.9774 50.5667 60.1128 48.3559 65.8966C45.6061 73.0853 42.261 79.8062 40.2726 82.3512C39.8977 82.8344 39.2021 82.9174 38.719 82.5406C38.2359 82.1657 38.1528 81.47 38.5296 80.9869C40.3944 78.5984 43.6042 72.1151 46.2844 65.1063C48.441 59.4676 50.2362 53.535 50.6768 49.1851C50.928 46.7542 50.4352 44.9221 49.554 43.6757C48.9936 42.8834 48.2786 42.327 47.5036 42.0061C46.7384 41.6912 45.9133 41.608 45.1287 41.751C44.1316 41.9365 43.1943 42.493 42.5123 43.4302C42.0349 44.0833 41.5886 44.9415 41.1866 46.0371C40.7924 47.1076 40.4406 48.383 40.1527 49.8962C39.9208 51.1116 39.7816 52.1417 39.6464 53.1194C39.3778 55.1175 39.1362 56.9223 38.259 59.45C37.3314 62.1304 36.0753 64.8338 34.8076 67.2145C32.9874 70.635 31.1167 73.4545 30.222 74.5444Z" fill="var(--svgColor)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M62.0469 40.8641C61.7957 40.3037 62.0469 39.6486 62.6073 39.3994C63.1677 39.1501 63.8228 39.3994 64.0721 39.9598C65.7049 43.6197 66.0295 48.5358 66.3484 53.3071C66.6536 57.9352 66.9514 62.4242 68.4122 65.1065C68.704 65.6437 68.5048 66.3162 67.9677 66.6099C67.4305 66.9037 66.758 66.7026 66.4643 66.1654C64.7677 63.0485 64.4566 58.3237 64.1318 53.4481C63.8287 48.8701 63.5175 44.153 62.0469 40.8641Z" fill="var(--svgColor)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M44.4273 46.899C44.4602 46.2864 44.98 45.8188 45.5907 45.8458C46.2033 45.8788 46.6709 46.3985 46.6438 47.0092C46.352 52.648 45.1732 58.9749 42.8679 65.0504C40.8833 70.2872 38.0581 75.3366 34.2513 79.6169C33.8435 80.0768 33.1421 80.1174 32.684 79.7096C32.226 79.3018 32.1836 78.6004 32.5913 78.1424C36.2127 74.0746 38.8987 69.2649 40.7906 64.2715C43.0127 58.4182 44.1491 52.3252 44.4273 46.899Z" fill="var(--svgColor)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M46.613 84.622C46.2691 85.1322 45.5791 85.2674 45.069 84.9235C44.5589 84.5796 44.4236 83.8897 44.7675 83.3795C46.8449 80.305 48.4545 77.2074 49.8131 74.1078C51.1851 70.9812 52.3079 67.8468 53.4035 64.7395V64.7356C53.6084 64.1558 54.2421 63.8506 54.8219 64.0535C55.2257 64.1927 55.4943 64.5443 55.5542 64.9385L55.7204 65.7308C56.6113 69.9377 57.4229 73.7774 59.8578 78.1234C60.1593 78.6567 59.9698 79.3331 59.4307 79.6346C58.8973 79.9361 58.221 79.7466 57.9195 79.2075C55.9117 75.6228 54.9512 72.417 54.186 69.1241C53.4671 71.072 52.7057 73.0296 51.8438 74.991C50.4333 78.2218 48.7637 81.4412 46.613 84.622Z" fill="var(--svgColor)" />
      <path d="M86.8095 73.6417C90.5824 73.6417 94.2202 75.2285 96.917 78.085C99.2709 80.5784 100.736 83.8603 101.073 87.365H72.5457C72.8825 83.8603 74.348 80.5784 76.702 78.085C79.3988 75.2285 83.0366 73.6417 86.8095 73.6417Z" stroke="var(--svgColor)" strokeWidth="3.11349" />
      <path d="M96.1234 60.2733C96.1234 65.203 91.9964 69.2759 86.8097 69.2759C81.6229 69.2759 77.496 65.203 77.496 60.2733C77.496 55.3435 81.6229 51.2706 86.8097 51.2706C91.9964 51.2706 96.1234 55.3435 96.1234 60.2733Z" stroke="var(--svgColor)" strokeWidth="3.11349" />
    </g>
    <defs>
      <clipPath id="clip0_7387_106">
        <rect x="111.779" y="0.228516" width="95" height="111" rx="2" transform="rotate(90 111.779 0.228516)" fill="var(--svgColor)" />
      </clipPath>
    </defs>
  </svg>
);

export default AssistanceCollaboratorIcon;