import React from "react";

const ConceptsIcon = (props) => (
  <svg {...props} className={"icon"} width="74" height="93" viewBox="0 0 74 93" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M70.0076 87.051C70.0078 88.1557 69.1123 89.0514 68.0076 89.0514H5.98828C4.88371 89.0514 3.98828 88.156 3.98828 87.0514V5.01496C3.98828 3.91039 4.88371 3.01495 5.98828 3.01495H46.9918C47.5128 3.01495 48.0132 3.21825 48.3866 3.58158L69.3899 24.0196C69.7767 24.396 69.995 24.9128 69.9951 25.4526L70.0076 87.051Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round"/>
    <path d="M47.8047 3.01495V22.9403C47.8047 23.4926 48.2524 23.9403 48.8047 23.9403H69.5446" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round"/>
    <path d="M14.4996 48.8709C15.7592 48.8709 16.7804 47.8672 16.7804 46.629C16.7804 45.3909 15.7592 44.3871 14.4996 44.3871C13.2399 44.3871 12.2188 45.3909 12.2188 46.629C12.2188 47.8672 13.2399 48.8709 14.4996 48.8709Z" fill="var(--svgColor)"/>
    <path d="M14.4996 59.2581C15.7592 59.2581 16.7804 58.2543 16.7804 57.0162C16.7804 55.778 15.7592 54.7743 14.4996 54.7743C13.2399 54.7743 12.2188 55.778 12.2188 57.0162C12.2188 58.2543 13.2399 59.2581 14.4996 59.2581Z" fill="var(--svgColor)"/>
    <path d="M14.4996 69.6453C15.7592 69.6453 16.7804 68.6415 16.7804 67.4034C16.7804 66.1652 15.7592 65.1615 14.4996 65.1615C13.2399 65.1615 12.2188 66.1652 12.2188 67.4034C12.2188 68.6415 13.2399 69.6453 14.4996 69.6453Z" fill="var(--svgColor)"/>
    <path d="M22.3438 46.629H54.2687" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22.3438 57.0162H54.2687" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22.3438 67.4034H54.2687" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default ConceptsIcon;
