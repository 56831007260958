import React from "react";

const FamilyIcon = (props) => (
    <svg {...props} className={"icon"} width="130" height="93" viewBox="0 0 130 93" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M33 41.546C38.955 41.546 44.6939 44.1139 48.9457 48.7312C52.8769 53.0004 55.233 58.6957 55.5694 64.7299H10.4306C10.767 58.6957 13.1231 53.0004 17.0543 48.7312C21.3061 44.1139 27.0449 41.546 33 41.546Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" />
        <path d="M47.9894 21.6765C47.9894 29.9116 41.2842 36.598 33 36.598C24.7158 36.598 18.0107 29.9116 18.0107 21.6765C18.0107 13.4413 24.7158 6.75488 33 6.75488C41.2842 6.75488 47.9894 13.4413 47.9894 21.6765Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" />
        <path d="M94.5 41.546C100.343 41.546 105.981 44.1068 110.163 48.7222C114.03 52.9908 116.35 58.6891 116.681 64.7299H72.3188C72.6498 58.6891 74.9702 52.9908 78.8375 48.7222C83.019 44.1068 88.6568 41.546 94.5 41.546Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="3" />
        <path d="M109.223 21.6765C109.223 29.9346 102.614 36.598 94.5 36.598C86.3856 36.598 79.7766 29.9346 79.7766 21.6765C79.7766 13.4183 86.3856 6.75488 94.5 6.75488C102.614 6.75488 109.223 13.4183 109.223 21.6765Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="3" />
        <path d="M63 57.5C67.6413 57.5 72.0925 59.3437 75.3744 62.6256C78.3008 65.5521 80.0838 69.4083 80.4356 73.5H45.5644C45.9162 69.4083 47.6992 65.5521 50.6256 62.6256C53.9075 59.3437 58.3587 57.5 63 57.5Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" />
        <path d="M74.5 41.5C74.5 47.5202 69.4073 52.5 63 52.5C56.5927 52.5 51.5 47.5202 51.5 41.5C51.5 35.4798 56.5927 30.5 63 30.5C69.4073 30.5 74.5 35.4798 74.5 41.5Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" />
    </svg>

);

export default FamilyIcon;
