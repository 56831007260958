import React from "react";

const ConsentPublicationIcon = (props) => (
    <svg {...props} className={"icon"} width="82" height="101" viewBox="0 0 82 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4889_12570)">
            <path d="M43.7135 20.6216H71.5333" stroke="var(--svgColor)" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M43.7135 24.5913H71.5333" stroke="var(--svgColor)" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M43.7135 28.561H58.1109" stroke="var(--svgColor)" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M74.4531 9.6311H40.7937C39.6891 9.6311 38.7937 10.5265 38.7937 11.6311V54.3118C38.7937 55.4163 39.6891 56.3118 40.7937 56.3118H74.4531C75.5577 56.3118 76.4531 55.4163 76.4531 54.3118V11.6311C76.4531 10.5265 75.5577 9.6311 74.4531 9.6311Z" stroke="var(--svgColor)" strokeWidth="2" strokeMiterlimit="10" />
            <ellipse cx="64.6069" cy="43.3647" rx="9.04729" ry="9.04729" fill="#F5A506" />
            <path d="M60.3262 43.485L62.4021 45.9755L68.8886 40.7534" stroke="var(--svgColor)" strokeWidth="2.58381" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M29.3423 73.2485C36.1816 73.2485 42.7407 75.9654 47.5769 80.8015C52.0582 85.2829 54.7199 91.2438 55.0862 97.5361H3.59831C3.96469 91.2438 6.62634 85.2829 11.1077 80.8015C15.9438 75.9654 22.503 73.2485 29.3423 73.2485Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" />
            <path d="M46.513 50.9238C46.513 59.9553 38.8815 67.3762 29.3426 67.3762C19.8036 67.3762 12.1721 59.9553 12.1721 50.9238C12.1721 41.8923 19.8036 34.4714 29.3426 34.4714C38.8815 34.4714 46.513 41.8923 46.513 50.9238Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" />
        </g>
        <defs>
            <clipPath id="clip0_4889_12570">
                <rect width="81.0606" height="100" fill="white" transform="translate(0.469727 0.472656)" />
            </clipPath>
        </defs>
    </svg>
);

export default ConsentPublicationIcon;
