import React from "react";

const DocumentPublicationsIcon = (props) => (
  <svg {...props} className={"icon"} width="86" height="87" viewBox="0 0 86 87" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M58.0159 72.0473C58.0162 73.1521 57.1207 74.0477 56.0159 74.0477H4.91156C3.80699 74.0477 2.91156 73.1523 2.91156 72.0477V4.10303C2.91156 2.99846 3.80699 2.10303 4.91156 2.10303H56.0151C57.1202 2.10303 58.0158 2.99919 58.0151 4.10424L58.0054 20.1597L58.0159 72.0473Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
    <path d="M11.685 40.4483C12.7365 40.4483 13.5888 39.609 13.5888 38.5736C13.5888 37.5382 12.7365 36.6989 11.685 36.6989C10.6336 36.6989 9.78131 37.5382 9.78131 38.5736C9.78131 39.609 10.6336 40.4483 11.685 40.4483Z" fill="var(--svgColor)" />
    <path d="M11.685 49.1343C12.7365 49.1343 13.5888 48.2949 13.5888 47.2596C13.5888 46.2242 12.7365 45.3849 11.685 45.3849C10.6336 45.3849 9.78131 46.2242 9.78131 47.2596C9.78131 48.2949 10.6336 49.1343 11.685 49.1343Z" fill="var(--svgColor)" />
    <path d="M11.685 57.8201C12.7365 57.8201 13.5888 56.9807 13.5888 55.9454C13.5888 54.91 12.7365 54.0707 11.685 54.0707C10.6336 54.0707 9.78131 54.91 9.78131 55.9454C9.78131 56.9807 10.6336 57.8201 11.685 57.8201Z" fill="var(--svgColor)" />
    <path d="M18.2324 38.5736H44.8792" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.2324 47.2596H44.8792" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.2324 55.9455H44.8792" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M83.0445 82.1007C83.0448 83.2054 82.1493 84.1011 81.0445 84.1011H29.1772C28.0726 84.1011 27.1772 83.2057 27.1772 82.1011V13.7806C27.1772 12.6761 28.0726 11.7806 29.1772 11.7806H63.4469C63.9656 11.7806 64.464 11.9821 64.8369 12.3426L82.424 29.3422C82.8138 29.7189 83.0339 30.2377 83.034 30.7798L83.0445 82.1007Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
    <path d="M35.1267 41.0833H73.8873" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M35.1267 50.7499H61.7746" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M35.1267 57.9999H61.7746" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M35.1267 65.2499H61.7746" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M64.2559 11.7806V28.3701C64.2559 28.9223 64.7036 29.3701 65.2559 29.3701H82.6528" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
  </svg>
);

export default DocumentPublicationsIcon;
