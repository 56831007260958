import React from "react";

const AsssistanceAppIcon = (props) => (
  <svg {...props} className={"icon"} width="112" height="96" viewBox="0 0 112 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30.8387 37.7742C29.8232 37.7742 29 36.951 29 35.9355V8.35484C29 4.29938 32.2994 1 36.3548 1H78.6452C82.7006 1 86 4.29938 86 8.35484V17.5484C86 18.5639 85.1766 19.3871 84.1613 19.3871C83.146 19.3871 82.3226 18.5639 82.3226 17.5484V8.35484C82.3226 6.32711 80.6729 4.67742 78.6452 4.67742H36.3548C34.3271 4.67742 32.6774 6.32711 32.6774 8.35484V35.9355C32.6774 36.951 31.8542 37.7742 30.8387 37.7742Z" fill="var(--svgColor)" />
    <path d="M78.6452 94.7742H36.3548C32.2994 94.7742 29 91.4748 29 87.4193V33.9129C29 32.8974 29.8232 32.0742 30.8387 32.0742C31.8542 32.0742 32.6774 32.8974 32.6774 33.9129V87.4193C32.6774 89.4471 34.3271 91.0968 36.3548 91.0968H78.6452C80.6729 91.0968 82.3226 89.4471 82.3226 87.4193V15.158C82.3226 14.1425 83.146 13.3193 84.1613 13.3193C85.1766 13.3193 86 14.1425 86 15.158V87.4193C86 91.4748 82.7006 94.7742 78.6452 94.7742Z" fill="var(--svgColor)" />
    <path d="M65.7742 12.0323H49.2258C48.5293 12.0323 47.8926 11.6388 47.5813 11.0158L43.9039 3.66098C43.2938 2.441 44.1817 1 45.5484 1H69.4517C70.8158 1 71.7074 2.43861 71.0962 3.66098L67.4188 11.0158C67.1073 11.6388 66.4707 12.0323 65.7742 12.0323ZM50.3622 8.35484H64.6377L66.4764 4.67742H48.5235L50.3622 8.35484Z" fill="var(--svgColor)" />
    <path d="M84.1613 80.0651H30.8387C29.8232 80.0651 29 79.2419 29 78.2264C29 77.2109 29.8232 76.3877 30.8387 76.3877H84.1613C85.1766 76.3877 86 77.2109 86 78.2264C86 79.2419 85.1766 80.0651 84.1613 80.0651Z" fill="var(--svgColor)" />
    <path d="M60.258 87.4191H54.7419C53.7264 87.4191 52.9032 86.5959 52.9032 85.5804C52.9032 84.5649 53.7264 83.7417 54.7419 83.7417H60.258C61.2734 83.7417 62.0967 84.5649 62.0967 85.5804C62.0967 86.5959 61.2734 87.4191 60.258 87.4191Z" fill="var(--svgColor)" />
    <rect x="60" y="24.855" width="41" height="41" rx="20.5" fill="#FF592C" />
    <path fillRule="evenodd" clipRule="evenodd" d="M80.5 56.7441C86.7899 56.7441 91.8889 51.6451 91.8889 45.3552C91.8889 39.0653 86.7899 33.9663 80.5 33.9663C74.2101 33.9663 69.1111 39.0653 69.1111 45.3552C69.1111 51.6451 74.2101 56.7441 80.5 56.7441ZM79.3612 39.6607H81.6389V44.2163H86.1945V46.494H79.3612V39.6607Z" fill="var(--svgColor)" />
  </svg>
);

export default AsssistanceAppIcon;