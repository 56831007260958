import React from "react";
import Icon from "./icon";

function CalculationsManagementIcon(props) {
	return (
		<Icon viewBox="2 2 28 28" {...props}>
			<path
				d="m16 2a14.01541 14.01541 0 0 0 -14 14c.76852 18.573 27.23431 18.56759 28-.00011a14.01544 14.01544 0 0 0 
				-14-13.99989zm6.34 10.49a1.00019 1.00019 0 0 1 -2-.00006v-2.48994h-7.69l3.7 4.98a.992.992 0 0 1 .03 
				1.15l-3.87 5.87h7.83v-2.48a1.0001 1.0001 0 0 1 2 0v3.48a.99655.99655 0 0 1 -1 1h-10.68a1.00986 1.00986
			 	0 0 1 -.84-1.55l4.51-6.84-4.48-6.01a1.00974 1.00974 0 0 1 .81-1.6h10.68a.99655.99655 0 0 1 1 1z"
				fill="var(--icons)"
			/>
		</Icon>
	);
}
export default CalculationsManagementIcon;
