import React from "react";

const AssetsAndDiscountsEntryInInstallmentsIcon = (props) => (
  <svg {...props} className={"icon"} width="112" height="96" viewBox="0 0 112 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4106_12260)">
      <path d="M108.576 90.6218L108.576 13.6211C108.576 12.5165 107.681 11.6211 106.576 11.6211L6.19801 11.6211C5.09344 11.6211 4.19801 12.5165 4.19801 13.6211L4.19801 90.6218C4.19801 91.7263 5.09343 92.6218 6.19801 92.6218L106.576 92.6218C107.681 92.6218 108.576 91.7264 108.576 90.6218Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M90.2853 3.21436L90.2853 11.6237" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M67.5432 3.21436L67.5432 11.6237" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M44.8013 3.21436L44.8013 11.6237" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M22.0593 3.21436L22.0593 11.6237" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <rect x="59.5762" y="68.5" width="14" height="14" rx="1" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="19.5762" y="68.5" width="14" height="14" rx="1" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="79.5762" y="68.5" width="14" height="14" rx="1" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="39.5762" y="68.5" width="14" height="14" rx="1" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="59.5762" y="28.5" width="14" height="14" rx="1" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="39.5762" y="28.5" width="14" height="14" rx="1" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="19.5762" y="28.5" width="14" height="14" rx="1" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2" />
      <path d="M61.4094 55.6726L64.3837 59.2408L73.6771 51.759" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <rect x="39.5762" y="48.5" width="14" height="14" rx="1" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="19.5762" y="48.5" width="14" height="14" rx="1" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="79.5762" y="28.5" width="14" height="14" rx="1" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="79.5762" y="48.5" width="14" height="14" rx="1" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="2" />
    </g>
    <defs>
      <clipPath id="clip0_4106_12260">
        <rect x="111.576" y="0.5" width="95" height="111" rx="2" transform="rotate(90 111.576 0.5)" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AssetsAndDiscountsEntryInInstallmentsIcon;
