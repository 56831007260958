import React from "react";

const BenefitsCenterIcon = (props) => (
    <svg {...props} className={"icon"} width="121" height="101" viewBox="0 0 121 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.37415 96.3501H114.778" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M37.0235 53.2693H24.1814C23.0768 53.2693 22.1814 54.1647 22.1814 55.2693V94.2894C22.1814 95.394 23.0768 96.2894 24.1814 96.2894H37.0235C38.1281 96.2894 39.0235 95.394 39.0235 94.2894V55.2693C39.0235 54.1647 38.1281 53.2693 37.0235 53.2693Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M66.4973 53.2693H53.6552C52.5506 53.2693 51.6552 54.1647 51.6552 55.2693V94.2894C51.6552 95.394 52.5506 96.2894 53.6552 96.2894H66.4973C67.6018 96.2894 68.4973 95.394 68.4973 94.2894V55.2693C68.4973 54.1647 67.6018 53.2693 66.4973 53.2693Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M94.9183 53.2693H82.0762C80.9716 53.2693 80.0762 54.1647 80.0762 55.2693V94.2894C80.0762 95.394 80.9716 96.2894 82.0762 96.2894H94.9183C96.0228 96.2894 96.9183 95.394 96.9183 94.2894V55.2693C96.9183 54.1647 96.0228 53.2693 94.9183 53.2693Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M59.2632 18.7683C59.4353 18.6478 59.6644 18.6478 59.8365 18.7683L98.6149 45.9061C99.0155 46.1864 98.8172 46.8158 98.3282 46.8158H20.7714C20.2826 46.8158 20.0842 46.1864 20.4848 45.9061L59.2632 18.7683Z" stroke="var(--svgColor)" strokeWidth="3" />
        <rect x="41.1288" y="1.5" width="37.8947" height="37.8947" rx="18.9474" fill="var(--svgColor)" />
        <path d="M58.6476 31.8422V29.1796C57.0565 29.1365 55.4946 28.7235 54.0762 27.9709L54.8829 24.6952C56.2577 25.4703 57.7907 25.8908 59.3535 25.9214C60.8997 25.9214 61.9417 25.2908 61.9417 24.1697C61.9417 23.0486 61.0846 22.4179 59.0846 21.7348C56.2106 20.7188 54.2442 19.3174 54.2442 16.6023C54.2994 15.338 54.7905 14.1369 55.6272 13.2197C56.464 12.3025 57.5901 11.7309 58.7989 11.6099V8.94727H61.4375V11.4172C62.7857 11.4352 64.1148 11.7516 65.3367 12.3456L64.53 15.5512C63.3353 14.9065 62.0097 14.57 60.6644 14.5703C58.9837 14.5703 58.3619 15.3761 58.3619 16.1643C58.3619 16.9526 59.3031 17.6883 61.5888 18.5642C64.7989 19.7378 66.0762 21.2793 66.0762 23.8193C66.078 25.1659 65.5839 26.4618 64.6957 27.4396C63.8076 28.4175 62.5931 29.0027 61.3031 29.0745V31.9473L58.6476 31.8422Z" fill="#F5A506" />
    </svg>
);

export default BenefitsCenterIcon;
