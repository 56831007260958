import React from "react";

const AdvancesIcon = (props) => (
    <svg {...props} className={"icon"} width="114" height="115" viewBox="0 0 114 115" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M57.1328 3.50001L57.1337 3.50001C65.3134 3.49574 73.385 5.397 80.7234 9.05731C88.062 12.7177 94.4712 18.0392 99.4537 24.6112C104.436 31.1834 107.858 38.8294 109.453 46.9572C111.047 55.085 110.772 63.4752 108.648 71.4779C106.524 79.4804 102.609 86.8795 97.207 93.1022C91.8051 99.3249 85.0617 104.204 77.4998 107.363C69.9379 110.523 61.7597 111.878 53.5984 111.325C45.437 110.771 37.51 108.324 30.4314 104.171L29.4088 103.572L28.5889 104.428C23.1212 110.139 16.2089 111.269 10.5169 110.923C8.00837 110.77 5.7672 110.331 4.04525 109.887C5.71931 108.984 7.76195 107.733 9.68022 106.152C13.5519 102.962 17.3461 98.0513 15.7513 91.7221L15.673 91.4111L15.4743 91.1594C9.20804 83.2181 5.28314 73.6457 4.15355 63.5436C3.02395 53.4414 4.73584 43.2223 9.09111 34.0628C13.4463 24.9034 20.2671 17.1772 28.7662 11.7713C37.2651 6.36554 47.0977 3.49915 57.1328 3.50001Z" stroke="var(--svgColor)" strokeWidth="3" />
        <path d="M53.5714 86.7167V79.5393C49.1958 79.423 44.9008 78.3099 41 76.2811L43.2185 67.4509C46.9992 69.5405 51.2149 70.674 55.5126 70.7564C59.7647 70.7564 62.6303 69.0565 62.6303 66.0344C62.6303 63.0123 60.2732 61.3123 54.7732 59.4707C46.8698 56.7319 41.4622 52.9543 41.4622 45.6352C41.6139 42.2271 42.9643 38.9893 45.2653 36.517C47.5664 34.0446 50.6634 32.5037 53.9874 32.1774V25H61.2437V31.6581C64.9511 31.7065 68.6061 32.5596 71.9664 34.1607L69.7479 42.802C66.4627 41.0639 62.8171 40.1571 59.1176 40.1576C54.4958 40.1576 52.7857 42.3298 52.7857 44.4547C52.7857 46.5796 55.374 48.5628 61.6597 50.9238C70.4874 54.0876 74 58.243 74 65.0899C74.005 68.7197 72.6462 72.213 70.2038 74.849C67.7613 77.4849 64.4217 79.0624 60.874 79.256V87L53.5714 86.7167Z" fill="#F5A506" />
    </svg >
);

export default AdvancesIcon;
