import React from "react";

const FolderIcon = (props) => (
    <svg {...props} className={"icon"} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22 13.126C20.8858 12.3251 19.5306 11.9303 18.1606 12.0074C16.7905 12.0846 15.4883 12.6291 14.471 13.5501C13.4537
            14.4711 12.7829 15.7129 12.5704 17.0686C12.3578 18.4243 12.6164 19.8119 13.303 21H3C2.73478 21 2.48043 20.8946 2.29289
            20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414
            5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V13.126ZM20 17H23V19H20V22.5L15 18L20 13.5V17Z"
            fill="var(--icons)"
        />
    </svg>
);

export default FolderIcon;
