import React from "react";

const DigitalWarehouseFileTypeIcon = (props) => (
  <svg {...props} className={"icon"} width="112" height="95" viewBox="0 0 112 95" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4182_12085)">
      <path d="M98.5 90.1218L98.5 16C98.5 14.8954 97.6046 14 96.5 14L58.7657 14C58.2957 14 57.8406 13.8344 57.4805 13.5324L49.0575 6.46764C48.6973 6.16557 48.2423 6 47.7722 6L9.99987 6C8.8953 6 7.99987 6.89543 7.99987 8L7.99987 90.1218C7.99987 91.2263 8.89529 92.1218 9.99987 92.1218L96.5 92.1218C97.6046 92.1218 98.5 91.2263 98.5 90.1218Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M108 90L108 25C108 23.8954 107.105 23 106 23L19 23C17.8954 23 17 23.8954 17 25L17 90C17 91.1046 17.8954 92 19 92L106 92C107.105 92 108 91.1046 108 90Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
      <path d="M92 63H70" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M92 70H70" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M92 77H70" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_4182_12085">
        <rect x="111.076" width="95" height="111" rx="2" transform="rotate(90 111.076 0)" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DigitalWarehouseFileTypeIcon;
