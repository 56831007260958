import React from "react";

const AssetsAndDiscountsCreationIcon = (props) => (
  <svg {...props} className={"icon"} width="172" height="99" viewBox="0 0 172 99" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M167.282 5.53101H93.0254C92.4731 5.53101 92.0254 5.97872 92.0254 6.53101V44.4475C92.0254 44.9998 92.4731 45.4475 93.0254 45.4475H167.282C167.835 45.4475 168.282 44.9998 168.282 44.4475V6.53101C168.282 5.97872 167.835 5.53101 167.282 5.53101Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M80.4238 5.53101H6.16693C5.61465 5.53101 5.16693 5.97872 5.16693 6.53101V44.4475C5.16693 44.9998 5.61465 45.4475 6.16693 45.4475H80.4238C80.9761 45.4475 81.4238 44.9998 81.4238 44.4475V6.53101C81.4238 5.97872 80.9761 5.53101 80.4238 5.53101Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M167.282 55.1182H93.0254C92.4731 55.1182 92.0254 55.5659 92.0254 56.1182V94.0347C92.0254 94.5869 92.4731 95.0347 93.0254 95.0347H167.282C167.835 95.0347 168.282 94.5869 168.282 94.0347V56.1182C168.282 55.5659 167.835 55.1182 167.282 55.1182Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M80.8135 55.1182H6.55664C6.00436 55.1182 5.55664 55.5659 5.55664 56.1182V94.0347C5.55664 94.5869 6.00436 95.0347 6.55664 95.0347H80.8135C81.3658 95.0347 81.8135 94.5869 81.8135 94.0347V56.1182C81.8135 55.5659 81.3658 55.1182 80.8135 55.1182Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M23.8574 65.5173H74.7861" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M23.8574 75.866H74.7861" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M23.8574 86.345H74.7861" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M17.5311 63.0754H14.5957C14.0434 63.0754 13.5957 63.5232 13.5957 64.0754V66.9592C13.5957 67.5115 14.0434 67.9592 14.5957 67.9592H17.5311C18.0834 67.9592 18.5311 67.5115 18.5311 66.9592V64.0754C18.5311 63.5232 18.0834 63.0754 17.5311 63.0754Z" fill="var(--svgColor)" />
    <path d="M17.5311 73.4241H14.5957C14.0434 73.4241 13.5957 73.8718 13.5957 74.4241V77.3079C13.5957 77.8601 14.0434 78.3079 14.5957 78.3079H17.5311C18.0834 78.3079 18.5311 77.8601 18.5311 77.3079V74.4241C18.5311 73.8718 18.0834 73.4241 17.5311 73.4241Z" fill="var(--svgColor)" />
    <path d="M17.5311 83.9031H14.5957C14.0434 83.9031 13.5957 84.3508 13.5957 84.9031V87.7869C13.5957 88.3391 14.0434 88.7869 14.5957 88.7869H17.5311C18.0834 88.7869 18.5311 88.3391 18.5311 87.7869V84.9031C18.5311 84.3508 18.0834 83.9031 17.5311 83.9031Z" fill="var(--svgColor)" />
    <path d="M109.822 15.1807H160.755" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M22.9638 15.1807H73.8968" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M109.822 25.5293H160.755" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M22.9638 25.5293H73.8968" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M109.822 36.0083H160.755" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M22.9638 36.0083H73.8968" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M103.496 12.7388H100.561C100.008 12.7388 99.5605 13.1865 99.5605 13.7388V16.6226C99.5605 17.1748 100.008 17.6226 100.561 17.6226H103.496C104.048 17.6226 104.496 17.1748 104.496 16.6225V13.7388C104.496 13.1865 104.048 12.7388 103.496 12.7388Z" fill="var(--svgColor)" />
    <path d="M16.6375 12.7388H13.7021C13.1498 12.7388 12.7021 13.1865 12.7021 13.7388V16.6226C12.7021 17.1748 13.1498 17.6226 13.7021 17.6226H16.6375C17.1898 17.6226 17.6375 17.1748 17.6375 16.6225V13.7388C17.6375 13.1865 17.1898 12.7388 16.6375 12.7388Z" fill="var(--svgColor)" />
    <path d="M103.496 23.0874H100.561C100.008 23.0874 99.5605 23.5351 99.5605 24.0874V26.9712C99.5605 27.5235 100.008 27.9712 100.561 27.9712H103.496C104.048 27.9712 104.496 27.5235 104.496 26.9712V24.0874C104.496 23.5351 104.048 23.0874 103.496 23.0874Z" fill="var(--svgColor)" />
    <path d="M16.6375 23.0874H13.7021C13.1498 23.0874 12.7021 23.5351 12.7021 24.0874V26.9712C12.7021 27.5235 13.1498 27.9712 13.7021 27.9712H16.6375C17.1898 27.9712 17.6375 27.5235 17.6375 26.9712V24.0874C17.6375 23.5351 17.1898 23.0874 16.6375 23.0874Z" fill="var(--svgColor)" />
    <path d="M103.496 33.5664H100.561C100.008 33.5664 99.5605 34.0141 99.5605 34.5664V37.4502C99.5605 38.0025 100.008 38.4502 100.561 38.4502H103.496C104.048 38.4502 104.496 38.0025 104.496 37.4502V34.5664C104.496 34.0141 104.048 33.5664 103.496 33.5664Z" fill="var(--svgColor)" />
    <path d="M16.6375 33.5664H13.7021C13.1498 33.5664 12.7021 34.0141 12.7021 34.5664V37.4502C12.7021 38.0025 13.1498 38.4502 13.7021 38.4502H16.6375C17.1898 38.4502 17.6375 38.0025 17.6375 37.4502V34.5664C17.6375 34.0141 17.1898 33.5664 16.6375 33.5664Z" fill="var(--svgColor)" />
    <path d="M109.822 65.4541H160.755" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M109.822 75.8027H160.755" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M109.822 86.2817H160.755" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M103.496 63.0081H100.561C100.008 63.0081 99.5605 63.4558 99.5605 64.0081V66.8918C99.5605 67.4441 100.008 67.8918 100.561 67.8918H103.496C104.048 67.8918 104.496 67.4441 104.496 66.8918V64.0081C104.496 63.4558 104.048 63.0081 103.496 63.0081Z" fill="var(--svgColor)" />
    <path d="M103.496 73.3608H100.561C100.008 73.3608 99.5605 73.8086 99.5605 74.3608V77.2446C99.5605 77.7969 100.008 78.2446 100.561 78.2446H103.496C104.048 78.2446 104.496 77.7969 104.496 77.2446V74.3608C104.496 73.8086 104.048 73.3608 103.496 73.3608Z" fill="var(--svgColor)" />
    <path d="M103.496 83.8357H100.561C100.008 83.8357 99.5605 84.2834 99.5605 84.8357V87.7195C99.5605 88.2718 100.008 88.7195 100.561 88.7195H103.496C104.048 88.7195 104.496 88.2718 104.496 87.7195V84.8357C104.496 84.2834 104.048 83.8357 103.496 83.8357Z" fill="var(--svgColor)" />
  </svg>
);

export default AssetsAndDiscountsCreationIcon;
