import React from "react";

const ExamsIcon = (props) => (
  <svg {...props} className={"icon"}  width="96" height="111" viewBox="0 0 96 111" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M90.6218 2.85962H13.6211C12.5165 2.85962 11.6211 3.75505 11.6211 4.85962V105.238C11.6211 106.342 12.5165 107.238 13.6211 107.238H90.6218C91.7263 107.238 92.6218 106.342 92.6218 105.238V4.85962C92.6218 3.75505 91.7264 2.85962 90.6218 2.85962Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M72 52.5213C72 51.4167 71.1046 50.5213 70 50.5213H59.1064C58.0018 50.5213 57.1064 49.6258 57.1064 48.5213V38C57.1064 36.8954 56.211 36 55.1064 36H48.8936C47.789 36 46.8936 36.8954 46.8936 38V48.5213C46.8936 49.6258 45.9982 50.5213 44.8936 50.5213H34C32.8954 50.5213 32 51.4167 32 52.5213V58.4787C32 59.5833 32.8954 60.4787 34 60.4787H44.8936C45.9982 60.4787 46.8936 61.3742 46.8936 62.4787V73C46.8936 74.1046 47.789 75 48.8936 75H55.1064C56.211 75 57.1064 74.1046 57.1064 73V62.4787C57.1064 61.3742 58.0018 60.4787 59.1064 60.4787H70C71.1046 60.4787 72 59.5833 72 58.4787V52.5213Z" fill="#FF4040"/>
    <path d="M3.21484 21.2909H11.6242" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M3.21484 44.033H11.6242" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M3.21484 66.7749H11.6242" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M3.21484 89.5168H11.6242" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
  </svg>
);

export default ExamsIcon;
