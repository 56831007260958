import React from "react";

const AlertsTemplateIcon = (props) => (
  <svg {...props} className={"icon"} width="136" height="108" viewBox="0 0 136 108" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4763_12378)">
      <path d="M68.1246 3.37818L68.1256 3.37818C75.7885 3.37418 83.3503 5.15634 90.2253 8.58746C97.1005 12.0186 103.105 17.007 107.773 23.1679C112.441 29.3288 115.647 36.4967 117.141 44.1163C118.636 51.7359 118.378 59.6015 116.388 67.1037C114.398 74.6059 110.729 81.5421 105.668 87.3755C100.607 93.2088 94.2896 97.7825 87.2052 100.744C80.1209 103.706 72.4593 104.976 64.8135 104.457C57.1677 103.939 49.7414 101.645 43.1098 97.7519L42.0867 97.1514L41.2667 98.0085C36.16 103.346 29.7033 104.404 24.3767 104.08C22.1374 103.943 20.1254 103.563 18.5456 103.167C20.0811 102.327 21.9163 101.186 23.6447 99.7608C27.2872 96.7582 30.8835 92.1131 29.3735 86.1168L29.2952 85.8059L29.0967 85.5542C23.2258 78.1098 19.5484 69.1361 18.4901 59.6656C17.4317 50.195 19.0356 40.6149 23.1162 32.0282C27.1967 23.4417 33.5871 16.199 41.5496 11.1315C49.5119 6.06421 58.7235 3.37738 68.1246 3.37818Z" stroke="var(--svgColor)" strokeWidth="3" />
      <path d="M50.9866 28.719C50.2526 28.6861 49.5131 28.6861 48.7791 28.7409L48.7736 28.7354C48.0662 28.7793 47.375 28.9656 46.7413 29.2832C46.2648 29.5242 45.8265 29.8748 45.1309 30.5321C44.479 31.1511 44.101 31.6879 43.7012 32.2029C41.6763 34.8413 40.5891 38.0797 40.6117 41.4056C40.663 44.0864 41.2809 46.7261 42.4248 49.1512C44.6598 54.0922 48.3409 59.3235 53.2161 64.1713C54.3884 65.3381 55.5332 66.5104 56.7712 67.6005C62.8154 72.922 70.0179 76.7596 77.806 78.8081C77.806 78.8081 80.8736 79.2846 80.9174 79.2846C81.9363 79.3394 82.9497 79.2627 83.9631 79.2079C85.558 79.1273 87.1157 78.6972 88.5261 77.948C89.2427 77.5773 89.9427 77.1752 90.6241 76.7429C90.8557 76.5833 91.084 76.419 91.3088 76.2499C91.9586 75.7808 92.5637 75.2527 93.1165 74.6723C93.5858 74.1859 93.9742 73.6274 94.2668 73.018C94.6941 72.1251 95.1213 70.4215 95.2966 69.0028C95.4226 67.9182 95.3898 67.3266 95.3679 66.9596C95.3514 66.3734 94.8639 65.7654 94.3271 65.5079L91.139 64.0782C91.139 64.0782 86.3788 62.0021 83.4701 60.671C83.1633 60.5341 82.8292 60.4738 82.5005 60.4519C82.1257 60.4134 81.747 60.4556 81.3899 60.5756C81.0327 60.6956 80.7054 60.8906 80.4299 61.1476V61.1366C80.397 61.1366 80.0355 61.4489 76.0695 66.2529C75.8426 66.5573 75.5304 66.7875 75.1725 66.9143C74.8146 67.0411 74.4271 67.0587 74.0592 66.965C73.7031 66.8719 73.3525 66.7459 73.0129 66.6035C72.3337 66.3132 72.0981 66.2036 71.6325 66.0009L71.6051 65.99C68.4718 64.6205 65.5686 62.7745 63.005 60.5122C62.3148 59.9041 61.6738 59.2523 61.0165 58.6168C58.7158 56.3819 56.8315 54.01 55.4291 51.671L55.106 51.1506C54.8759 50.7781 54.6239 50.3344 54.5472 50.0276C54.3391 49.2224 54.8814 48.576 54.8814 48.576C54.8814 48.576 56.2125 47.1189 56.8315 46.3246C57.3467 45.6708 57.8276 44.9907 58.2721 44.2869C58.9185 43.2461 59.1212 42.1779 58.7816 41.3453C57.2478 37.604 55.6592 33.8736 54.0268 30.1706C53.7036 29.4366 52.745 28.9052 51.8741 28.8066L51.874 28.8066C51.5782 28.7737 51.2824 28.7409 50.9866 28.719Z" fill="#F5A506" />
    </g>
    <defs>
      <clipPath id="clip0_4763_12378">
        <rect width="107" height="108" fill="white" transform="translate(14.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default AlertsTemplateIcon;
