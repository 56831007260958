import React from "react";

const ConceptStructureIcon = (props) => (
    <svg {...props} className={"icon"} width="138" height="95" viewBox="0 0 138 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 91L133 91" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M62 22H47C45.8954 22 45 22.8954 45 24V89C45 90.1046 45.8954 91 47 91H62C63.1046 91 64 90.1046 64 89V24C64 22.8954 63.1046 22 62 22Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M36 22H20C18.8954 22 18 22.8954 18 24V89C18 90.1046 18.8954 91 20 91H36C37.1046 91 38 90.1046 38 89V24C38 22.8954 37.1046 22 36 22Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M90 22H75C73.8954 22 73 22.8954 73 24V89C73 90.1046 73.8954 91 75 91H90C91.1046 91 92 90.1046 92 89V24C92 22.8954 91.1046 22 90 22Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M117 22H101C99.8954 22 99 22.8954 99 24V89C99 90.1046 99.8954 91 101 91H117C118.105 91 119 90.1046 119 89V24C119 22.8954 118.105 22 117 22Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M22.5 81.5H33M22.5 75H33M22.5 67H27.5M22.5 59H27.5M22.5 52.5H27.75H33M22.5 46H27.75H33M22.5 40.5H27.5" stroke="var(--svgColor)" strokeWidth="2" strokeLinecap="round" />
        <path d="M104 81.5H109.25M104 75H114.5M104 67H109M104 59H114.5M104 52.5H109.25H114.5M104 46H109.25M104 40.5H109" stroke="var(--svgColor)" strokeWidth="2" strokeLinecap="round" />
        <path d="M49 81.5H59.5M49 75H55.5M49 67H54M49 59H59.5M49 52.5H54.25M49 46H54.25M49 40.5H54" stroke="var(--svgColor)" strokeWidth="2" strokeLinecap="round" />
        <path d="M77 81.5H83.5M77 75H87.5M77 67H82M77 59H87.5M77 52.5H82.25M77 46H87.5M77 40.5H87.5" stroke="var(--svgColor)" strokeWidth="2" strokeLinecap="round" />
    </svg>
);

export default ConceptStructureIcon;
