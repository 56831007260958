import React from "react";

const DiseasesIcon = (props) => (
  <svg {...props} className={"icon"} width="118" height="119" viewBox="0 0 118 119" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3310:10140)">
      <path d="M42.6444 61.3445C46.0142 61.3445 48.7459 58.6024 48.7459 55.2198C48.7459 51.8373 46.0142 49.0952 42.6444 49.0952C39.2747 49.0952 36.543 51.8373 36.543 55.2198C36.543 58.6024 39.2747 61.3445 42.6444 61.3445Z" fill="#FF4040"/>
      <path d="M45.6432 86.0985C50.1406 86.0985 53.7865 82.4388 53.7865 77.9243C53.7865 73.4098 50.1406 69.7501 45.6432 69.7501C41.1459 69.7501 37.5 73.4098 37.5 77.9243C37.5 82.4388 41.1459 86.0985 45.6432 86.0985Z" fill="#F5A506"/>
      <path d="M70.328 73.345C73.6978 73.345 76.4295 70.6029 76.4295 67.2203C76.4295 63.8378 73.6978 61.0956 70.328 61.0956C66.9583 61.0956 64.2266 63.8378 64.2266 67.2203C64.2266 70.6029 66.9583 73.345 70.328 73.345Z" fill="#FF592C"/>
      <path d="M58.7926 105.302C82.7672 105.302 102.202 85.7925 102.202 61.7268C102.202 37.661 82.7672 18.1518 58.7926 18.1518C34.8181 18.1518 15.3828 37.661 15.3828 61.7268C15.3828 85.7925 34.8181 105.302 58.7926 105.302Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M42.6494 21.2719L34.9805 6.6225" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M73.8633 20.8541L78.9906 6.6225" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M98.1992 43.4239L111.403 36.7303" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M100.066 75.2562L111.401 80.163" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M77.8047 100.91L82.3121 111.48" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M42.6469 102.19L38.8125 112.84" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M18.9058 78.9452L8.29688 83.7898" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M18.6603 45.1039L5.89062 40.9704" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M67.543 1.70673L92.5687 11.5382" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M26.3008 107.924L51.3265 117.755" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M2.91406 71.4338L13.6824 96.1458" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M106.02 24.3743L116.779 49.0952" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M22.6719 12.0271L47.2903 1.21783" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M69.9961 116.884L94.6233 106.075" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M1.23828 53.5309L11.0325 28.41" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M106.496 92.3412L116.299 67.2203" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    </g>
    <defs>
      <clipPath id="clip0_3310:10140">
        <rect width="118" height="119" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default DiseasesIcon;
