import React from "react";

const BronzeMedalIcon = (props) => (
  <svg {...props} className={"icon"} width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5678_21033)">
      <path d="M33.6703 25.1816L24.1819 29.3029L13.0716 59.7769C13.0619 59.8041 13.0599 59.8335 13.0659 59.8618C13.0718 59.8901 13.0854 59.9162 13.1053 59.9372C13.1251 59.9583 13.1503 59.9734 13.1782 59.981C13.206 59.9886 13.2355 59.9884 13.2632 59.9803L21.9207 57.429L26.8829 64.9567C26.8987 64.9809 26.921 65.0003 26.9472 65.0125C26.9733 65.0248 27.0024 65.0295 27.0311 65.0262C27.0598 65.0229 27.087 65.0116 27.1097 64.9936C27.1324 64.9757 27.1496 64.9517 27.1594 64.9245L38.2698 34.4596L33.6703 25.1816Z" fill="#D50000" />
      <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
        <path d="M33.6703 25.1816L24.1819 29.3029L13.0716 59.7769C13.0619 59.8041 13.0599 59.8335 13.0659 59.8618C13.0718 59.8901 13.0854 59.9162 13.1053 59.9372C13.1251 59.9583 13.1503 59.9734 13.1782 59.981C13.206 59.9886 13.2355 59.9884 13.2632 59.9803L21.9207 57.429L26.8829 64.9567C26.8987 64.9809 26.921 65.0003 26.9472 65.0125C26.9733 65.0248 27.0024 65.0295 27.0311 65.0262C27.0598 65.0229 27.087 65.0116 27.1097 64.9936C27.1324 64.9757 27.1496 64.9517 27.1594 64.9245L38.2698 34.4596L33.6703 25.1816Z" fill="#D50000" />
      </g>
      <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
        <path d="M36.7127 31.3203L32.2019 32.6121L30.3125 56.277L38.269 34.4589L36.7127 31.3203Z" fill="#AC0202" />
      </g>
      <path d="M53.5081 58.4194L39.9026 29.1355L30.0991 25.832L26.3086 35.4641L39.9026 64.7378C39.9207 64.7769 39.9493 64.8101 39.9853 64.8338C40.0212 64.8575 40.0631 64.8707 40.1061 64.8719C40.1491 64.8731 40.1916 64.8622 40.2288 64.8406C40.266 64.819 40.2965 64.7874 40.3167 64.7494L44.3889 57.1367L53.256 58.7466C53.298 58.7545 53.3414 58.7506 53.3813 58.7354C53.4212 58.7202 53.4561 58.6942 53.4822 58.6604C53.5083 58.6265 53.5246 58.5861 53.5291 58.5436C53.5337 58.501 53.5264 58.4581 53.5081 58.4194Z" fill="#D50000" />
      <g style={{ mixBlendMode: "multiply" }} opacity="0.3">
        <path d="M32.2001 47.5612C37.2835 47.569 42.2165 45.8343 46.1786 42.6454L39.8993 29.1354L34.7661 27.4058L33.6638 25.1816L31.2637 26.2261L30.0958 25.832L29.6675 26.9203L24.1754 29.3093L19.0781 43.2996C22.8908 46.0762 27.4857 47.5684 32.2001 47.5612Z" fill="#AC0202" />
      </g>
      <path d="M32.2021 46.3334C44.5459 46.3334 54.5526 36.3137 54.5526 23.9538C54.5526 11.5939 44.5459 1.57422 32.2021 1.57422C19.8582 1.57422 9.85156 11.5939 9.85156 23.9538C9.85156 36.3137 19.8582 46.3334 32.2021 46.3334Z" fill="#A86727" />
      <g style={{ mixBlendMode: "screen" }} opacity="0.5">
        <path d="M10.4712 24.571C10.4712 20.1819 11.7603 15.8896 14.1778 12.2283C16.5954 8.56707 20.0349 5.69845 24.0682 3.97943C28.1015 2.26041 32.5505 1.76689 36.8618 2.56027C41.1731 3.35366 45.1562 5.39892 48.3155 8.44156C46.2566 6.29252 43.79 4.57654 41.0603 3.39418C38.3306 2.21182 35.3927 1.58685 32.4188 1.5559C29.4448 1.52494 26.4946 2.08863 23.7409 3.21392C20.9873 4.33921 18.4857 6.00348 16.3826 8.1092C14.2795 10.2149 12.6173 12.7198 11.4934 15.4769C10.3694 18.2341 9.80634 21.1881 9.83714 24.166C9.86793 27.1438 10.492 30.0856 11.6727 32.8189C12.8534 35.5521 14.567 38.022 16.7132 40.0836C12.7021 35.919 10.4639 30.3568 10.4712 24.571Z" fill="#A86727" />
      </g>
      <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
        <path d="M50.0187 10.4375C53.2607 15.2734 54.4923 21.1843 53.4518 26.9148C52.4114 32.6453 49.1808 37.744 44.4463 41.1278C39.7118 44.5117 33.8464 45.9142 28.0958 45.0374C22.3452 44.1606 17.1625 41.0737 13.6484 36.4322C15.3145 38.9174 17.4616 41.0427 19.9625 42.6824C22.4634 44.322 25.2674 45.4427 28.2085 45.9781C31.1496 46.5135 34.1681 46.4527 37.0854 45.7994C40.0026 45.1461 42.7594 43.9135 45.1924 42.1745C47.6255 40.4355 49.6855 38.2255 51.2505 35.6752C52.8155 33.125 53.8537 30.2863 54.3037 27.3272C54.7537 24.3681 54.6063 21.3487 53.8703 18.4476C53.1343 15.5465 51.8246 12.8228 50.0187 10.4375Z" fill="#A86727" />
      </g>
      <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
        <path d="M45.9506 37.7189C53.5433 30.1163 53.5433 17.79 45.9506 10.1874C38.3579 2.58478 26.0476 2.58478 18.4549 10.1874C10.8622 17.79 10.8622 30.1163 18.4549 37.7189C26.0476 45.3215 38.3579 45.3215 45.9506 37.7189Z" fill="#A86727" />
      </g>
      <path d="M45.1274 36.8965C52.2665 29.7481 52.2665 18.1582 45.1274 11.0097C37.9882 3.86129 26.4134 3.86128 19.2742 11.0097C12.1351 18.1582 12.1351 29.7481 19.2742 36.8965C26.4134 44.045 37.9882 44.045 45.1274 36.8965Z" fill="#A86727" />
      <path d="M44.3143 36.0815C51.0039 29.3832 51.0039 18.5231 44.3143 11.8248C37.6247 5.12648 26.7787 5.12648 20.0891 11.8248C13.3996 18.5231 13.3996 29.3832 20.0891 36.0815C26.7787 42.7798 37.6247 42.7798 44.3143 36.0815Z" fill="#A86727" />
      <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M32.8281 37.7697C33.1334 38.1175 33.4558 38.4609 33.7954 38.8C33.9904 38.9833 34.225 39.119 34.4809 39.1967C34.6219 39.2435 34.7671 39.2767 34.9144 39.2959C35.0706 39.3134 35.2279 39.3199 35.3851 39.3152C35.5505 39.312 35.7153 39.2948 35.8777 39.2637C36.0441 39.2313 36.2082 39.1883 36.3691 39.1349C36.6953 39.025 37.0015 38.8627 37.2758 38.6545C36.8643 38.3879 36.4733 38.1278 36.0938 37.8367C35.8847 37.6827 35.6481 37.5704 35.3967 37.5057C35.1268 37.4311 34.8483 37.3925 34.5684 37.3911C34.2732 37.3865 33.9784 37.4159 33.6899 37.4787C33.3913 37.538 33.1016 37.6359 32.8281 37.7697Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M35.4018 35.7578C35.5175 35.32 35.6063 34.864 35.6809 34.43C35.1725 34.4221 34.672 34.5558 34.2352 34.8164C33.8109 35.057 33.477 35.4302 33.2847 35.8789C33.1136 36.2936 32.9258 36.7134 32.7188 37.1346C33.2948 37.2571 33.8955 37.1751 34.4178 36.9028C34.6577 36.7788 34.8731 36.6121 35.0532 36.4108C35.2178 36.2227 35.3371 35.9993 35.4018 35.7578Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M35.9844 37.0092C36.3222 37.2668 36.6751 37.5188 37.0429 37.7652C37.2498 37.8978 37.4852 37.979 37.7298 38.0022C38.0079 38.0371 38.29 38.0201 38.5619 37.9519C38.7089 37.9179 38.8537 37.8749 38.9954 37.8231C39.1393 37.7656 39.2786 37.6971 39.4121 37.6184C39.5464 37.5414 39.6753 37.4553 39.798 37.3608C39.9192 37.2613 40.0327 37.1528 40.1376 37.0362C39.9318 36.9525 39.7285 36.8662 39.5305 36.7787C39.3324 36.6911 39.1292 36.5906 38.9337 36.494C38.7189 36.3918 38.4867 36.3315 38.2494 36.3163C37.9915 36.2918 37.7313 36.3061 37.4777 36.3588C37.2113 36.4036 36.9519 36.4828 36.7059 36.5945C36.4474 36.6986 36.2046 36.8382 35.9844 37.0092Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M37.9495 34.7297C37.9637 34.5185 37.9752 34.3137 37.9804 34.1038C37.9855 33.8938 37.9804 33.6839 37.9804 33.4766C37.8615 33.497 37.7445 33.5272 37.6305 33.5667C37.5167 33.6008 37.4057 33.6439 37.2987 33.6955C37.1942 33.7474 37.0937 33.8068 36.9977 33.8732C36.9004 33.9346 36.8083 34.004 36.7225 34.0806C36.6371 34.157 36.558 34.2401 36.4858 34.3291C36.4113 34.4125 36.3442 34.5021 36.2852 34.597C36.168 34.7863 36.086 34.9953 36.0434 35.2139C35.9636 35.6196 35.8697 36.0356 35.7578 36.4529C36.0299 36.4512 36.3002 36.4078 36.5591 36.3241C36.8079 36.2293 37.0419 36.0992 37.2537 35.9377C37.4473 35.7804 37.613 35.5914 37.7437 35.3788C37.8609 35.181 37.9313 34.9589 37.9495 34.7297Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M38.8867 35.6548C39.2366 35.8265 39.5976 35.9892 39.9697 36.1429C40.1775 36.2254 40.4019 36.2572 40.6244 36.2356C40.878 36.2187 41.1264 36.1558 41.3575 36.0502C41.8733 35.824 42.309 35.4475 42.6077 34.9696C42.2219 34.8898 41.8193 34.7829 41.4399 34.6773C40.9963 34.5768 40.5322 34.6219 40.1163 34.8061C39.646 34.9911 39.2251 35.2829 38.8867 35.6587V35.6548Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M40.2606 33.2577C40.213 32.8713 40.1629 32.5017 40.087 32.123C39.6746 32.279 39.3135 32.5465 39.0439 32.8958C38.9171 33.0623 38.8133 33.2451 38.7352 33.4393C38.6627 33.6302 38.6248 33.8326 38.6233 34.0369C38.6233 34.4232 38.613 34.8096 38.5898 35.1959C38.8333 35.1435 39.0675 35.0544 39.2844 34.9319C39.4914 34.8035 39.6789 34.6461 39.8413 34.4644C40.1449 34.0974 40.3134 33.6685 40.2606 33.2577Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M41.5234 33.6864C41.8656 33.774 42.218 33.8564 42.5743 33.9259C42.7724 33.9593 42.9754 33.9478 43.1685 33.8924C43.3894 33.8342 43.5977 33.736 43.7833 33.6027C44.1976 33.3159 44.5177 32.9125 44.7029 32.4436C44.339 32.4333 43.9711 32.4075 43.6161 32.3766C43.2038 32.3597 42.7973 32.4782 42.4585 32.714C42.2665 32.832 42.0909 32.9749 41.9363 33.139C41.7772 33.3041 41.6385 33.4879 41.5234 33.6864Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M42.3251 31.3101C42.2171 30.9727 42.1103 30.6494 41.9843 30.3262C41.6385 30.5354 41.3583 30.8375 41.1752 31.1981C41.1299 31.2859 41.0917 31.3772 41.0608 31.4711C40.963 31.7357 40.9394 32.0221 40.9926 32.2992C41.0621 32.6392 41.1212 32.9831 41.1701 33.3373C41.3788 33.2502 41.5714 33.1288 41.7399 32.9779C41.901 32.8261 42.0403 32.6528 42.1541 32.4628C42.3611 32.0764 42.4422 31.6682 42.3251 31.3101Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M43.6328 31.3351C43.9479 31.3531 44.2759 31.366 44.5962 31.3673C44.9809 31.3424 45.3412 31.1698 45.602 30.8856C45.7555 30.7279 45.8878 30.5508 45.9956 30.3589C46.099 30.1582 46.1738 29.9441 46.2181 29.7227C45.8931 29.7673 45.5716 29.8047 45.2534 29.8347C44.8877 29.8831 44.5489 30.0532 44.2914 30.3177C44.1426 30.4533 44.0128 30.6083 43.9055 30.7787C43.7922 30.9523 43.7006 31.1392 43.6328 31.3351Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M43.9388 29.1043C43.7913 28.8235 43.6387 28.5505 43.4809 28.2852C42.9304 28.7849 42.6732 29.5795 42.9253 30.2002C43.0449 30.49 43.1529 30.7824 43.2584 31.085C43.4277 30.9725 43.5765 30.8318 43.6983 30.669C43.8152 30.5088 43.9088 30.3328 43.9761 30.1461C44.102 29.8076 44.0885 29.4329 43.9388 29.1043Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M45.1953 28.7093C45.4731 28.672 45.7548 28.6295 46.0378 28.5805C46.3662 28.4969 46.651 28.2922 46.8352 28.0074C46.9435 27.848 47.0301 27.6747 47.0925 27.4923C47.1237 27.3987 47.1486 27.3032 47.1671 27.2064C47.1814 27.1083 47.1887 27.0093 47.1889 26.9102C46.9176 26.9977 46.6462 27.0802 46.3671 27.151C45.7793 27.3081 45.3085 27.9855 45.1953 28.7093Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M45.1068 26.7162C44.9422 26.496 44.7647 26.2783 44.5846 26.0723C44.5329 26.1373 44.4868 26.2067 44.447 26.2796C44.4055 26.3506 44.3686 26.4242 44.3364 26.4998C44.2747 26.6494 44.2361 26.8075 44.2219 26.9686C44.1756 27.2718 44.2381 27.5816 44.3981 27.8431C44.5448 28.0749 44.6901 28.3132 44.8316 28.5579C44.959 28.4307 45.0658 28.2842 45.148 28.1239C45.2228 27.9651 45.2743 27.7964 45.301 27.6229C45.3144 27.5403 45.3208 27.4567 45.3203 27.373C45.3168 27.2916 45.3065 27.2106 45.2895 27.1309C45.2596 26.981 45.1972 26.8394 45.1068 26.7162Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M46.1971 25.9811C46.4269 25.9055 46.6584 25.8248 46.8917 25.7389C47.0207 25.6889 47.1374 25.6116 47.2338 25.5123C47.3389 25.3999 47.4257 25.2717 47.4911 25.1323C47.5617 24.9825 47.6107 24.8234 47.6364 24.6597C47.6608 24.4888 47.6608 24.3154 47.6364 24.1445C47.4126 24.2566 47.1888 24.3648 46.9663 24.4665C46.7155 24.5966 46.5154 24.807 46.3978 25.0641C46.3643 25.1329 46.3355 25.2039 46.3116 25.2766C46.2839 25.3496 46.2616 25.4245 46.2447 25.5007C46.2056 25.6576 46.1896 25.8195 46.1971 25.9811Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M45.8193 24.2837C45.6393 24.1172 45.4588 23.9562 45.2778 23.8008C45.2065 23.9279 45.1561 24.0658 45.1286 24.209C45.0978 24.3472 45.0853 24.4888 45.0913 24.6302C45.0989 24.7673 45.1275 24.9024 45.1762 25.0307C45.2188 25.1525 45.2853 25.2646 45.3717 25.3604C45.5351 25.5369 45.6984 25.7197 45.8592 25.9065C45.9463 25.7767 46.0127 25.6342 46.056 25.484C46.1029 25.3382 46.1229 25.185 46.1152 25.032C46.1189 24.7533 46.0126 24.4844 45.8193 24.2837Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M46.6071 23.803C46.7975 23.7128 46.9762 23.6175 47.1627 23.5184C47.3742 23.3898 47.5295 23.186 47.5975 22.9478C47.6785 22.6762 47.6834 22.3875 47.6116 22.1133C47.438 22.2305 47.2541 22.3425 47.0766 22.452C46.8782 22.586 46.7326 22.785 46.665 23.0148C46.5741 23.2673 46.5541 23.5399 46.6071 23.803Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M46.1001 22.4128C45.9329 22.2904 45.7721 22.1732 45.5984 22.0586C45.5036 22.2893 45.4795 22.543 45.529 22.7875C45.5627 23.0139 45.6747 23.2213 45.8454 23.3735C46.0036 23.5023 46.1682 23.6414 46.3187 23.7792C46.4352 23.5395 46.4765 23.2701 46.437 23.0065C46.4044 22.7728 46.2838 22.5605 46.1001 22.4128Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M46.4004 19.7324C46.2747 19.8976 46.1869 20.0884 46.1431 20.2914C46.0895 20.5344 46.067 20.7832 46.0762 21.0319C46.0844 21.1615 46.1003 21.2905 46.1238 21.4183C46.1434 21.536 46.1726 21.6518 46.2113 21.7647C46.2771 21.96 46.3802 22.1407 46.5148 22.2966C46.665 22.1499 46.7745 21.9666 46.8325 21.7647C46.8728 21.6462 46.9021 21.5242 46.92 21.4002C46.9377 21.2653 46.9425 21.1291 46.9341 20.9933C46.9268 20.7316 46.8682 20.4738 46.7618 20.2347C46.6826 20.041 46.5587 19.8689 46.4004 19.7324Z" fill="#A86727" />
        </g>
      </g>
      <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M31.5819 37.7697C31.2767 38.1175 30.9542 38.4609 30.6147 38.8C30.4197 38.9833 30.1851 39.119 29.9291 39.1967C29.7881 39.2435 29.643 39.2767 29.4957 39.2959C29.3394 39.3134 29.1821 39.3199 29.0249 39.3152C28.8596 39.312 28.6948 39.2948 28.5323 39.2637C28.366 39.2313 28.2018 39.1883 28.041 39.1349C27.717 39.0243 27.413 38.8621 27.1406 38.6545C27.5522 38.3879 27.9432 38.1278 28.3227 37.8367C28.5318 37.6827 28.7684 37.5704 29.0198 37.5057C29.2896 37.4311 29.5682 37.3925 29.8481 37.3911C30.1433 37.3865 30.4381 37.4159 30.7266 37.4787C31.023 37.5386 31.3104 37.6364 31.5819 37.7697Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M29.0135 35.7578C28.8977 35.32 28.809 34.864 28.7344 34.43C29.2427 34.4221 29.7433 34.5558 30.1801 34.8164C30.6044 35.057 30.9383 35.4302 31.1306 35.8789C31.3017 36.2936 31.4894 36.7134 31.6965 37.1346C31.1205 37.2571 30.5198 37.1751 29.9974 36.9028C29.7575 36.7788 29.5422 36.6121 29.362 36.4108C29.1975 36.2227 29.0782 35.9993 29.0135 35.7578Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M28.4266 37.0092C28.0888 37.2668 27.7359 37.5188 27.3681 37.7652C27.1612 37.8978 26.9257 37.979 26.6812 38.0022C26.4031 38.0371 26.1209 38.0201 25.849 37.9519C25.7021 37.9179 25.5573 37.8749 25.4156 37.8231C25.2717 37.7656 25.1324 37.6971 24.9989 37.6184C24.8646 37.5414 24.7357 37.4553 24.613 37.3608C24.4918 37.2613 24.3783 37.1528 24.2734 37.0362C24.4792 36.9504 24.682 36.8645 24.8818 36.7787C25.0812 36.6872 25.2818 36.5906 25.4773 36.494C25.6921 36.3918 25.9243 36.3315 26.1616 36.3163C26.4195 36.2918 26.6797 36.3061 26.9333 36.3588C27.1997 36.4036 27.4591 36.4828 27.7051 36.5945C27.9636 36.6986 28.2064 36.8382 28.4266 37.0092Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M26.4628 34.7297C26.4487 34.5185 26.4371 34.3137 26.432 34.1038C26.4268 33.8938 26.432 33.6839 26.432 33.4766C26.5508 33.497 26.6679 33.5272 26.7818 33.5667C26.8957 33.6008 27.0066 33.6439 27.1137 33.6955C27.2181 33.7474 27.3187 33.8068 27.4146 33.8732C27.512 33.9346 27.604 34.004 27.6899 34.0806C27.7753 34.157 27.8544 34.2401 27.9266 34.3291C28.001 34.4125 28.0682 34.5021 28.1272 34.597C28.2444 34.7863 28.3263 34.9953 28.369 35.2139C28.4488 35.6196 28.5427 36.0356 28.6546 36.4529C28.3824 36.4512 28.1122 36.4078 27.8532 36.3241C27.6044 36.2293 27.3705 36.0992 27.1587 35.9377C26.965 35.7804 26.7994 35.5914 26.6686 35.3788C26.5514 35.181 26.481 34.9589 26.4628 34.7297Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M25.5257 35.6548C25.1759 35.8265 24.8149 35.9892 24.4427 36.1429C24.2349 36.2254 24.0105 36.2572 23.788 36.2356C23.5345 36.2187 23.2861 36.1558 23.0549 36.0502C22.5391 35.824 22.1034 35.4475 21.8047 34.9696C22.1906 34.8898 22.5931 34.7829 22.9726 34.6773C23.4161 34.5768 23.8802 34.6219 24.2961 34.8061C24.7664 34.9911 25.1873 35.2829 25.5257 35.6587V35.6548Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M24.1502 33.2577C24.1978 32.8713 24.248 32.5017 24.3238 32.123C24.7363 32.279 25.0974 32.5465 25.367 32.8958C25.4937 33.0623 25.5976 33.2451 25.6757 33.4393C25.7481 33.6302 25.786 33.8326 25.7876 34.0369C25.7876 34.4232 25.7978 34.8096 25.821 35.1959C25.5775 35.1435 25.3434 35.0544 25.1264 34.9319C24.9195 34.8035 24.7319 34.6461 24.5695 34.4644C24.266 34.0974 24.0975 33.6685 24.1502 33.2577Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M22.8866 33.6864C22.5444 33.774 22.192 33.8564 21.8357 33.9259C21.6376 33.9593 21.4346 33.9478 21.2415 33.8924C21.0206 33.8342 20.8122 33.736 20.6267 33.6027C20.2123 33.3159 19.8923 32.9125 19.707 32.4436C20.071 32.4333 20.4389 32.4075 20.7939 32.3766C21.2062 32.3597 21.6127 32.4782 21.9515 32.714C22.1435 32.832 22.3191 32.9749 22.4737 33.139C22.6328 33.3041 22.7715 33.4879 22.8866 33.6864Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M22.0853 31.3101C22.1933 30.9727 22.3001 30.6494 22.4262 30.3262C22.7719 30.5354 23.0521 30.8375 23.2352 31.1981C23.2785 31.2848 23.3151 31.3748 23.3445 31.4672C23.4423 31.7319 23.4659 32.0183 23.4127 32.2954C23.3432 32.6354 23.2841 32.9792 23.2352 33.3334C23.0265 33.2463 22.8339 33.1249 22.6654 32.9741C22.5043 32.8223 22.3649 32.6489 22.2512 32.4589C22.0493 32.0764 21.9683 31.6682 22.0853 31.3101Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M20.7819 31.3351C20.4655 31.3531 20.1388 31.366 19.8172 31.3673C19.4325 31.3424 19.0722 31.1698 18.8114 30.8856C18.6579 30.7279 18.5256 30.5508 18.4178 30.3589C18.3145 30.1582 18.2396 29.9441 18.1953 29.7227C18.5203 29.7673 18.8418 29.8047 19.16 29.8347C19.5252 29.8821 19.864 30.0508 20.1221 30.3138C20.2708 30.4494 20.4006 30.6045 20.5079 30.7749C20.6214 30.9499 20.7134 31.138 20.7819 31.3351Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M20.4705 29.1043C20.618 28.8235 20.7706 28.5505 20.9284 28.2852C21.4789 28.7849 21.7362 29.5795 21.4841 30.2002C21.3644 30.49 21.2564 30.7824 21.1509 31.085C20.9817 30.9725 20.8329 30.8318 20.7111 30.669C20.5941 30.5088 20.5006 30.3328 20.4332 30.1461C20.3074 29.8076 20.3208 29.4329 20.4705 29.1043Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M19.2202 28.7093C18.9424 28.672 18.6607 28.6295 18.3777 28.5805C18.0493 28.4969 17.7645 28.2922 17.5803 28.0074C17.472 27.848 17.3855 27.6747 17.323 27.4923C17.2919 27.3987 17.2669 27.3032 17.2484 27.2064C17.2341 27.1083 17.2268 27.0093 17.2266 26.9102C17.498 26.9977 17.7693 27.0802 18.0485 27.151C18.6363 27.3081 19.1122 27.9855 19.2202 28.7093Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M19.3073 26.7162C19.4719 26.496 19.6494 26.2783 19.8295 26.0723C19.8812 26.1373 19.9273 26.2067 19.9671 26.2796C20.0086 26.3506 20.0455 26.4242 20.0777 26.4998C20.1395 26.6494 20.1781 26.8075 20.1922 26.9686C20.2385 27.2718 20.176 27.5816 20.016 27.8431C19.8694 28.0749 19.724 28.3132 19.5825 28.5579C19.4551 28.4307 19.3483 28.2842 19.2661 28.1239C19.1914 27.9651 19.1398 27.7964 19.1131 27.6229C19.0998 27.5403 19.0933 27.4567 19.0938 27.373C19.0973 27.2916 19.1076 27.2106 19.1246 27.1309C19.1546 26.981 19.2169 26.8394 19.3073 26.7162Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M18.2154 25.9811C17.9847 25.9055 17.7532 25.8248 17.5208 25.7389C17.3918 25.6889 17.2752 25.6116 17.1787 25.5123C17.0736 25.3999 16.9868 25.2717 16.9215 25.1323C16.8508 24.9825 16.8019 24.8234 16.7761 24.6597C16.7517 24.4888 16.7517 24.3154 16.7761 24.1445C16.9999 24.2566 17.2237 24.3648 17.4462 24.4665C17.697 24.5966 17.8971 24.807 18.0147 25.0641C18.0482 25.1329 18.077 25.2039 18.1009 25.2766C18.1286 25.3496 18.151 25.4245 18.1678 25.5007C18.2069 25.6576 18.223 25.8195 18.2154 25.9811Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M18.5947 24.2837C18.7748 24.1172 18.9553 23.9562 19.1362 23.8008C19.2075 23.9279 19.2579 24.0658 19.2854 24.209C19.3162 24.3472 19.3288 24.4888 19.3227 24.6302C19.3142 24.767 19.2847 24.9016 19.2352 25.0294C19.1926 25.1513 19.1262 25.2633 19.0397 25.3591C18.8764 25.5356 18.713 25.7184 18.5523 25.9052C18.4652 25.7755 18.3988 25.6329 18.3555 25.4828C18.3094 25.3368 18.2903 25.1836 18.2989 25.0307C18.2955 24.7524 18.4018 24.484 18.5947 24.2837Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M17.8011 23.803C17.6107 23.7128 17.432 23.6175 17.2455 23.5184C17.034 23.3898 16.8787 23.186 16.8107 22.9478C16.7297 22.6762 16.7248 22.3875 16.7966 22.1133C16.9702 22.2305 17.1541 22.3425 17.3316 22.452C17.53 22.586 17.6756 22.785 17.7432 23.0148C17.8341 23.2673 17.8541 23.5399 17.8011 23.803Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M18.3119 22.4128C18.4791 22.2904 18.6399 22.1732 18.8135 22.0586C18.9083 22.2893 18.9325 22.543 18.883 22.7875C18.8493 23.0139 18.7373 23.2213 18.5666 23.3735C18.4084 23.5023 18.2437 23.6414 18.0932 23.7792C17.9764 23.5396 17.9356 23.27 17.9762 23.0065C18.0079 22.7728 18.1281 22.5602 18.3119 22.4128Z" fill="#A86727" />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path d="M18.0104 19.7324C18.136 19.8976 18.2238 20.0884 18.2677 20.2914C18.3219 20.5343 18.3444 20.7832 18.3345 21.0319C18.3263 21.1615 18.3104 21.2905 18.2869 21.4183C18.2674 21.536 18.2381 21.6518 18.1995 21.7647C18.1337 21.96 18.0306 22.1407 17.8959 22.2966C17.7457 22.1499 17.6363 21.9666 17.5782 21.7647C17.538 21.6462 17.5087 21.5242 17.4908 21.4002C17.4731 21.2653 17.4683 21.1291 17.4766 20.9933C17.484 20.7316 17.5426 20.4738 17.649 20.2347C17.7282 20.041 17.852 19.8689 18.0104 19.7324Z" fill="#A86727" />
        </g>
      </g>
      <g style={{ mixBlendMode: "multiply" }} opacity="0.4">
        <path d="M42.0813 11.6328L41.9128 12.864L42.8299 13.7012L41.608 13.9214L41.0973 15.0522L40.5108 13.9574L39.2773 13.8209L40.1365 12.9233L39.8857 11.7075L41.0022 12.2484L42.0813 11.6328Z" fill="#A86727" />
      </g>
      <g style={{ mixBlendMode: "multiply" }} opacity="0.4">
        <path d="M37.4094 9.33008L37.6293 10.5523L38.7599 11.0649L37.6654 11.6521L37.529 12.8872L36.6338 12.0269L35.4184 12.278L35.9586 11.1589L35.3438 10.0796L36.5734 10.2483L37.4094 9.33008Z" fill="#A86727" />
      </g>
      <g style={{ mixBlendMode: "multiply" }} opacity="0.4">
        <path d="M32.256 8.58594L32.8425 9.68064L34.0747 9.81845L33.2168 10.7148L33.4676 11.9319L32.3499 11.391L31.272 12.0053L31.4405 10.7741L30.5234 9.93822L31.7441 9.71799L32.256 8.58594Z" fill="#A86727" />
      </g>
      <g style={{ mixBlendMode: "multiply" }} opacity="0.4">
        <path d="M27.1215 9.4707L28.0167 10.331L29.2322 10.0799L28.692 11.1978L29.3055 12.277L28.0772 12.1096L27.2411 13.0278L27.0212 11.8044L25.8906 11.2918L26.9839 10.7058L27.1215 9.4707Z" fill="#A86727" />
      </g>
      <g style={{ mixBlendMode: "multiply" }} opacity="0.4">
        <path d="M22.5146 11.9015L23.6323 12.4424L24.7102 11.8281L24.5417 13.0593L25.4588 13.8952L24.2382 14.1154L23.7262 15.2475L23.1397 14.1528L21.9062 14.015L22.7654 13.1186L22.5146 11.9015Z" fill="#A86727" />
      </g>
      <g style={{ mixBlendMode: "multiply" }} opacity="0.4">
        <path d="M21.5759 16.1442C21.5759 16.0222 21.5398 15.903 21.4721 15.8015C21.4044 15.7001 21.3082 15.621 21.1956 15.5743C21.083 15.5276 20.9592 15.5154 20.8396 15.5392C20.7201 15.563 20.6104 15.6218 20.5242 15.708C20.438 15.7943 20.3794 15.9042 20.3556 16.0239C20.3318 16.1436 20.344 16.2676 20.3906 16.3803C20.4373 16.493 20.5162 16.5894 20.6176 16.6572C20.7189 16.725 20.838 16.7611 20.9598 16.7611C21.0408 16.7613 21.121 16.7455 21.1958 16.7145C21.2706 16.6836 21.3386 16.6382 21.3959 16.5808C21.4531 16.5235 21.4985 16.4554 21.5294 16.3805C21.5603 16.3056 21.5761 16.2253 21.5759 16.1442Z" fill="#A86727" />
      </g>
      <g style={{ mixBlendMode: "multiply" }} opacity="0.4">
        <path d="M44.6775 16.1442C44.6775 16.0222 44.6413 15.9029 44.5736 15.8014C44.5058 15.6999 44.4095 15.6208 44.2969 15.5742C44.1842 15.5275 44.0603 15.5154 43.9407 15.5393C43.8212 15.5632 43.7114 15.6221 43.6253 15.7085C43.5392 15.7949 43.4806 15.9049 43.457 16.0247C43.4334 16.1444 43.4458 16.2685 43.4926 16.3812C43.5394 16.4939 43.6186 16.5902 43.7201 16.6578C43.8215 16.7254 43.9408 16.7614 44.0627 16.7611C44.1435 16.7611 44.2236 16.7452 44.2983 16.7142C44.3729 16.6831 44.4408 16.6377 44.4979 16.5804C44.555 16.5231 44.6003 16.4551 44.6311 16.3802C44.6619 16.3054 44.6777 16.2252 44.6775 16.1442Z" fill="#A86727" />
      </g>
      <g style={{ mixBlendMode: "screen" }} opacity="0.3">
        <path d="M32.204 6.80079C31.8181 6.80079 31.4322 6.81496 31.0464 6.84071L15.1102 22.7976C15.0845 23.184 15.0703 23.5639 15.0703 23.9567C15.0662 26.7613 15.7522 29.5236 17.0678 31.9996L40.2377 8.80474C37.7648 7.4861 35.0056 6.79783 32.204 6.80079Z" fill="#A86727" />
      </g>
      <g style={{ mixBlendMode: "screen" }} opacity="0.3">
        <path d="M41.882 9.79883L18.0703 33.6402C18.8174 34.7308 19.6875 35.7316 20.6633 36.6229L44.8621 12.3939C43.9716 11.4172 42.9716 10.5464 41.882 9.79883Z" fill="#A86727" />
      </g>
      <g style={{ mixBlendMode: "screen" }} opacity="0.3">
        <path d="M49.3319 23.9515C49.3318 23.1115 49.2708 22.2726 49.1493 21.4414L29.6953 40.9194C30.5254 41.0414 31.3632 41.103 32.2021 41.1036C34.0433 41.1053 35.8726 40.8096 37.6197 40.2278L48.4573 29.3761C49.0386 27.6268 49.334 25.795 49.3319 23.9515Z" fill="#A86727" />
      </g>
      <path d="M32.2066 41.3158C22.6449 41.3158 14.8672 33.5267 14.8672 23.9538C14.8672 14.3809 22.6449 6.5918 32.2066 6.5918C41.7683 6.5918 49.5512 14.3796 49.5512 23.9538C49.5512 33.5279 41.767 41.3158 32.2066 41.3158ZM32.2066 7.01165C27.7207 7.01676 23.42 8.80337 20.2479 11.9795C17.0759 15.1557 15.2916 19.462 15.2865 23.9538C15.2865 33.2948 22.8751 40.8946 32.2066 40.8946C41.5381 40.8946 49.1255 33.2961 49.1255 23.9538C49.1255 14.6115 41.5355 7.01165 32.2066 7.01165Z" fill="#A86727" />
      <g style={{ mixBlendMode: "screen" }} opacity="0.5">
        <g style={{ mixBlendMode: "screen" }} opacity="0.5">
          <path d="M32.2066 41.3158C22.6449 41.3158 14.8672 33.5267 14.8672 23.9538C14.8672 14.3809 22.6449 6.5918 32.2066 6.5918C41.7683 6.5918 49.5512 14.3796 49.5512 23.9538C49.5512 33.5279 41.767 41.3158 32.2066 41.3158ZM32.2066 7.01165C27.7207 7.01676 23.42 8.80337 20.2479 11.9795C17.0759 15.1557 15.2916 19.462 15.2865 23.9538C15.2865 33.2948 22.8751 40.8946 32.2066 40.8946C41.5381 40.8946 49.1255 33.2961 49.1255 23.9538C49.1255 14.6115 41.5355 7.01165 32.2066 7.01165Z" fill="#A86727" />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_5678_21033">
        <rect width="64" height="64" fill="white" transform="translate(0.203125 0.724609)" />
      </clipPath>
    </defs>
  </svg>
);

export default BronzeMedalIcon;
