import React from "react";
import Icon from "./icon";

function Extensibility(props) {
  return (
    <Icon viewBox="0 0 300 300" {...props}>
      <g>
        <g>
          <path d="M149.996,0C67.157,0,0.001,67.161,0.001,149.997S67.157,300,149.996,300s150.003-67.163,150.003-150.003
			S232.838,0,149.996,0z M234.752,201.789l-81.296,40.291c-1.089,0.539-2.272,0.812-3.455,0.812c-1.183,0-2.368-0.27-3.455-0.809
			L65.25,201.792c-3.851-1.909-5.426-6.577-3.517-10.429c1.906-3.846,6.58-5.426,10.426-3.517l77.844,38.58l77.841-38.577
			c3.844-1.911,8.517-0.335,10.426,3.514C240.178,195.211,238.603,199.88,234.752,201.789z M234.752,172.18l-81.296,40.291
			c-1.089,0.539-2.272,0.812-3.455,0.812c-1.183,0-2.368-0.27-3.455-0.809L65.25,172.183c-3.851-1.909-5.426-6.577-3.517-10.429
			c1.906-3.849,6.58-5.421,10.426-3.517l77.844,38.58l77.841-38.577c3.844-1.917,8.517-0.335,10.426,3.514
			C240.178,165.603,238.603,170.271,234.752,172.18z M234.752,142.572l-81.296,40.291c-1.089,0.539-2.272,0.812-3.455,0.812
			c-1.183,0-2.368-0.27-3.455-0.809L65.25,142.572c-3.851-1.909-5.426-6.577-3.517-10.426c1.906-3.849,6.58-5.426,10.426-3.517
			l77.844,38.58l77.841-38.58c3.844-1.906,8.517-0.335,10.426,3.517C240.178,135.995,238.603,140.663,234.752,142.572z
			 M234.752,111.506l-81.296,40.289c-1.089,0.539-2.272,0.809-3.455,0.809c-1.183,0-2.368-0.27-3.455-0.809L65.25,111.506
			c-2.648-1.312-4.326-4.015-4.326-6.972c0-2.957,1.675-5.659,4.326-6.972l81.299-40.294c2.176-1.079,4.733-1.079,6.909,0
			l81.296,40.294c2.648,1.312,4.326,4.015,4.326,6.972C239.08,107.491,237.403,110.191,234.752,111.506z"/>
        </g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
    </Icon>
  );
}
export default Extensibility;
