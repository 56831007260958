import React from "react";

const AssetsAndDiscountsSummaryIcon = (props) => (
  <svg {...props} className={"icon"} width="112" height="96" viewBox="0 0 112 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4106_12281)">
      <path d="M5.07617 62.8586H108.223" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M5.07617 33.0955H108.223" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M30.8629 92.6218L30.8629 3.33229" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <rect x="10.0175" y="76.4185" width="15.9044" height="8.56012" rx="1" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="35.8039" y="76.4185" width="15.9044" height="8.56012" rx="1" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="61.5905" y="76.4185" width="15.9044" height="8.56012" rx="1" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="2" />
      <rect x="87.3771" y="76.4185" width="15.9044" height="8.56012" rx="1" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="2" />
      <path d="M56.6494 92.6218L56.6494 3.33229" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M82.436 92.6218L82.436 3.33229" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M108.576 90.6217L108.576 5.33228C108.576 4.22771 107.681 3.33228 106.576 3.33228L6.19801 3.33227C5.09344 3.33227 4.19801 4.2277 4.19801 5.33227L4.19801 90.6217C4.19801 91.7263 5.09343 92.6217 6.19801 92.6217L106.576 92.6217C107.681 92.6217 108.576 91.7263 108.576 90.6217Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
    </g>
    <defs>
      <clipPath id="clip0_4106_12281">
        <rect x="111.223" y="0.5" width="95" height="111" rx="2" transform="rotate(90 111.223 0.5)" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AssetsAndDiscountsSummaryIcon;
