import React from "react";

const FestivitiesTableIcon = (props) => (
    <svg {...props} className={"icon"} width="111" height="95" viewBox="0 0 111 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4719_12370)">
            <path d="M108 90.1218L108 13.1211C108 12.0165 107.105 11.1211 106 11.1211L5.62184 11.1211C4.51727 11.1211 3.62184 12.0165 3.62184 13.1211L3.62184 90.1218C3.62184 91.2263 4.51726 92.1218 5.62184 92.1218L106 92.1218C107.105 92.1218 108 91.2264 108 90.1218Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
            <path d="M89.709 2.71428L89.709 11.1236" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M66.967 2.71428L66.967 11.1236" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M44.2251 2.71428L44.2251 11.1236" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M21.4832 2.71428L21.4832 11.1236" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
            <rect x="59" y="68" width="14" height="14" rx="1" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="2" />
            <rect x="19" y="68" width="14" height="14" rx="1" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="2" />
            <rect x="79" y="68" width="14" height="14" rx="1" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="2" />
            <rect x="39" y="68" width="14" height="14" rx="1" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="2" />
            <rect x="59" y="28" width="14" height="14" rx="1" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="2" />
            <rect x="19" y="28" width="14" height="14" rx="1" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="2" />
            <rect x="59" y="48" width="14" height="14" rx="1" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="2" />
            <rect x="19" y="48" width="14" height="14" rx="1" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="2" />
            <rect x="79" y="28" width="14" height="14" rx="1" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="2" />
            <rect x="39" y="28" width="14" height="14" rx="1" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="2" />
            <rect x="79" y="48" width="14" height="14" rx="1" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="2" />
            <rect x="39" y="48" width="14" height="14" rx="1" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="2" />
        </g>
        <defs>
            <clipPath id="clip0_4719_12370">
                <rect x="111" width="95" height="111" rx="2" transform="rotate(90 111 0)" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default FestivitiesTableIcon;
