import React from "react";

const AlertsUserIcon = (props) => (
  <svg {...props} className={"icon"} width="130" height="113" viewBox="0 0 130 113" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4025_11685)">
      <g clipPath="url(#clip1_4025_11685)">
        <path d="M33.6951 7.30433L33.6947 7.30433C29.8245 7.30231 26.0055 8.20189 22.5333 9.93376C19.0611 11.6657 16.0285 14.1835 13.6711 17.2931C11.3136 20.4027 9.69463 24.0204 8.94007 27.866C8.18552 31.7117 8.3158 35.6815 9.32074 39.4679C10.3257 43.2543 12.1782 46.7552 14.7341 49.6995C17.29 52.6437 20.4806 54.9523 24.0585 56.4472C27.6364 57.942 31.5059 58.5833 35.3674 58.3214C39.229 58.0596 42.9796 56.9016 46.3288 54.9368L46.8127 54.653L47.2006 55.0582C49.7876 57.7604 53.0582 58.2949 55.7514 58.1311C56.9383 58.059 57.9987 57.8512 58.8134 57.6411C58.0213 57.214 57.0549 56.6218 56.1472 55.874C54.3154 54.3648 52.5202 52.0411 53.2747 49.0464L53.3118 48.8993L53.4058 48.7802C56.3706 45.0228 58.2277 40.4937 58.7622 35.7138C59.2966 30.934 58.4867 26.0989 56.426 21.7651C54.3653 17.4313 51.1381 13.7757 47.1167 11.2179C43.0955 8.66015 38.4432 7.30393 33.6951 7.30433Z" fill="var(--svgColor)" stroke="var(--svgColor)" strokeWidth="1.41944" />
        <path d="M36.8486 16.7189L36.156 37.9387H31.1186L30.405 16.7189H36.8486ZM30.2371 44.5083C30.2371 43.6127 30.545 42.8711 31.1606 42.2834C31.7903 41.6818 32.6159 41.3809 33.6373 41.3809C34.6728 41.3809 35.4983 41.6818 36.114 42.2834C36.7297 42.8711 37.0375 43.6127 37.0375 44.5083C37.0375 45.3758 36.7297 46.1104 36.114 46.7121C35.4983 47.3138 34.6728 47.6146 33.6373 47.6146C32.6159 47.6146 31.7903 47.3138 31.1606 46.7121C30.545 46.1104 30.2371 45.3758 30.2371 44.5083Z" fill="#FF0000" />
      </g>
      <path d="M90.8419 74.0953C98.9265 74.0953 106.715 77.596 112.482 83.8854C117.872 89.7629 121.077 97.6224 121.478 105.934H60.2055C60.6066 97.6224 63.8116 89.7629 69.2016 83.8854C74.9693 77.596 82.7573 74.0953 90.8419 74.0953Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="3.55129" />
      <circle cx="90.8421" cy="47.4913" r="20.4199" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="3.55129" />
    </g>
    <defs>
      <clipPath id="clip0_4025_11685">
        <rect width="128.341" height="112.298" fill="white" transform="translate(0.829834 0.300781)" />
      </clipPath>
      <clipPath id="clip1_4025_11685">
        <rect width="53.9389" height="54.412" fill="white" transform="matrix(-1 0 0 1 60.7274 5.64832)" />
      </clipPath>
    </defs>
  </svg>
);

export default AlertsUserIcon;
