import React from "react";

const ExcelBigIcon = (props) => (
  <svg {...props} className={"icon"} width="111" height="96" viewBox="0 0 111 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 21L57 14V82.5L18 74.5V21Z" fill="#F5A506" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M91.2396 24.6785L91.2396 25.8785L91.2396 70.5218L91.2396 71.7218L90.0396 71.7218L59.0775 71.7218L59.0775 64.3611L67.718 
      64.3611C68.4243 64.3611 68.9982 63.7873 68.9982 63.081C68.9982 62.3746 68.4243 61.8008 67.718 61.8008L59.0774 61.8008L59.0774 54.4407L67.718 54.4407C68.4243 54.4407 
      68.9982 53.8669 68.9982 53.1605C68.9982 52.4542 68.4243 51.8804 67.718 51.8804L59.0774 51.8804L59.0774 44.5198L67.718 44.5198C68.4243 44.5198 68.9982 43.946 68.9982 
      43.2396C68.9982 42.5333 68.4243 41.9595 67.718 41.9595L59.0774 41.9595L59.0774 34.5989L67.718 34.5989C68.4243 34.5989 68.9982 34.0251 68.9982 33.3188C68.9982 32.6124 
      68.4243 32.0386 67.718 32.0386L59.0774 32.0386L59.0774 24.6785L90.0396 24.6785L91.2396 24.6785ZM56.5171 63.0765L56.5171 53.165L56.5171 53.1605L56.5171 53.1561L56.5171 
      43.2451L56.5171 43.2396L56.5171 43.2342L56.5171 33.3242L56.5171 33.3188L56.5171 33.3133L56.5171 23.3983L56.5171 16.4684L56.5171 15.0226L55.096 15.2889L20.3735 
      21.7969L19.3946 21.9804L19.3946 22.9764L19.3946 73.4233L19.3946 74.4192L20.3735 74.6027L55.096 81.1107L56.5171 81.3771L56.5171 79.9312L56.5171 73.002L56.5171 
      63.0854L56.5171 63.081L56.5171 63.0765ZM59.0774 22.1182L92.5198 22.1182C93.2261 22.1182 93.8 22.692 93.8 23.3983L93.8 73.002C93.8 73.7083 93.2261 74.2821 92.5198 
      74.2821L59.0775 74.2821L59.0775 82.9223C59.0775 83.3019 58.9073 83.6658 58.6154 83.9069L58.6114 83.9102C58.3853 84.0986 58.0932 84.2025 57.7973 84.2025C57.7239 
      84.2025 57.6441 84.1948 57.5763 84.1812L57.5692 84.1798L57.5621 84.1784L17.8792 76.7379L17.8771 76.7375C17.2723 76.6252 16.8342 76.0964 16.8342 75.4818L16.8342 
      20.9178C16.8342 20.2998 17.2709 19.7747 17.8771 19.6621L17.8792 19.6618L57.5591 12.2218L57.5602 12.2216C57.9367 12.1523 58.3255 12.2538 58.6159 12.4932C58.9085 
      12.7352 59.0774 13.0943 59.0774 13.4773L59.0774 22.1182ZM77.6387 32.0386L82.5991 32.0386C83.3054 32.0386 83.8793 32.6124 83.8793 33.3188C83.8793 34.0251 83.3054 
      34.5989 82.5991 34.5989L77.6387 34.5989C76.9324 34.5989 76.3585 34.0251 76.3585 33.3188C76.3585 32.6124 76.9324 32.0386 77.6387 32.0386ZM82.5991 41.9595L77.6387 
      41.9595C76.9324 41.9595 76.3585 42.5333 76.3585 43.2396C76.3585 43.946 76.9324 44.5198 77.6387 44.5198L82.5991 44.5198C83.3054 44.5198 83.8793 43.946 83.8793 
      43.2397C83.8793 42.5333 83.3054 41.9595 82.5991 41.9595ZM77.6387 51.8804L82.5991 51.8804C83.3054 51.8804 83.8793 52.4542 83.8793 53.1605C83.8793 53.8669 83.3054 
      54.4407 82.5991 54.4407L77.6387 54.4407C76.9324 54.4407 76.3585 53.8669 76.3585 53.1605C76.3585 52.4542 76.9324 51.8804 77.6387 51.8804ZM82.5991 61.8008L77.6387 
      61.8008C76.9324 61.8008 76.3585 62.3746 76.3585 63.081C76.3585 63.7873 76.9324 64.3611 77.6387 64.3611L82.5991 64.3611C83.3054 64.3611 83.8793 63.7873 83.8793 
      63.081C83.8793 62.3746 83.3054 61.8008 82.5991 61.8008ZM48.8435 57.2789C49.3091 57.8109 49.2565 58.6135 48.726 59.0802L46.9141 58.9618L38.7448 49.6255L31.5224 
      58.9113C31.2751 59.2293 30.8982 59.4005 30.5153 59.4005C30.241 59.4005 29.9656 59.3144 29.7268 59.1306C29.1733 58.7007 29.07 57.8936 29.5039 57.3333L29.5043 
      57.3327L37.0263 47.6615L29.5533 39.1209L29.5514 39.1188C29.0887 38.5925 29.1395 37.7845 29.6736 37.3172L29.6757 37.3153C30.2018 36.8527 31.0061 36.8977 31.4837 
      37.439L31.4847 37.4402L38.6273 45.6032L46.8618 35.0159L46.863 35.0145C47.3042 34.4527 48.108 34.3565 48.6588 34.7885L48.6642 34.7927C49.2173 35.2215 49.3227 36.0234 
      48.8835 36.5899L48.883 36.5906L40.3458 47.5671L48.8435 57.2789ZM48.726 59.0802L46.9146 58.9624C47.1702 59.2538 47.5215 59.4008 47.8766 59.4008C48.1725 59.4008 48.472 
      59.2987 48.726 59.0802Z"
      fill="var(--svgColor)"
    />
  </svg>
);

export default ExcelBigIcon;