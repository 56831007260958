import React from "react";
import Icon from "./icon";

function CoinsIcon(props) {
  return (
    <Icon viewBox="0 0 300.005 300.005" {...props}>
      <g>
        <g>
        <path d="M150,0C67.159,0,0.002,67.162,0.002,150S67.159,300.005,150,300.005S300.003,232.841,300.003,150S232.841,0,150,0z
				 M129.624,75.705c33.463,0,51.517,10.579,51.517,14.906c0,4.324-18.054,14.9-51.517,14.9c-33.466,0-51.517-10.577-51.517-14.9
				C78.104,86.287,96.159,75.705,129.624,75.705z M129.622,121.073c19.224,0,38.57-3.488,51.517-10.268v14.563
				c0,4.324-18.054,14.903-51.517,14.903c-33.466,0-51.517-10.579-51.517-14.903c0,0,0-14.563,0-14.563
				C91.049,117.587,110.398,121.073,129.622,121.073z M78.104,145.562c12.945,6.782,32.293,10.271,51.517,10.271
				s38.57-3.488,51.517-10.271v12.924c0,4.326-18.054,14.906-51.517,14.906c-33.466,0-51.517-10.579-51.517-14.906
				C78.105,158.486,78.105,145.562,78.104,145.562z M199.383,244.833c-22.157,0-40.196-17.813-40.637-39.867
				c-7.789,1.844-17.504,3.133-29.121,3.133c-33.466,0-51.517-10.577-51.517-14.903v-14.511
				c12.945,6.782,32.293,10.271,51.517,10.271c11.734,0,23.506-1.307,33.847-3.862c6.845-12.844,20.37-21.615,35.911-21.615
				c22.425,0,40.673,18.246,40.673,40.678S221.807,244.833,199.383,244.833z"/>
			<path d="M198.418,197.52c-6.544-0.781-9.869-1.714-9.869-4.656c0-4.399,5.916-5.055,9.449-5.055c4.178,0,8.805,2.02,10.31,4.505
				l0.597,0.993l9.555-4.42l-0.584-1.19c-3.359-6.868-9.322-8.94-13.92-9.783v-6.077H192.78v6.043
				c-9.843,1.533-15.673,7.078-15.673,14.983c0,13.004,12.198,14.369,20.264,15.271c7.33,0.869,10.751,2.63,10.751,5.537
				c0,5.628-7.952,6.066-10.39,6.066c-5.47,0-10.74-2.71-12.255-6.303l-0.506-1.198l-10.369,4.394l0.511,1.196
				c2.918,6.832,9.168,11.134,17.67,12.211v6.552h11.176v-6.881c8.157-1.006,16.013-6.222,16.013-16.039
				C219.968,200.176,207.005,198.579,198.418,197.52z"/>
        </g>
      </g>
    </Icon>
  );
}
export default CoinsIcon;
