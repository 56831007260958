import React from "react";

const DocumentTemplatesIcon = (props) => (
    <svg {...props} className={"icon"} width="89" height="92" viewBox="0 0 89 92" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M69.0076 87.0509C69.0078 88.1557 68.1123 89.0513 67.0076 89.0513H4.98828C3.88371 89.0513 2.98828 88.1559 2.98828 87.0513V5.01489C2.98828 3.91033 3.88371 3.01489 4.98828 3.01489H45.9918C46.5128 3.01489 47.0132 3.21819 47.3866 3.58152L68.3899 24.0196C68.7767 24.396 68.995 24.9128 68.9951 25.4525L69.0076 87.0509Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
        <path d="M46.8047 3.01489V22.9402C46.8047 23.4925 47.2524 23.9402 47.8047 23.9402H68.5446" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
        <rect x="12" y="34" width="32" height="16" rx="2" stroke="var(--svgColor)" strokeWidth="3" />
        <rect x="12" y="59" width="47" height="19" rx="2" stroke="var(--svgColor)" strokeWidth="3" />
        <rect x="49" y="34" width="40" height="41" rx="20" fill="#FF592C" />
        <path d="M66.991 62.9774C67.0795 62.9459 67.1594 62.8942 67.2243 62.8264L67.2289 62.831L80.1641 49.8169C80.9315 48.8371 81.3037 47.6062 81.2073 46.3663C81.111 45.1265 80.553 43.9676 79.6433 43.1176C77.8246 41.4039 75.0576 41.3228 73.1325 42.7951L60.0969 55.7104L60.1022 55.715C60.0344 55.7796 59.9825 55.8591 59.9508 55.9471L56.8091 65.3373C56.7786 65.4284 56.7703 65.5253 56.7847 65.6203C56.7992 65.7152 56.836 65.8053 56.8923 65.8832C56.9485 65.9611 57.0224 66.0246 57.108 66.0684C57.1937 66.1122 57.2885 66.1351 57.3847 66.1352C57.3906 66.1352 57.3959 66.1352 57.4019 66.1352H73.3469C73.6814 66.1352 73.9526 65.864 73.9526 65.5295C73.9526 65.195 73.6814 64.9239 73.3469 64.9239H61.1318L66.991 62.9774ZM58.9067 62.8943L60.0301 64.0153L58.3476 64.5744L58.9067 62.8943ZM61.3849 56.1377L69.9471 47.5961L70.9708 48.6161L62.4046 57.1571L61.3849 56.1377ZM71.8299 49.472L73.482 51.1205L64.9172 59.666L63.2651 58.0136L71.8299 49.472ZM74.3412 51.9776L75.3655 52.9984L66.7994 61.5446L65.7757 60.5219L74.3412 51.9776ZM76.2233 52.1418L70.8042 46.7415L73.0862 44.4667L78.5013 49.8736L76.2233 52.1418ZM78.8099 43.9985C79.48 44.6254 79.8993 45.4736 79.9901 46.3856C80.0808 47.2976 79.8369 48.2115 79.3036 48.9577L73.9856 43.649C74.7075 43.1453 75.5814 42.905 76.46 42.9686C77.3387 43.0323 78.1685 43.396 78.8099 43.9985ZM60.7915 57.2586L65.6759 62.138L61.3142 63.5886L59.3356 61.6105L60.7915 57.2586Z" fill="var(--svgColor)" />
    </svg>
);

export default DocumentTemplatesIcon;
