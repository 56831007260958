import React from "react";

const BronzeShieldIcon = (props) => (
  <svg {...props} className={"icon"} width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5678_21061)">
      <path d="M32.2031 64.7246C25.7722 62.2229 20.283 57.9201 15.9616 52.1358C12.6395 47.6954 10.0737 42.4665 8.29511 36.7116C5.71533 28.367 5.84182 9.91878 5.84055 8.80422C7.87601 8.57876 23.5911 6.66936 32.2031 0.724609C40.8152 6.66936 56.5302 8.57876 58.5657 8.80422C58.5657 9.91878 58.6935 28.3734 56.1086 36.7205C54.3287 42.4729 51.7642 47.7018 48.4421 52.1333C44.1194 57.9188 38.6328 62.2203 32.2031 64.7246Z" fill="url(#paint0_linear_5678_21061)" />
      <path d="M32.2013 64.2787C25.9403 61.8127 20.5929 57.6436 16.2907 51.8861C13.035 47.5284 10.4795 42.3824 8.69068 36.5866C6.42651 29.2598 6.2489 13.9999 6.25401 9.16716C9.21712 8.81432 23.8474 6.83486 32.2039 1.21875C40.5604 6.83486 55.1906 8.81432 58.1537 9.16716C58.1537 14.0075 57.98 29.2675 55.7145 36.5956C53.9257 42.3913 51.3702 47.5361 48.1106 51.8899C43.8071 57.6448 38.4623 61.8127 32.2013 64.2787Z" fill="url(#paint1_linear_5678_21061)" />
      <path d="M32.2016 62.0554C26.6319 59.6976 21.839 55.8661 17.947 50.6563C14.8344 46.4898 12.3824 41.5513 10.6638 35.9811C8.70754 29.6529 8.3715 16.8463 8.32422 10.9678C13.1797 10.2697 24.5823 8.23041 32.2041 3.65625C39.8259 8.23041 51.2235 10.2697 56.084 10.9678C56.0368 16.8476 55.7007 29.658 53.7419 35.9887C52.0195 41.5602 49.5675 46.4962 46.4587 50.6602C42.5616 55.8687 37.77 59.6989 32.2016 62.0554Z" fill="url(#paint2_linear_5678_21061)" />
      <path d="M31.6823 16.2299C31.7422 16.0457 32.0029 16.0457 32.0628 16.2299L32.6094 17.9123C32.6362 17.9947 32.713 18.0505 32.7996 18.0505H34.5685C34.7623 18.0505 34.8428 18.2984 34.6861 18.4123L33.255 19.452C33.1849 19.503 33.1556 19.5932 33.1823 19.6756L33.729 21.358C33.7888 21.5423 33.578 21.6955 33.4212 21.5816L31.9901 20.5419C31.92 20.4909 31.8251 20.4909 31.755 20.5419L30.3239 21.5816C30.1672 21.6955 29.9563 21.5423 30.0161 21.358L30.5628 19.6756C30.5895 19.5932 30.5602 19.503 30.4901 19.452L29.059 18.4123C28.9023 18.2984 28.9828 18.0505 29.1766 18.0505H30.9455C31.0322 18.0505 31.1089 17.9947 31.1357 17.9123L31.6823 16.2299Z" fill="#A86727" />
      <path d="M31.3947 28.3815C31.5443 27.9208 32.196 27.9208 32.3457 28.3815L33.6565 32.4155C33.7234 32.6215 33.9154 32.761 34.132 32.761L38.3736 32.761C38.858 32.761 39.0594 33.3808 38.6675 33.6655L35.236 36.1587C35.0607 36.286 34.9874 36.5117 35.0543 36.7177L36.365 40.7517C36.5147 41.2124 35.9875 41.5954 35.5956 41.3107L32.1641 38.8176C31.9888 38.6903 31.7515 38.6903 31.5763 38.8176L28.1448 41.3107C27.7529 41.5954 27.2257 41.2124 27.3753 40.7517L28.6861 36.7177C28.753 36.5117 28.6797 36.286 28.5044 36.1587L25.0729 33.6655C24.681 33.3808 24.8824 32.761 25.3668 32.761L29.6084 32.761C29.825 32.761 30.017 32.6215 30.0839 32.4155L31.3947 28.3815Z" fill="#A86727" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_5678_21061" x1="17.3339" y1="59.1607" x2="38.9121" y2="1.45111" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D7B189" />
        <stop offset="0.28" stopColor="#92562D" />
        <stop offset="0.3" stopColor="#8D5027" />
        <stop offset="0.4" stopColor="#894C26" />
        <stop offset="0.47" stopColor="#824523" />
        <stop offset="0.49" stopColor="#874C29" />
        <stop offset="0.52" stopColor="#955E3A" />
        <stop offset="0.56" stopColor="#AD7C57" />
        <stop offset="0.6" stopColor="#CEA57E" />
        <stop offset="0.61" stopColor="#D7B189" />
        <stop offset="0.77" stopColor="#AB6C2E" />
        <stop offset="0.83" stopColor="#9C530D" />
        <stop offset="0.85" stopColor="#9F5712" />
        <stop offset="0.89" stopColor="#A66322" />
        <stop offset="0.92" stopColor="#B2773C" />
        <stop offset="0.96" stopColor="#C39260" />
        <stop offset="1" stopColor="#D7B189" />
      </linearGradient>
      <linearGradient id="paint1_linear_5678_21061" x1="17.5263" y1="58.7912" x2="38.7894" y2="1.92679" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D7B189" />
        <stop offset="0.2" stopColor="#92562D" />
        <stop offset="0.21" stopColor="#8D5027" />
        <stop offset="0.33" stopColor="#894C26" />
        <stop offset="0.42" stopColor="#884C27" />
        <stop offset="0.43" stopColor="#B47B43" />
        <stop offset="0.44" stopColor="#CB9552" />
        <stop offset="0.62" stopColor="#FFF2DB" />
        <stop offset="0.66" stopColor="#D3AB7E" />
        <stop offset="0.71" stopColor="#9C530D" />
        <stop offset="1" stopColor="#D7B189" />
      </linearGradient>
      <linearGradient id="paint2_linear_5678_21061" x1="8.32166" y1="32.8552" x2="56.0815" y2="32.8552" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="0.02" stopColor="#060606" />
        <stop offset="0.1" stopColor="#111111" />
        <stop offset="0.21" stopColor="#181818" />
        <stop offset="0.5" stopColor="#1A1A1A" />
        <stop offset="0.79" stopColor="#181818" />
        <stop offset="0.9" stopColor="#111111" />
        <stop offset="0.98" stopColor="#060606" />
        <stop offset="1" />
      </linearGradient>
      <clipPath id="clip0_5678_21061">
        <rect width="64" height="64" fill="white" transform="translate(0.203125 0.724609)" />
      </clipPath>
    </defs>
  </svg>
);

export default BronzeShieldIcon;
