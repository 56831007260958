import React from "react";

const EntryIcon = (props) => (
  <svg {...props} className={"icon"} width="81" height="101" viewBox="0 0 81 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M78.4684 2.81723V81.7882C78.4684 82.5593 78.0251 83.2618 77.329 83.5936L47.4739 97.8251C46.1466 98.4578 44.6133 97.4901 44.6133 96.0197V19.3128C44.6133 18.5287 45.0715 17.8169 45.7852 17.4923L78.3035 2.701C78.3219 2.69179 78.3424 2.68774 78.3629 2.68929C78.3833 2.69083 78.403 2.6979 78.4198 2.70978C78.4367 2.72165 78.4501 2.73789 78.4586 2.75679C78.4671 2.77569 78.4706 2.79656 78.4684 2.81723Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M78.3976 2.70096L19.2199 2.63067C18.1144 2.62936 17.2175 3.52518 17.2175 4.63067V34.694" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M16.1199 56.34V84.64C16.1199 85.7445 17.0154 86.64 18.1199 86.64H44.6061" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M35.8274 45.0847L1.66321 45.0847" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M24.2345 32.4706L35.6177 43.6801C36.4052 44.4556 36.4144 45.7229 35.6381 46.5097L24.4782 57.8223" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M52.4926 48.0493V58.4399" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
  </svg>

);

export default EntryIcon;
