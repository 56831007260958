import React from "react";

const CommunicationClassificationIcon = (props) => (
    <svg {...props} className={"icon"} width="111" height="96" viewBox="0 0 111 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_7335_581)">
            <path d="M17 65V55C17 53.8954 17.8954 53 19 53H92C93.1046 53 94 53.8954 94 55V65" stroke="var(--svgColor)" strokeWidth="2" />
            <path d="M55 43L55 63.6743" stroke="var(--svgColor)" strokeWidth="2" />
            <path d="M60.8395 4H50.4421C39.7045 4 31 12.7045 31 23.4421C31 34.1797 39.7045 42.8843 50.4421 42.8843H60.8394C71.577 42.8843 80.2816 34.1797 80.2816 23.4421C80.2816 12.7045 71.577 4 60.8395 4Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="2.20882" strokeMiterlimit="10" />
            <path d="M5 76.437C5 83.0644 10.3726 88.437 17 88.437C23.6274 88.437 29 83.0644 29 76.437C29 69.8096 23.6274 64.437 17 64.437C10.3726 64.437 5 69.8096 5 76.437Z" stroke="var(--svgColor)" strokeWidth="2.20882" strokeMiterlimit="10" />
            <path d="M43 76.437C43 83.0644 48.3726 88.437 55 88.437C61.6274 88.437 67 83.0644 67 76.437C67 69.8096 61.6274 64.437 55 64.437C48.3726 64.437 43 69.8096 43 76.437Z" stroke="var(--svgColor)" strokeWidth="2.20882" strokeMiterlimit="10" />
            <path d="M82 76.437C82 83.0644 87.3726 88.437 94 88.437C100.627 88.437 106 83.0644 106 76.437C106 69.8096 100.627 64.437 94 64.437C87.3726 64.437 82 69.8096 82 76.437Z" stroke="var(--svgColor)" strokeWidth="2.20882" strokeMiterlimit="10" />
            <path fillRule="evenodd" clipRule="evenodd" d="M58.3565 9.53211L60.6307 8.92277L60.9178 9.99433C60.9267 10.0203 60.9348 10.0467 60.942 10.0736L65.1091 25.6254L65.1142 25.624L66.7497 31.7278L64.4756 32.3371L64.1744 31.2132C64.1651 31.1863 64.1567 31.1589 64.1492 31.131L63.754 29.6562L52.4619 28.6921L53.6869 33.2639C53.9461 34.1868 53.825 35.1516 53.3456 35.982C52.3729 37.6668 50.2107 38.2462 48.5258 37.2734C47.6955 36.794 47.1082 36.019 46.8712 35.0902L45.5168 30.0356L43.6344 30.54C41.7507 31.0448 39.8178 29.9288 39.3131 28.0451L38.7043 25.773C38.2007 23.8937 39.32 21.9552 41.1992 21.4517L48.5622 19.4788L59.0608 12.1602L58.3565 9.53211ZM59.992 15.6359L59.7081 14.5763L50.4153 21.0542L51.8145 26.2761L63.1066 27.2401L59.9969 15.6346L59.992 15.6359ZM43.0256 28.2679C42.3977 28.4362 41.7534 28.0642 41.5851 27.4363L40.9763 25.1642C40.8085 24.5378 41.1815 23.8916 41.8079 23.7238L48.234 22.0019L49.4516 26.5461L43.0256 28.2679ZM51.4213 33.8967C51.5088 34.2053 51.4688 34.5282 51.3084 34.8059C50.9842 35.3676 50.2635 35.5607 49.7018 35.2364C49.4241 35.0761 49.228 34.8165 49.1494 34.5055L47.7887 29.4268L50.0603 28.8181L51.4213 33.8967Z" fill="var(--svgColor)" />
            <rect x="69.4626" y="12.6143" width="2.39686" height="5.09017" transform="rotate(57.914 69.4626 12.6143)" fill="var(--svgColor)" />
            <rect x="72.1094" y="19.9077" width="2.39686" height="5.20645" transform="rotate(87.3797 72.1094 19.9077)" fill="var(--svgColor)" />
        </g>
        <defs>
            <clipPath id="clip0_7335_581">
                <rect x="111" y="0.228027" width="95" height="111" rx="2" transform="rotate(90 111 0.228027)" fill="var(--svgColor)" />
            </clipPath>
        </defs>
    </svg>
);

export default CommunicationClassificationIcon;
