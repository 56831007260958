import React from "react";

const FeedbackPurposeIcon = (props) => (
  <svg {...props} className={"icon"} width="111" height="95" viewBox="0 0 111 95" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5788_18452)">
      <path d="M80.5076 88.0509C80.5078 89.1557 79.6123 90.0513 78.5076 90.0513H16.4883C15.3837 90.0513 14.4883 89.1559 14.4883 88.0513V6.01489C14.4883 4.91033 15.3837 4.01489 16.4883 4.01489H57.4918C58.0128 4.01489 58.5132 4.21819 58.8866 4.58152L79.8899 25.0196C80.2767 25.396 80.495 25.9128 80.4951 26.4525L80.5076 88.0509Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
      <path d="M36.5354 49.4106H68" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M26.366 45.3102C26.5157 44.8495 27.1674 44.8495 27.3171 45.3102L27.9785 47.3459C28.0454 47.5519 28.2374 47.6913 28.454 47.6913L30.5944 47.6913C31.0788 47.6913 31.2802 48.3112 30.8883 48.5959L29.1567 49.854C28.9814 49.9813 28.9081 50.207 28.975 50.413L29.6365 52.4486C29.7861 52.9093 29.2589 53.2923 28.867 53.0076L27.1354 51.7495C26.9602 51.6222 26.7229 51.6222 26.5476 51.7495L24.816 53.0076C24.4241 53.2923 23.8969 52.9093 24.0466 52.4486L24.708 50.413C24.7749 50.207 24.7016 49.9813 24.5264 49.854L22.7947 48.5959C22.4029 48.3112 22.6043 47.6913 23.0886 47.6913L25.229 47.6913C25.4457 47.6913 25.6376 47.5519 25.7046 47.3459L26.366 45.3102Z" fill="#FF592C" />
      <path d="M26.366 59.1476C26.5157 58.687 27.1674 58.687 27.3171 59.1476L27.9785 61.1833C28.0454 61.3893 28.2374 61.5288 28.454 61.5288L30.5944 61.5288C31.0788 61.5288 31.2802 62.1486 30.8883 62.4333L29.1567 63.6914C28.9814 63.8187 28.9081 64.0444 28.975 64.2504L29.6365 66.286C29.7861 66.7467 29.2589 67.1297 28.867 66.845L27.1354 65.5869C26.9602 65.4596 26.7229 65.4596 26.5476 65.5869L24.816 66.845C24.4241 67.1297 23.8969 66.7467 24.0466 66.286L24.708 64.2504C24.7749 64.0444 24.7016 63.8187 24.5264 63.6914L22.7947 62.4333C22.4029 62.1486 22.6043 61.5288 23.0886 61.5288L25.229 61.5288C25.4457 61.5288 25.6376 61.3893 25.7046 61.1833L26.366 59.1476Z" fill="#FF592C" />
      <path d="M26.366 72.9843C26.5157 72.5236 27.1674 72.5236 27.3171 72.9843L27.9785 75.0199C28.0454 75.2259 28.2374 75.3654 28.454 75.3654L30.5944 75.3654C31.0788 75.3654 31.2802 75.9852 30.8883 76.2699L29.1567 77.528C28.9814 77.6554 28.9081 77.881 28.975 78.087L29.6365 80.1227C29.7861 80.5834 29.2589 80.9664 28.867 80.6817L27.1354 79.4236C26.9602 79.2963 26.7229 79.2963 26.5476 79.4236L24.816 80.6817C24.4241 80.9664 23.8969 80.5834 24.0466 80.1227L24.708 78.087C24.7749 77.881 24.7016 77.6554 24.5264 77.528L22.7947 76.2699C22.4029 75.9852 22.6043 75.3654 23.0886 75.3654L25.229 75.3654C25.4457 75.3654 25.6376 75.2259 25.7046 75.0199L26.366 72.9843Z" fill="#FF592C" />
      <path d="M36.5354 63.2478H68" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M36.5354 77.085H68" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M58.3047 4.01489V23.9402C58.3047 24.4925 58.7524 24.9402 59.3047 24.9402H80.0446" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
      <path d="M72.437 46.525L73.6614 38.1854L96.4924 15.3544L99.7153 12.1315C100.016 11.8313 100.502 11.8313 100.802 12.1315L108.139 19.4679C108.439 19.7681 108.439 20.2548 108.139 20.5551L104.916 23.778L82.085 46.609L73.7454 47.8334C72.9811 47.9456 72.3248 47.2893 72.437 46.525Z" fill="#FF592C" />
      <path d="M73.6614 38.1854L72.437 46.525C72.3248 47.2893 72.9811 47.9456 73.7454 47.8334L82.085 46.609M73.6614 38.1854L82.085 46.609M73.6614 38.1854L96.4924 15.3544M82.085 46.609L104.916 23.778M96.4924 15.3544L99.7153 12.1315C100.016 11.8313 100.502 11.8313 100.802 12.1315L108.139 19.4679C108.439 19.7681 108.439 20.2548 108.139 20.5551L104.916 23.778M96.4924 15.3544L104.916 23.778" stroke="var(--svgColor)" strokeWidth="2" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_5788_18452">
        <rect x="111" width="95" height="111" rx="2" transform="rotate(90 111 0)" fill="var(--svgColor)" />
      </clipPath>
    </defs>
  </svg>
);

export default FeedbackPurposeIcon;
