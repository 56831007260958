import React from "react";

const CollabIcon = (props) => (
  <svg {...props} className={"icon"}  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
    <path d="M31.8435 18.5656C33.2613 9.82966 27.3287 1.59841 18.5928 0.180622C9.8568 -1.23717 1.62555 4.69538 0.207762 13.4313C-1.21003 22.1673 4.72252 30.3986 13.4585 31.8163C22.1944 33.2341 30.4257 27.3016 31.8435 18.5656Z" fill="#FF4040"/>
    <path d="M22.1689 22.0215C22.1689 22.6605 21.9232 23.3487 21.4316 23.8402C20.9401 24.3318 20.2519 24.5776 19.6129 24.5776H11.4531V7.42242H19.6129C20.2519 7.42242 20.9401 7.6682 21.4316 8.15975C21.9232 8.6513 22.1689 9.33947 22.1689 9.97849V22.0215Z" fill="#495057"/>
    <path d="M22.1689 13.1736H11.4531V18.8756H22.1689V13.1736Z" fill="white"/>
    <path d="M17.893 16.9585H14.8945V17.5975H17.893V16.9585Z" fill="#CCCBCA"/>
    <path d="M20.8915 15.6805H14.8945V16.3195H20.8915V15.6805Z" fill="#CCCBCA"/>
    <path d="M20.8915 14.4516H14.8945V15.0906H20.8915V14.4516Z" fill="#CCCBCA"/>
    <path d="M11.4525 24.5776H10.4203C10.2728 24.5776 10.1253 24.5284 9.97787 24.381C9.87956 24.2826 9.78125 24.086 9.78125 23.9386V8.06144C9.78125 7.91398 9.8304 7.76651 9.97787 7.61904C10.0762 7.52073 10.2728 7.42242 10.4203 7.42242H11.4525V24.5776Z" fill="#343B43"/>
    <path d="M13.6127 7.42242H12.5312V24.5776H13.6127V7.42242Z" fill="#D3D3D3"/>
    <path d="M24.4782 14.6483H23.3477V22.8572H24.4782V14.6483Z" fill="#495057"/>
    <path d="M24.235 23.9877H23.5469V24.381C23.5469 24.4301 23.5469 24.4793 23.596 24.5284C23.6452 24.5776 23.6943 24.5776 23.7435 24.5776H24.0876C24.1367 24.5776 24.1859 24.5776 24.235 24.5284C24.2842 24.4793 24.2842 24.4301 24.2842 24.381V23.9877H24.235Z" fill="white"/>
    <path d="M24.4805 23.8403C24.4805 23.8894 24.4805 23.9386 24.4314 23.9877C24.3822 24.0369 24.333 24.0369 24.2839 24.0369H23.4974C23.4482 24.0369 23.3991 24.0369 23.3499 23.9877C23.3008 23.9386 23.3008 23.8894 23.3008 23.8403V22.9063H24.4314V23.8403H24.4805Z" fill="#343B43"/>
    <path d="M24.4805 22.8571V22.6114C24.5296 22.6114 24.5788 22.6114 24.6279 22.5622C24.6771 22.5131 24.6771 22.4639 24.6771 22.4148V19.7604H24.9229V22.4148C24.9229 22.5622 24.8737 22.6605 24.7754 22.7588C24.7262 22.808 24.6279 22.8571 24.4805 22.8571Z" fill="#343B43"/>
    <path d="M23.793 13.5177L23.8913 13.2227C23.8913 13.2227 23.8913 13.1736 23.9404 13.1736C23.9404 13.1736 23.9896 13.1736 23.9896 13.2227L24.0879 13.5177H23.793Z" fill="white"/>
    <path d="M24.1341 13.5176H23.6917L23.3477 14.6482H24.4782L24.1341 13.5176Z" fill="#343B43"/>
    </g>
    <defs>
    <clipPath id="clip0">
    <rect width="32" height="32" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);
export default CollabIcon;
