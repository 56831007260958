import React from "react";

const AssetsAndDiscountsEntryPerConceptIcon = (props) => (
  <svg {...props} className={"icon"} width="74" height="93" viewBox="0 0 74 93" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M70.0837 87.0509C70.084 88.1557 69.1885 89.0513 68.0837 89.0513H6.06445C4.95988 89.0513 4.06445 88.1559 4.06445 87.0513V5.01489C4.06445 3.91033 4.95988 3.01489 6.06445 3.01489H47.0679C47.5889 3.01489 48.0894 3.21819 48.4627 3.58152L69.466 24.0196C69.8529 24.396 70.0711 24.9128 70.0713 25.4525L70.0837 87.0509Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
    <path d="M47.8809 3.01489V22.9402C47.8809 23.4925 48.3286 23.9402 48.8809 23.9402H69.6208" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
    <path d="M22.4199 46.6289H54.3449" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22.4199 57.0161H54.3449" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22.4199 67.4033H54.3449" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22.4225 61.1677C28.3183 61.1677 33.0978 56.3882 33.0978 50.4924C33.0978 44.5966 28.3183 39.8171 22.4225 39.8171C16.5267 39.8171 11.7472 44.5966 11.7472 50.4924C11.7472 56.3882 16.5267 61.1677 22.4225 61.1677Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="2.4894" strokeMiterlimit="10" />
    <path d="M26 51H16" stroke="var(--svgColor)" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M22.5 46L26.6185 50.437C26.9719 50.8178 26.965 51.4087 26.6028 51.7811L22.5 56" stroke="var(--svgColor)" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
  </svg>
);

export default AssetsAndDiscountsEntryPerConceptIcon;
