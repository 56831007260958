import React from "react";

const ExtraHoursTableIcon = (props) => (
    <svg {...props} className={"icon"} width="112" height="96" viewBox="0 0 112 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5235_16717)">
            <path fillRule="evenodd" clipRule="evenodd" d="M106.811 9.7959C108.744 9.7959 110.311 11.3629 110.311 13.2959L110.311 90.2966C110.311 92.2296 108.744 93.7966 106.811 93.7966L6.43275 93.7966C4.49975 93.7966 2.93275 92.2296 2.93275 90.2966L2.93275 13.2959C2.93275 11.3629 4.49976 9.79589 6.43275 9.79589L20.7941 9.79589L20.7941 2.88916C20.7941 2.06073 21.4656 1.38916 22.2941 1.38916C23.1225 1.38916 23.7941 2.06073 23.7941 2.88916L23.7941 9.79589L43.536 9.79589L43.536 2.88916C43.536 2.06073 44.2076 1.38916 45.036 1.38916C45.8645 1.38916 46.536 2.06073 46.536 2.88916L46.536 9.79589L66.278 9.7959L66.278 2.88916C66.278 2.06073 66.9496 1.38916 67.778 1.38916C68.6064 1.38916 69.278 2.06073 69.278 2.88916L69.278 9.7959L89.02 9.7959L89.02 2.88916C89.02 2.06073 89.6916 1.38916 90.52 1.38916C91.3484 1.38916 92.02 2.06073 92.02 2.88916L92.02 9.7959L106.811 9.7959ZM22.2051 12.7959C22.2345 12.7976 22.2642 12.7985 22.2941 12.7985C22.3239 12.7985 22.3536 12.7976 22.383 12.7959L44.9471 12.7959C44.9765 12.7976 45.0062 12.7985 45.036 12.7985C45.0659 12.7985 45.0956 12.7976 45.125 12.7959L67.6891 12.7959C67.7185 12.7976 67.7482 12.7985 67.778 12.7985C67.8079 12.7985 67.8375 12.7976 67.8669 12.7959L90.4311 12.7959C90.4605 12.7976 90.4901 12.7985 90.52 12.7985C90.5498 12.7985 90.5795 12.7976 90.6089 12.7959L106.811 12.7959C107.087 12.7959 107.311 13.0198 107.311 13.2959L107.311 90.2966C107.311 90.5727 107.087 90.7966 106.811 90.7966L6.43275 90.7966C6.1566 90.7966 5.93275 90.5727 5.93275 90.2966L5.93275 13.2959C5.93275 13.0198 6.15661 12.7959 6.43275 12.7959L22.2051 12.7959Z" fill="var(--svgColor)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M43.1051 32.5583C31.291 32.5583 21.74 42.0509 21.74 53.7284C21.74 65.4059 31.291 74.8985 43.1051 74.8985C54.9192 74.8985 64.4702 65.4059 64.4702 53.7284C64.4702 42.0509 54.9192 32.5583 43.1051 32.5583ZM18.115 53.7284C18.115 40.0201 29.3179 28.9333 43.1051 28.9333C56.8922 28.9333 68.0951 40.0201 68.0951 53.7284C68.0951 67.4367 56.8922 78.5234 43.1051 78.5234C29.3179 78.5234 18.115 67.4367 18.115 53.7284ZM42.3078 42.3006C43.112 42.3006 43.7639 42.9526 43.7639 43.7568L43.7639 53.8811L55.4616 53.9374C56.2658 53.9412 56.9147 54.5963 56.9108 55.4005C56.907 56.2047 56.2519 56.8535 55.4477 56.8496L42.3008 56.7864C41.4966 56.7825 40.8478 56.1274 40.8516 55.3232C40.852 55.2498 40.8578 55.1777 40.8686 55.1073C40.8574 55.0346 40.8516 54.9602 40.8516 54.8844L40.8516 43.7568C40.8516 42.9526 41.5036 42.3006 42.3078 42.3006Z" fill="var(--svgColor)" />
            <rect x="83.6748" y="21" width="29.6748" height="29.6748" rx="14.8374" transform="rotate(90 83.6748 21)" fill="#F5A506" />
            <path d="M67.709 44.6601V42.5985C66.4522 42.5651 65.2186 42.2454 64.0981 41.6627L64.7354 39.1264C65.8213 39.7266 67.0322 40.0522 68.2666 40.0758C69.4879 40.0758 70.311 39.5876 70.311 38.7195C70.311 37.8515 69.6339 37.3632 68.0542 36.8343C65.7841 36.0476 64.2309 34.9626 64.2309 32.8603C64.2745 31.8814 64.6623 30.9515 65.3233 30.2413C65.9842 29.5312 66.8737 29.0886 67.8285 28.9949V26.9333H69.9127V28.8457C70.9776 28.8596 72.0274 29.1047 72.9925 29.5646L72.3553 32.0466C71.4117 31.5473 70.3646 31.2869 69.302 31.287C67.9745 31.287 67.4833 31.9109 67.4833 32.5213C67.4833 33.1316 68.2267 33.7012 70.0322 34.3794C72.5677 35.2881 73.5766 36.4816 73.5766 38.4483C73.5781 39.4908 73.1878 40.4942 72.4863 41.2513C71.7847 42.0085 70.8255 42.4616 69.8065 42.5172V44.7415L67.709 44.6601Z" fill="white" />
            <rect x="93.3268" y="50.6748" width="19.7502" height="19.7502" rx="9.87509" transform="rotate(90 93.3268 50.6748)" fill="#F5A506" />
            <path d="M82.7007 66.4219V65.0498C81.8643 65.0276 81.0432 64.8148 80.2975 64.4269L80.7216 62.7389C81.4444 63.1384 82.2503 63.3551 83.0718 63.3708C83.8847 63.3708 84.4325 63.0458 84.4325 62.4681C84.4325 61.8904 83.9819 61.5654 82.9305 61.2134C81.4196 60.6898 80.3859 59.9677 80.3859 58.5685C80.4149 57.917 80.673 57.2981 81.1129 56.8254C81.5528 56.3528 82.1448 56.0582 82.7802 55.9959V54.6238H84.1674V55.8966C84.8761 55.9058 85.5748 56.0689 86.2172 56.375L85.7931 58.0269C85.1651 57.6946 84.4682 57.5213 83.761 57.5214C82.8774 57.5214 82.5505 57.9366 82.5505 58.3428C82.5505 58.7491 83.0453 59.1282 84.2469 59.5795C85.9345 60.1843 86.606 60.9787 86.606 62.2876C86.6069 62.9815 86.3472 63.6493 85.8803 64.1532C85.4133 64.6571 84.7749 64.9586 84.0967 64.9956V66.476L82.7007 66.4219Z" fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_5235_16717">
                <rect x="111.811" y="0.174805" width="95" height="111" rx="2" transform="rotate(90 111.811 0.174805)" fill="white" />
            </clipPath>
        </defs>
    </svg>

);

export default ExtraHoursTableIcon;
