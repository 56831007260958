import React from "react";

const EmployerIcon = (props) => (
  <svg {...props} className={"icon"}  width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
    <path d="M27.5906 27.3409C33.8485 21.083 33.8485 10.9369 27.5906 4.67897C21.3327 -1.57895 11.1866 -1.57895 4.92863 4.67897C-1.32929 10.9369 -1.32929 21.083 4.92863 27.3409C11.1866 33.5989 21.3327 33.5989 27.5906 27.3409Z" fill="#F5A506"/>
    <path d="M25.5416 12.0922H6.96094V21.4808C6.96094 21.6283 7.01009 21.7757 7.15756 21.9232C7.25587 22.0215 7.45249 22.1198 7.59995 22.1198H24.9517C25.0992 22.1198 25.2467 22.0707 25.3941 21.9232C25.4924 21.8249 25.5907 21.6283 25.5907 21.4808V12.0922H25.5416Z" fill="white"/>
    <path d="M25.5439 10.5192C25.5439 10.3717 25.4947 10.2243 25.3472 10.0768C25.2489 9.9785 25.0523 9.88019 24.9048 9.88019H7.55308C7.40561 9.88019 7.25815 9.92934 7.11068 10.0768C7.01237 10.1751 6.91406 10.3717 6.91406 10.5192V12.0922H25.4947V10.5192H25.5439Z" fill="#343B43"/>
    <path d="M18.3182 11.0108C18.3182 10.765 18.1215 10.6176 17.9249 10.6176H14.6315C14.3857 10.6176 14.2383 10.8142 14.2383 11.0108C14.2383 11.2566 14.4349 11.404 14.6315 11.404H17.9249C18.1215 11.404 18.3182 11.2074 18.3182 11.0108Z" fill="#E6E5E5"/>
    <path d="M15.5181 13.5668H8.39062V20.6943H15.5181V13.5668Z" fill="#E6E5E5"/>
    <path d="M11.9307 18.2366C10.7019 18.2366 9.71875 19.318 9.71875 20.6452H14.0936C14.1427 19.318 13.1596 18.2366 11.9307 18.2366Z" fill="#495057"/>
    <path d="M13.2559 16.5162C13.2559 17.2535 12.6661 17.8433 11.9288 17.8433C11.1914 17.8433 10.6016 17.2535 10.6016 16.5162C10.6016 15.7788 11.1914 15.189 11.9288 15.189C12.6661 15.189 13.2559 15.7788 13.2559 16.5162Z" fill="#495057"/>
    <path d="M20.5314 20.1045H16.9922V20.6452H20.5314V20.1045Z" fill="#CCCBCA"/>
    <path d="M24.1197 19.0231H16.9922V19.5638H24.1197V19.0231Z" fill="#CCCBCA"/>
    <path d="M24.1197 17.9417H16.9922V18.4824H24.1197V17.9417Z" fill="#CCCBCA"/>
    <path d="M24.1197 16.8111H16.9922V17.3518H24.1197V16.8111Z" fill="#CCCBCA"/>
    <path d="M24.1197 13.5668H16.9922V15.189H24.1197V13.5668Z" fill="#495057"/>
    <path d="M17.1371 7.86484C16.8913 7.61906 16.5964 7.52075 16.2523 7.52075C15.9574 7.52075 15.6133 7.61906 15.3675 7.86484C15.1217 8.11061 15.0234 8.40554 15.0234 8.74963V11.0108H17.4812V8.74963C17.4812 8.40554 17.3337 8.11061 17.1371 7.86484ZM16.2523 9.24118C15.9574 9.24118 15.7116 8.99541 15.7116 8.74963C15.7116 8.4547 15.9574 8.20892 16.2523 8.20892C16.5472 8.20892 16.793 8.4547 16.793 8.74963C16.7439 8.99541 16.4981 9.24118 16.2523 9.24118Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0">
    <rect width="32" height="32" fill="white" transform="translate(0.230469)"/>
    </clipPath>
    </defs>
  </svg>
);
export default EmployerIcon;
