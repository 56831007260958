import React from "react";
import Icon from "./icon";

function CalculationIcon(props) {
  return (
    <Icon viewBox="0 0 300 300" {...props}>
      <g>
        <g>
          <path d="M149.996,0C67.157,0,0.001,67.161,0.001,149.997S67.157,300,149.996,300s150.003-67.163,150.003-150.003
            S232.835,0,149.996,0z M78.081,221.593l35.517-57.227c2.983,3.934,6.323,7.827,10.102,11.606
            c3.737,3.74,7.628,7.114,11.588,10.118L78.081,221.593z M218.641,128.074c-3.779,3.781-10.185,4.152-17.302,1.73l-19.027,19.021
            c9.563,15.536,11.84,30.664,4.375,38.136c-3.022,3.022-7.298,4.451-12.348,4.451c-9.298,0-21.221-4.866-32.762-13.585
            c-0.257-0.192-0.511-0.394-0.765-0.594c-0.822-0.633-1.642-1.286-2.459-1.961c-0.324-0.267-0.646-0.534-0.97-0.807
            c-0.778-0.659-1.551-1.338-2.321-2.031c-0.303-0.272-0.609-0.539-0.913-0.817c-1.048-0.965-2.088-1.953-3.115-2.98
            c-1.172-1.172-2.295-2.36-3.382-3.558c-0.674-0.742-1.307-1.491-1.95-2.241c-0.35-0.41-0.713-0.817-1.056-1.232
            c-0.833-1.001-1.621-2.007-2.394-3.016c-0.096-0.127-0.195-0.252-0.288-0.376c-13.531-17.852-17.857-36.627-9.251-45.232
            c3.022-3.022,7.298-4.451,12.348-4.451c7.436,0,16.544,3.115,25.798,8.813l19.014-19.011c-2.422-7.119-2.049-13.523,1.735-17.302
            c7.083-7.088,23.36-2.303,36.346,10.686C220.946,104.706,225.731,120.983,218.641,128.074z"/>
        </g>
      </g>
    </Icon>
  );
}
export default CalculationIcon;
