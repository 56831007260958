import React from "react";

const CatalogsIcon = (props) => (
  <svg {...props} className={"icon"} width="126" height="98" viewBox="0 0 126 98" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M46.1233 17.0723V80.2322" stroke="var(--svgColor)" strokeWidth="3.40459" />
    <path d="M107.978 17.0723V80.2322" stroke="var(--svgColor)" strokeWidth="3.40459" />
    <path d="M107.978 58.8241C107.978 60.6365 107.612 62.4303 105.961 64.0989C104.309 65.7675 102.377 67.2767 99.3365 68.5366C96.2961 69.7966 92.6953 70.7816 88.7482 71.433C84.8012 72.0844 80.5886 72.3889 76.3613 72.3284C72.1339 72.2679 67.9782 71.8436 64.1414 71.0807C60.3047 70.3178 56.8653 69.232 54.028 67.8879C51.1907 66.5438 49.4986 64.9688 48.111 63.2567C46.7234 61.5447 46.2955 59.8075 46.1233 57.9221" stroke="var(--svgColor)" strokeWidth="3.40459" />
    <path d="M107.978 79.5631C107.978 81.3755 107.612 83.1694 105.961 84.8379C104.309 86.5065 102.377 88.0157 99.3365 89.2756C96.2961 90.5356 92.6953 91.5206 88.7482 92.172C84.8012 92.8234 80.5886 93.1279 76.3613 93.0674C72.1339 93.0069 67.9782 92.5826 64.1414 91.8197C60.3047 91.0569 56.8653 89.9711 54.028 88.6269C51.1907 87.2828 49.4986 85.7078 48.111 83.9958C46.7234 82.2837 46.2955 80.5465 46.1233 78.6611" stroke="var(--svgColor)" strokeWidth="3.40459" />
    <path d="M107.978 38.0848C107.978 39.8972 107.612 41.6911 105.961 43.3597C104.309 45.0282 102.377 46.5374 99.3365 47.7974C96.2961 49.0573 92.6953 50.0423 88.7482 50.6938C84.8012 51.3452 80.5886 51.6497 76.3613 51.5891C72.1339 51.5286 67.9782 51.1043 64.1414 50.3414C60.3047 49.5786 56.8653 48.4928 54.028 47.1487C51.1907 45.8045 49.4986 44.2296 48.111 42.5175C46.7234 40.8054 46.2955 39.0682 46.1233 37.1829" stroke="var(--svgColor)" strokeWidth="3.40459" />
    <path d="M108.381 17.5541C108.381 18.7316 107.786 20.0295 106.332 21.3923C104.876 22.7578 102.676 24.0659 99.8159 25.2001C94.1055 27.4645 86.1003 28.9027 77.1718 28.9027C68.2432 28.9027 60.238 27.4645 54.5277 25.2001C51.6676 24.0659 49.4678 22.7578 48.0114 21.3923C46.5579 20.0295 45.963 18.7316 45.963 17.5541C45.963 16.3767 46.5579 15.0787 48.0114 13.7159C49.4678 12.3504 51.6676 11.0423 54.5277 9.90812C60.238 7.64367 68.2432 6.20547 77.1718 6.20547C86.1003 6.20547 94.1055 7.64367 99.8159 9.90812C102.676 11.0423 104.876 12.3504 106.332 13.7159C107.786 15.0787 108.381 16.3767 108.381 17.5541Z" fill="#484848" stroke="var(--svgColor)" strokeWidth="3.40459" />
    <rect x="11.9171" y="27.7678" width="47.6643" height="47.6643" rx="23.8321" fill="#FF592C" />
    <path fillRule="evenodd" clipRule="evenodd" d="M41.6932 52.8115C38.3718 54.7291 34.1248 53.5912 32.2072 50.2698C30.2896 46.9484 31.4276 42.7013 34.749 40.7837C38.0704 38.8661 42.3174 40.0041 44.235 43.3255C46.1526 46.6469 45.0146 50.8939 41.6932 52.8115ZM31.4886 54.6608C30.6209 53.9179 29.8624 53.0176 29.2587 51.972C26.401 47.0223 28.0969 40.693 33.0467 37.8352C37.9965 34.9775 44.3257 36.6734 47.1835 41.6232C50.0413 46.573 48.3453 52.9022 43.3955 55.76C39.8922 57.7827 35.6978 57.5242 32.5323 55.4453L33.4562 56.1398L25.9899 66.0733C25.4251 66.8248 24.3579 66.9761 23.6064 66.4112C22.8548 65.8464 22.7035 64.7792 23.2684 64.0277L30.7347 54.0942L31.4886 54.6608Z" fill="var(--svgColor)" />
    <path d="M96.4644 34.9063C96.4644 33.7498 97.334 32.7782 98.4835 32.6505L99.2905 32.5608C100.546 32.4213 101.674 33.3338 101.8 34.5908L101.913 35.7232C102.038 36.9705 101.128 38.0828 99.8803 38.2075L98.96 38.2996C97.6238 38.4332 96.4644 37.3839 96.4644 36.0411V34.9063Z" fill="var(--svgColor)" />
    <path d="M96.4644 57.0362C96.4644 55.8797 97.334 54.9081 98.4835 54.7803L99.2905 54.6907C100.546 54.5512 101.674 55.4637 101.8 56.7207L101.913 57.8531C102.038 59.1004 101.128 60.2127 99.8803 60.3374L98.96 60.4294C97.6238 60.5631 96.4644 59.5138 96.4644 58.171V57.0362Z" fill="var(--svgColor)" />
    <path d="M96.4644 78.5984C96.4644 77.4419 97.334 76.4703 98.4835 76.3426L99.2905 76.2529C100.546 76.1134 101.674 77.0259 101.8 78.2829L101.913 79.4154C102.038 80.6627 101.128 81.7749 99.8803 81.8997L98.96 81.9917C97.6238 82.1253 96.4644 81.0761 96.4644 79.7332V78.5984Z" fill="var(--svgColor)" />
  </svg>
);

export default CatalogsIcon;
