import React from "react";

const AssetsAndDiscountsEntryPerSocietyIcon = (props) => (
  <svg {...props} className={"icon"} width="86" height="99" viewBox="0 0 86 99" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4105_12211)">
      <path d="M80.8903 87.8349C80.8905 88.9396 79.995 89.8353 78.8903 89.8353H16.871C15.7664 89.8353 14.871 88.9398 14.871 87.8353V5.79883C14.871 4.69426 15.7664 3.79883 16.871 3.79883H57.8745C58.3955 3.79883 58.8959 4.00212 59.2693 4.36546L80.2726 24.8035C80.6594 25.1799 80.8777 25.6967 80.8778 26.2365L80.8903 87.8349Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
      <path d="M58.1864 4.66895V24.5943C58.1864 25.1466 58.6341 25.5943 59.1864 25.5943H79.9263" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinejoin="round" />
      <path d="M14.0762 37.7725C16.72 37.7725 19.2693 38.9027 21.1591 40.9375C22.8674 42.7768 23.9076 45.2179 24.0934 47.8135H4.05892C4.24472 45.2179 5.28497 42.7768 6.9932 40.9375C8.88306 38.9027 11.4324 37.7725 14.0762 37.7725Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="1.68314" />
      <path d="M20.6814 28.706C20.6814 32.2921 17.7342 35.2173 14.0762 35.2173C10.4181 35.2173 7.47093 32.2921 7.47093 28.706C7.47093 25.1199 10.4181 22.1946 14.0762 22.1946C17.7342 22.1946 20.6814 25.1199 20.6814 28.706Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="1.68314" />
      <path d="M42.0762 37.7725C44.72 37.7725 47.2693 38.9027 49.1591 40.9375C50.8674 42.7768 51.9076 45.2179 52.0934 47.8135H32.0589C32.2447 45.2179 33.285 42.7768 34.9932 40.9375C36.8831 38.9027 39.4324 37.7725 42.0762 37.7725Z" stroke="var(--svgColor)" strokeWidth="1.68314" />
      <path d="M48.6814 28.706C48.6814 32.2921 45.7342 35.2173 42.0762 35.2173C38.4181 35.2173 35.4709 32.2921 35.4709 28.706C35.4709 25.1199 38.4181 22.1946 42.0762 22.1946C45.7342 22.1946 48.6814 25.1199 48.6814 28.706Z" stroke="var(--svgColor)" strokeWidth="1.68314" />
      <path d="M14.0762 69.7725C16.72 69.7725 19.2693 70.9027 21.1591 72.9375C22.8674 74.7768 23.9076 77.2179 24.0934 79.8135H4.05892C4.24472 77.2179 5.28497 74.7768 6.9932 72.9375C8.88306 70.9027 11.4324 69.7725 14.0762 69.7725Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="1.68314" />
      <path d="M20.6814 60.7057C20.6814 64.2918 17.7342 67.2171 14.0762 67.2171C10.4181 67.2171 7.47093 64.2918 7.47093 60.7057C7.47093 57.1196 10.4181 54.1944 14.0762 54.1944C17.7342 54.1944 20.6814 57.1196 20.6814 60.7057Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="1.68314" />
      <path d="M42.0762 69.7725C44.72 69.7725 47.2693 70.9027 49.1591 72.9375C50.8674 74.7768 51.9076 77.2179 52.0934 79.8135H32.0589C32.2447 77.2179 33.285 74.7768 34.9932 72.9375C36.8831 70.9027 39.4324 69.7725 42.0762 69.7725Z" stroke="var(--svgColor)" strokeWidth="1.68314" />
      <path d="M48.6814 60.7057C48.6814 64.2918 45.7342 67.2171 42.0762 67.2171C38.4181 67.2171 35.4709 64.2918 35.4709 60.7057C35.4709 57.1196 38.4181 54.1944 42.0762 54.1944C45.7342 54.1944 48.6814 57.1196 48.6814 60.7057Z" stroke="var(--svgColor)" strokeWidth="1.68314" />
    </g>
    <defs>
      <clipPath id="clip0_4105_12211">
        <rect width="85" height="99" fill="white" transform="translate(0.0761719)" />
      </clipPath>
    </defs>
  </svg>
);

export default AssetsAndDiscountsEntryPerSocietyIcon;
