import React from "react";
import Icon from "./icon";

function ExcelIcon(props) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path d="M2 5v14l10 2V3L2 5zM8.271 15.703l-1.113-2.414c-.042-.085-.092-.256-.133-.494H7.009c-.025.114-.075.285-.15.514l-1.122 2.395H4l2.061-3.707L4.175 8.297h1.779l.922 2.215c.075.181.133.39.191.628h.025c.033-.143.099-.361.2-.646l1.03-2.197h1.621l-1.936 3.67L10 15.703H8.271zM21 5h-7v2h2v2h-2v2h2v2h-2v2h2v2h-2v2h7c.552 0 1-.448 1-1V6C22 5.448 21.552 5 21 5zM20 17h-2v-2h2V17zM20 13h-2v-2h2V13zM20 9h-2V7h2V9z"/>
    </Icon>
  );
}
export default ExcelIcon;
