import React from "react";

const AlertsSystemIcon = (props) => (
  <svg {...props} className={"icon"} width="127" height="117" viewBox="0 0 127 117" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4025_11661)">
      <rect x="24.179" y="23.468" width="95.8431" height="67.8349" rx="6.31809" stroke="var(--svgColor)" strokeWidth="2.52724" strokeLinejoin="round" />
      <rect x="43.5228" y="91.4389" width="57.1553" height="6.57615" rx="2.52724" stroke="var(--svgColor)" strokeWidth="2.52724" strokeLinejoin="round" />
      <rect x="32.5472" y="33.3732" width="79.1065" height="48.0242" rx="6.31809" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="2.52724" strokeLinejoin="round" />
      <g clipPath="url(#clip1_4025_11661)">
        <path d="M22.8609 27.2577L22.8605 27.2577C19.5024 27.2559 16.1886 28.0365 13.1758 29.5392C10.163 31.0419 7.53174 33.2267 5.48621 35.9248C3.44065 38.623 2.03587 41.762 1.38115 45.0988C0.726425 48.4357 0.839466 51.8803 1.71145 55.1657C2.58342 58.4511 4.19081 61.4888 6.40858 64.0435C8.62631 66.5982 11.3948 68.6013 14.4993 69.8984C17.6038 71.1955 20.9613 71.7519 24.3119 71.5247C27.6625 71.2975 30.9169 70.2928 33.823 68.5879L34.2428 68.3417L34.5794 68.6932C36.8242 71.0379 39.662 71.5017 41.9989 71.3596C43.0287 71.297 43.9488 71.1167 44.6558 70.9344C43.9685 70.5638 43.1299 70.0499 42.3424 69.4011C40.7529 68.0916 39.1952 66.0753 39.8499 63.4769L39.8821 63.3492L39.9636 63.2459C42.5362 59.9856 44.1476 56.0557 44.6113 51.9083C45.0751 47.7609 44.3722 43.5655 42.5842 39.8051C40.7962 36.0448 37.996 32.8728 34.5067 30.6534C31.0175 28.4341 26.9807 27.2573 22.8609 27.2577Z" fill="var(--svgColor)" stroke="var(--svgColor)" strokeWidth="1.23164" />
        <path d="M25.5971 35.2637L24.9961 53.6759H20.6253L20.0061 35.2637H25.5971ZM19.8604 59.3762C19.8604 58.5991 20.1275 57.9557 20.6617 57.4457C21.2081 56.9237 21.9244 56.6626 22.8107 56.6626C23.7092 56.6626 24.4255 56.9237 24.9597 57.4457C25.4939 57.9557 25.761 58.5991 25.761 59.3762C25.761 60.1289 25.4939 60.7664 24.9597 61.2884C24.4255 61.8105 23.7092 62.0715 22.8107 62.0715C21.9244 62.0715 21.2081 61.8105 20.6617 61.2884C20.1275 60.7664 19.8604 60.1289 19.8604 59.3762Z" fill="#FF0000" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4025_11661">
        <rect width="126.44" height="116" fill="white" transform="translate(0.280029 0.300781)" />
      </clipPath>
      <clipPath id="clip1_4025_11661">
        <rect width="46.8023" height="47.2128" fill="white" transform="matrix(-1 0 0 1 46.3165 25.8208)" />
      </clipPath>
    </defs>
  </svg>
);

export default AlertsSystemIcon;
