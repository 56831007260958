import React from "react";

const CalculationReferenceIcon = (props) => (
  <svg {...props} className={"icon"} width="118" height="111" viewBox="0 0 118 111" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25.2636 31.0829C32.3861 31.0829 38.1601 25.3279 38.1601 18.2287C38.1601 11.1295 32.3861 5.37451 25.2636 5.37451C18.1411 5.37451 12.3672 11.1295 12.3672 18.2287C12.3672 25.3279 18.1411 31.0829 25.2636 31.0829Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M7.04494 54.8819C6.01684 54.8819 5.16604 54.1114 5.16058 53.0833C5.13113 47.5342 6.59144 31.0534 24.9803 31.0668C43.316 31.0801 45.2295 47.4481 45.3483 53.0372C45.3706 54.084 44.5114 54.8819 43.4644 54.8819H7.04494Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M57.3865 4.59723C63.7992 5.02274 83.0013 8.18051 83.6215 30.9165H76.1258L90.0567 44.8018L103.988 30.9165H97.6962C97.6962 30.9165 88.0164 -4.5984 57.3235 3.92536C57.2429 3.94386 57.1718 3.99089 57.1234 4.05772C57.0749 4.12455 57.0524 4.20662 57.0601 4.28871C57.0678 4.3708 57.105 4.44733 57.1651 4.50407C57.2251 4.56081 57.3038 4.59391 57.3865 4.59723Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M103.437 58.7766H75.1719C74.0673 58.7766 73.1719 59.672 73.1719 60.7766V104.92C73.1719 106.024 74.0673 106.92 75.1719 106.92H103.437C104.542 106.92 105.437 106.024 105.437 104.92V60.7766C105.437 59.672 104.542 58.7766 103.437 58.7766Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M97.3817 64.4746H81.3164C80.2118 64.4746 79.3164 65.37 79.3164 66.4746V70.9601C79.3164 72.0647 80.2118 72.9601 81.3164 72.9601H97.3817C98.4863 72.9601 99.3817 72.0647 99.3817 70.9601V66.4746C99.3817 65.37 98.4863 64.4746 97.3817 64.4746Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M82.932 78.2827H78.875V80.6828H82.932V78.2827Z" fill="var(--svgColor)"/>
    <path d="M91.3577 78.2827H87.3008V80.6828H91.3577V78.2827Z" fill="var(--svgColor)"/>
    <path d="M99.7914 78.2827H95.7344V80.6828H99.7914V78.2827Z" fill="var(--svgColor)"/>
    <path d="M82.932 85.0956H78.875V87.4957H82.932V85.0956Z" fill="var(--svgColor)"/>
    <path d="M91.3577 85.0956H87.3008V87.4957H91.3577V85.0956Z" fill="var(--svgColor)"/>
    <path d="M99.7914 85.0956H95.7344V87.4957H99.7914V85.0956Z" fill="var(--svgColor)"/>
    <path d="M82.932 91.9146H78.875V94.3147H82.932V91.9146Z" fill="var(--svgColor)"/>
    <path d="M91.3577 91.9146H87.3008V94.3147H91.3577V91.9146Z" fill="var(--svgColor)"/>
    <path d="M99.7914 91.9146H95.7344V94.3147H99.7914V91.9146Z" fill="var(--svgColor)"/>
    <path d="M82.932 98.7334H78.875V101.134H82.932V98.7334Z" fill="var(--svgColor)"/>
    <path d="M91.3577 98.7334H87.3008V101.134H91.3577V98.7334Z" fill="var(--svgColor)"/>
    <path d="M99.7914 98.7334H95.7344V101.134H99.7914V98.7334Z" fill="var(--svgColor)"/>
    <path d="M58.1403 105.772C51.7275 105.346 32.5253 102.188 31.9052 79.4522H39.4009L25.47 65.567L11.5391 79.4522H17.8304C17.8304 79.4522 27.5057 114.967 58.2031 106.439C58.2804 106.418 58.3478 106.37 58.3934 106.304C58.4389 106.238 58.4598 106.159 58.4523 106.079C58.4448 105.999 58.4094 105.925 58.3523 105.869C58.2953 105.812 58.2202 105.778 58.1403 105.772Z" fill="#FF4040" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
  </svg>
);

export default CalculationReferenceIcon;
